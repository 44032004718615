import { Box, SxProps, Typography } from "@mui/material";
import loadingAnimation from "assets/animations/basketball-spinner.json";
import { useIsMobile } from "hooks/utils/useIsMobile";
import { useLottie } from "lottie-react";

interface LoadingTileProps {
  message?: string;
  align?: "left" | "center" | "right";
  sx?: SxProps;
}

export function LoadingTile(props: LoadingTileProps) {
  const { message = "Loading...", align = "center", sx } = props;

  const isMobile = useIsMobile();

  const { View } = useLottie({
    animationData: loadingAnimation,
    loop: true,
    autoplay: true,
  });

  let alignItems = "flex-start";
  if (align === "center") {
    alignItems = "center";
  } else if (align === "right") {
    alignItems = "flex-end";
  }

  return (
    <Box display="flex" flexDirection="column" alignItems={alignItems} sx={sx}>
      <Typography variant="overline" mt={4}>{message}</Typography>
      <div style={{ maxWidth: isMobile ? "50%" : "15%" }}>
        <>{View}</>
      </div>
    </Box>
  );
}
