import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import Screen from "components/layouts/Screen";
import { useState } from "react";
import { html as frontendChanges } from "../../../CHANGELOG.md";
import { html as backendChanges } from "../../../../graphql/CHANGELOG.md";

function ViewChangelog() {
  const [openTab, setOpenTab] = useState<"Web" | "Server">("Web");

  const handleTabChange = (_event: unknown, selectedTabValue: any) =>
    setOpenTab(selectedTabValue);

  return (
    <Screen title="Changelog" backRoute="/">
      <TabContext value={openTab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList centered onChange={handleTabChange}>
            <Tab label="Web" value="Web" />
            <Tab label="Server" value="Server" />
          </TabList>
        </Box>
        <TabPanel value="Web">
          <div dangerouslySetInnerHTML={{ __html: frontendChanges }} />
        </TabPanel>
        <TabPanel value="Server">
          <div dangerouslySetInnerHTML={{ __html: backendChanges }} />
        </TabPanel>
      </TabContext>
    </Screen>
  );
}

export default ViewChangelog;
