import { bool, cleanEnv, str, url } from "envalid";

// Manually setting NODE_ENV so envalid knows which set of defaults to use
const currentEnv = { ...import.meta.env, NODE_ENV: import.meta.env.MODE };

// Validates that environment variables are correctly loaded during development
// Always import from this file instead of directly from import.meta.env
export const env = cleanEnv(currentEnv, {
  NODE_ENV: str({
    example: "Vite runs in development mode for dev and production while building",
    docs: "https://vitejs.dev/guide/env-and-mode.html#modes",
  }),
  VITE_APP_URL: str({
    default: "https://ptb.social",
    devDefault: "http://localhost:3000",
    desc: "The apps host URL, used for callbacks and redirects",
  }),
  VITE_BACKEND_ENDPOINT: str({
    default: "https://ptb.social",
    devDefault: "",
    desc: "The PTB web server endpoint",
  }),
  VITE_PTB_SUBSCRIPTIONS_ENDPOINT: url({
    default: "wss://ptb.social/api/subscriptions",
    devDefault: "ws://localhost:4000/api/subscriptions",
    desc: "The PTB web server subscriptions endpoint",
  }),
  VITE_GOOGLE_CLIENT_ID: str({
    default: "275248815275-u04vbhm64upam0j5qo2ktaod565tgpc0.apps.googleusercontent.com",
    desc: "The public Google OAuth client ID",
    docs: "https://console.cloud.google.com/apis/credentials/oauthclient/275248815275-u04vbhm64upam0j5qo2ktaod565tgpc0.apps.googleusercontent.com?project=park-the-bus-336116",
  }),
  VITE_STRIPE_PUBLIC_KEY: str({
    default:
      "pk_live_51KCKxCAa2UlTOjJMf2rLCtqpfxqfVgQeX58Ukiw5Uiv5LBwrvNyQs4kjXiaU15A5rV0BQycFDMeSsFvvgYiVNlMh00WY21Hc8X",
    devDefault:
      "pk_test_51KCKxCAa2UlTOjJMxWAn6TMQHauUgismmEqFmSE8PjQaV1Px3riUzKXI8cAwy6phnZXIoSECjteuFOvoD2syBIC2000hmqF8BK",
    desc: "The public Stripe API key",
    docs: "https://dashboard.stripe.com/apikeys",
  }),
  VITE_SW_ENABLED: bool({
    default: true,
    devDefault: false,
    desc: "Whether to enable the service worker",
  }),
  VITE_SENTRY_DSN: str({
    default: "https://a72fe8ec7e1f4c83ae471f2f606921f1@o356575.ingest.sentry.io/6220204",
    desc: "The Sentry DSN",
    docs: "https://sentry.io/organizations/webbx/projects/ptb-react/?project=6220204",
  }),
});

console.log("%c[env] %o", "color: #0f0", { ...env });
