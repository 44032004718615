import { useGetMyProfileQuery, useGlobalNotificationSubscription } from "graphql/generated";
import { useAuthStore } from "./AuthStoreProvider";
import isEqual from "react-fast-compare";
import { Navigate } from "react-router-dom";

interface UpdateProviderProps {
  children?: React.ReactNode;
}

/** Keeps client state in sync with server state and manages global realtime events */
function UpdateProvider(props: UpdateProviderProps) {
  const profile = useAuthStore((store) => store.profile);
  const setProfile = useAuthStore((store) => store.setProfile);

  /** Fetch a fresh profile after logging in with the cached profile */
  const { data } = useGetMyProfileQuery({
    onCompleted: ({ getMyProfile }) => {
      const hasProfileChange = !isEqual(getMyProfile, profile);
      hasProfileChange && setProfile(getMyProfile);
    },
    onError: (error) => {
      throw error;
    },
  });

  useGlobalNotificationSubscription({
    variables: {
      teamID: profile.teamProfile?.team.id ?? "",
    },
    skip: !profile.teamProfile,
    onData: ({ data, client }) => {
      const globalEvent = data.data?.globalNotification;
      if (!globalEvent) return;

      const eventType = globalEvent.__typename;
      if (!eventType) return;

      switch (eventType) {
        /**
         * A venues image has been changed, update the cache
         */
        case "VenueImageChangeEvent": {
          client.cache.modify({
            id: client.cache.identify({
              __typename: "Venue",
              id: globalEvent.venueID,
            }),
            fields: (fieldValue, details) =>
              details.storeFieldName === "imageURL" ? globalEvent.imageURL : fieldValue,
          });
          break;
        }
        case "PlayerProfileImageChangeEvent": {
          client.cache.modify({
            id: client.cache.identify({
              __typename: "TeamProfile",
              id: globalEvent.teamProfileID,
            }),
            fields: (fieldValue, details) =>
              details.storeFieldName === "imageURL" ? globalEvent.imageURL : fieldValue,
          });
          break;
        }
        default: {
          console.warn("Unknown global event type", eventType);
        }
      }
    },
  });

  if (!data?.getMyProfile.teamProfile) {
    <Navigate to="/login" />;
  }

  return <>{props.children}</>;
}

export default UpdateProvider;
