import { Button, ButtonProps } from "@mui/material";

interface PrimaryButtonProps extends ButtonProps {
  //
}

function PrimaryButton(props: PrimaryButtonProps) {
  const { children, ...rest } = props;
  return (
    <Button
      {...rest}
      sx={{
        ...rest.sx,
        // Use a colour gradient for the button
        backgroundImage: (theme) =>
          `linear-gradient(45deg, ${theme.palette.primary.main} 25%, ${theme.palette.secondary.main} 90%)`,

        // Use a colour gradient for the text
        color: "white",
      }}
    >
      {children}
    </Button>
  );
}

export default PrimaryButton;
