import { Star } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Card,
  CardHeader,
  Chip,
  hexToRgb,
  styled,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

interface PlayerCardProps {
  playerID: string;
  name: string;
  position?: string | null;
  isTeamAdmin: boolean;
  picture?: string | null;
  balance?: number;
}

const StyledCard = styled(Card)(({ theme }) => {
    // Create a feint gradient background with the theme colours
  const alpha = 0.15;
  const fadedSecondary = hexToRgb(theme.palette.secondary.main)
    .replace(")", `, ${alpha})`)
    .replace("rgb", "rgba");

  const fadedPrimary = hexToRgb(theme.palette.primary.main)
    .replace(")", `, ${alpha})`)
    .replace("rgb", "rgba");

  const fadedBackground = hexToRgb(theme.palette.background.default)
    .replace(")", `, ${alpha + 0.6})`)
    .replace("rgb", "rgba");

  return {
    border: "1px groove",
    borderColor: theme.palette.grey[900],
    background: `linear-gradient(15deg, ${fadedBackground} 0%, ${fadedPrimary} 35%, ${fadedSecondary} 100%)`,
  };
});

function PlayerCard(props: PlayerCardProps) {
  const { playerID, balance, isTeamAdmin, name, position, picture } = props;
  const navigate = useNavigate();

  return (
    <StyledCard>
      <CardHeader
        title={name}
        titleTypographyProps={{ fontWeight: "bold" }}
        onClick={() => navigate(`/profile/${playerID}`)}
        action={
          balance !== undefined ? (
            <Typography
              variant="caption"
              fontWeight={300}
              color={balance > 0 ? "success.main" : "error.main"}
            >{`$${balance}`}</Typography>
          ) : null
        }
        subheader={
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <div>
              <Typography variant="subtitle2" fontWeight={300} gutterBottom>
                {position}
              </Typography>
              {isTeamAdmin ? (
                <Chip
                  icon={<Star />}
                  size="small"
                  label="Admin"
                  sx={{
                    height: "22px",
                  }}
                />
              ) : null}
            </div>
          </Box>
        }
        avatar={<Avatar src={picture ?? ""} alt={name} sx={{ width: 48, height: 48 }} />}
        sx={{
          alignItems: "flex-start",
        }}
      />
    </StyledCard>
  );
}

export default PlayerCard;
