import {
  useGetNotificationsQuery,
  useMarkNotificationsReadMutation,
} from "graphql/generated";
import { useMemo } from "react";

interface useNotificationsInput {
  limit?: number;
  offset?: number;
}

export function useNotifications(inputs: useNotificationsInput = {}) {
  const { limit, offset } = inputs;
  const {
    data,
    loading: notificationsLoading,
    error: notificationsError,
  } = useGetNotificationsQuery({
    variables: {
      inputs: {
        limit,
        offset,
      },
    },
    pollInterval: 1000 * 60,
  });
  const [markNotificationsReadMutation] = useMarkNotificationsReadMutation();

  const notifications = data?.getNotifications ?? [];

  const handlers = useMemo(
    () => ({
      marksNotificationsRead: () =>
        markNotificationsReadMutation({
          awaitRefetchQueries: true,
          refetchQueries: ["GetNotifications"],
        }),
    }),
    [markNotificationsReadMutation]
  );

  return { notifications, notificationsLoading, notificationsError, handlers };
}
