import { Box, BoxProps, Paper, ThemeProvider } from "@mui/material";
import theme from "theme";

interface PublicScreenProps extends BoxProps {
  children?: JSX.Element | JSX.Element[];
}

function PublicScreen(props: PublicScreenProps) {
  const { children, ...boxProps } = props;
  return (
    <ThemeProvider theme={theme}>
      <Box {...boxProps} height="100vh" component={Paper}>
        {children}
      </Box>
    </ThemeProvider>
  );
}

export default PublicScreen;
