import { Menu } from "@mui/icons-material";
import { AppBar, Box, IconButton, styled, Toolbar } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

type BaseAppShellProps = {
  header?: React.ReactNode;
  drawer?: React.ReactNode;
  footer?: React.ReactNode;
  children?: React.ReactNode;
  /** Hamburger menu icon placement in header */
  menuPosition?: "left" | "right";
};

type MenuAppShellProps = BaseAppShellProps & {
  /** Render prop for a MUI Menu component */
  renderMenu?: (rootElement: HTMLElement | null, onClose: () => void) => JSX.Element;
  renderDrawer?: undefined;
};

type DrawerAppShellProps = BaseAppShellProps & {
  renderDrawer?: (isOpen: boolean, onClose: () => void) => JSX.Element;
  renderMenu?: undefined;
};

type AppShellProps = MenuAppShellProps | DrawerAppShellProps;

const DEFAULT_HEADER_HEIGHT = 70;

/**
 * Renders an app shell which is content with an optional header, menu and footer.
 * The menu can be rendered as a MUI Menu or a MUI Drawer.
 * Based off of [Mantines's AppShell component](https://mantine.dev/core/app-shell/)
 */
function AppShell(props: AppShellProps) {
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
  const [headerHeight, setHeaderHeight] = useState(DEFAULT_HEADER_HEIGHT);
  console.log("App header height: %o", headerHeight);

  const { header, children, footer } = props;
  // Rendering a menu
  const renderMenu = "renderMenu" in props ? props.renderMenu : undefined;
  const menuPosition = "menuPosition" in props ? props.menuPosition : "left";

  // Rendering a drawer
  const renderDrawer = "renderDrawer" in props ? props.renderDrawer : undefined;

  const headerRef = useRef<HTMLDivElement>(null);

  // Update header height when it changes
  useEffect(() => {
    if (headerRef.current) {
      setHeaderHeight(headerRef.current.clientHeight);
    }
  }, [headerRef]);

  const handleMenuClicked = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClosed = () => {
    setMenuAnchorEl(null);
  };

  const shouldRenderMenu = Boolean(renderMenu) || Boolean(renderDrawer);
  const isHeaderRequired = Boolean(header) || shouldRenderMenu;

  return (
    <>
      <Box boxSizing="border-box">
        {renderDrawer && renderDrawer(!!menuAnchorEl, handleMenuClosed)}
        {renderMenu && renderMenu(menuAnchorEl, handleMenuClosed)}


        {isHeaderRequired ? (
          <HeaderWrapper>
            <AppBar
              ref={headerRef}
              sx={{
                px: 2,
                borderBottom: 1,
                borderColor: "divider",
              }}
            >
              <Box
                display="flex"
                flexDirection={menuPosition === "left" ? "row" : "row-reverse"}
                alignItems="center"
                height="100%"
                width="100%"
              >
                {shouldRenderMenu ? (
                  <IconButton onClick={handleMenuClicked}>
                    <Menu />
                  </IconButton>
                ) : null}
                <Toolbar disableGutters sx={{ flex: 1 }}>
                  {header}
                </Toolbar>
              </Box>
            </AppBar>
          </HeaderWrapper>
        ) : null}

        <BodyWrapper>
          <ContentWrapper headerHeight={isHeaderRequired ? headerHeight : 0}>
            {children}
          </ContentWrapper>
        </BodyWrapper>

        {footer ? <FooterWrapper>{footer}</FooterWrapper> : null}
      </Box>
    </>
  );
}

const HeaderWrapper = styled("header")(({ theme }) => ({
  boxSizing: "border-box",
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  zIndex: theme.zIndex.appBar,
  padding: theme.spacing(1),
}));

const BodyWrapper = styled("div")(({ theme }) => ({
  boxSizing: "border-box",
  display: "flex",
  backgroundColor: theme.palette.background.default,
}));

const ContentWrapper = styled("main")<{ headerHeight: number }>(({ theme, headerHeight }) => ({
  boxSizing: "border-box",
  flex: 1,
  minHeight: "100vh",
  width: "100vw",
  paddingTop: headerHeight,
}));

const FooterWrapper = styled("footer")({
  boxSizing: "border-box",
  bottom: 0,
  left: 0,
  right: 0,
});

export default AppShell;
