import { yupResolver } from "@hookform/resolvers/yup";
import {
  CancelOutlined as CancelIcon,
  Delete,
  SaveOutlined as SaveIcon,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  Divider,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { ConfirmDialog } from "components/common";
import ErrorScreen from "components/common/ErrorScreen";
import LoadingSkeletonPage from "components/common/LoadingSkeletonPage";
import SecondaryButton from "components/common/SecondaryButton";
import FormTextField from "components/forms/FormTextField";
import Screen from "components/layouts/Screen";
import {
  DayOfWeek,
  ReoccuringGameFrequency,
  ReoccuringGameStatus,
  useCreateReoccuringGameMutation,
  useDeleteReoccuringGameMutation,
  useTeamReoccuringGamesQuery,
  useTeamVenuesQuery,
  useUpdateReoccuringGameMutation,
} from "graphql/generated";
import { useAuthStore } from "providers/AuthStoreProvider";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { addCacheItemToQuery, deleteCacheItem } from "utils/apolloHelpers";
import * as yup from "yup";

export interface EditReoccuringGameInput {
  name: string;
  frequency: ReoccuringGameFrequency;
  gameDay: DayOfWeek;
  gameTime: Date;
  venueID: string;
  maxPlayers: number;
  minPlayers: number;
  status: ReoccuringGameStatus;
}

const schema = yup
  .object({
    name: yup.string().required(),
    frequency: yup.string().required(),
    gameDay: yup.string().required(),
    gameTime: yup.date().required(),
    venueID: yup.string().required(),
    minPlayers: yup.number().positive().integer().required().typeError("Number required"),
    maxPlayers: yup.number().positive().integer().required().typeError("Number required"),
  })
  .required();

function EditScheduledGame() {
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);

  const { scheduledGameID } = useParams();
  const activeTeamProfile = useAuthStore((store) => store.profile.teamProfile);

  const navigate = useNavigate();
  const mode = scheduledGameID === "create" ? "create" : "edit";

  const [createReoccuringGame] = useCreateReoccuringGameMutation();
  const [updateReoccuringGame] = useUpdateReoccuringGameMutation();
  const [deleteReoccuringGame] = useDeleteReoccuringGameMutation();

  const venuesQuery = useTeamVenuesQuery();
  const { data, loading, error } = useTeamReoccuringGamesQuery({
    variables: {
      teamId: activeTeamProfile.team.id,
    },
  });

  const reoccuringGames = data?.getTeam?.reoccuringGames ?? [];
  const venues = venuesQuery.data?.getVenues ?? [];
  const activeVenues = venues.filter((venue) => venue.isActive);

  const isLoading = venuesQuery.loading || loading;
  const isError = !!error || venuesQuery.error;
  const errorMessage =
    error?.message ?? venuesQuery.error?.message ?? "Error loading scheduled games";

  const reoccuringGameLookup = reoccuringGames?.find((game) => game.id === scheduledGameID);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty },
    reset,
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      frequency: ReoccuringGameFrequency.WEEKLY,
      gameDay: DayOfWeek.TUESDAY,
      gameTime: new Date(),
      venueID: "",
      minPlayers: 8,
      maxPlayers: 14,
      status: ReoccuringGameStatus.RUNNING,
    },
  });

  // When a reoccuring game is selected, load the data into the form
  useEffect(() => {
    if (reoccuringGameLookup) {
      reset({
        name: reoccuringGameLookup.name,
        frequency: reoccuringGameLookup.frequency,
        gameDay: reoccuringGameLookup.gameDay,
        gameTime: new Date(reoccuringGameLookup.gameTime),
        venueID: reoccuringGameLookup.venue.id,
        minPlayers: reoccuringGameLookup.minPlayers,
        maxPlayers: reoccuringGameLookup.maxPlayers,
        status: reoccuringGameLookup.status,
      });
    }
  }, [reoccuringGameLookup, reset]);

  const onSubmit: SubmitHandler<EditReoccuringGameInput> = async (data) => {
    if (mode === "create") {
      await toast.promise(
        createReoccuringGame({
          variables: {
            inputs: {
              ...data,
              gameTime: data.gameTime.toISOString(),
            },
          },
          update: (cache, { data }) => {
            data &&
              addCacheItemToQuery({
                cache,
                existingObjectRef: `Team:${activeTeamProfile.team.id}`,
                fieldName: "reoccuringGames",
                newRef: `ReoccuringGame:${data.createReoccuringGame.id}`,
              });
          },
        }),
        {
          loading: "Creating reoccuring game...",
          success: "Reoccurance created!",
          error: "Error creating reoccuring game",
        }
      );
    } else if (mode === "edit" && scheduledGameID) {
      await toast.promise(
        updateReoccuringGame({
          variables: {
            inputs: {
              ...data,
              id: scheduledGameID,
              gameTime: data.gameTime.toISOString(),
            },
          },
        }),
        {
          loading: "Updating reoccuring game...",
          success: "Reoccurance updated!",
          error: "Error updating reoccuring game",
        }
      );
    } else {
      throw new Error("Unreachable: Unknown mode");
    }

    navigate("/schedule");
  };

  function handleDeletePressed() {
    setIsConfirmDeleteOpen(true);
  }

  async function handleDeleteClosed(confirmation: boolean) {
    if (confirmation && reoccuringGameLookup?.id) {
      await toast.promise(
        deleteReoccuringGame({
          variables: {
            deleteReoccuringGameId: reoccuringGameLookup.id,
          },
          update: (cache, { data }) => {
            data &&
              deleteCacheItem({
                cache,
                result: data.deleteReoccuringGame,
              });
          },
        }),
        {
          loading: "Deleting reoccuring game...",
          error: "Error deleting reoccuring game",
          success: "Reoccurance deleted!",
        }
      );
      navigate("/admin/reoccuring");
    }
    setIsConfirmDeleteOpen(false);
  }

  if (isLoading) {
    return <LoadingSkeletonPage />;
  }

  if (isError) {
    return <ErrorScreen error={error} message={errorMessage} />;
  }

  return (
    <Screen title="Edit Schedule" backRoute="/schedule">
      <Grid container spacing={2}>
        {mode === "edit" ? (
          <Grid item xs={12}>
            <Stack direction="row" spacing={2}>
              <SecondaryButton startIcon={<Delete />} onClick={() => handleDeletePressed()}>
                Delete
              </SecondaryButton>
            </Stack>
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <Card>
            <form onSubmit={handleSubmit(onSubmit)}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Stack display="flex" alignItems="flex-start">
                      <Controller
                        name="status"
                        control={control}
                        render={({ field }) => (
                          <FormControlLabel
                            label="Enabled"
                            checked={field.value === ReoccuringGameStatus.RUNNING}
                            onChange={(_event, checked) =>
                              field.onChange(
                                checked ? ReoccuringGameStatus.RUNNING : ReoccuringGameStatus.PAUSED
                              )
                            }
                            control={<Switch />}
                          />
                        )}
                      />
                      <Typography variant="caption" color="GrayText">
                        Pause games being generated
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <FormTextField label="Schedule Name" control={control} name="name" />
                  </Grid>

                  <Grid item xs={12}>
                    <FormTextField label="Venue" name="venueID" select control={control}>
                      {activeVenues.map((venue) => (
                        <MenuItem key={venue.id} value={venue.id}>
                          <Box display="flex" alignItems="center">
                            <Avatar
                              src={venue.imageURL ?? ""}
                              alt="venue"
                              sx={{ mr: 2, width: 32, height: 32 }}
                            />
                            <Typography variant="body2">{venue.name}</Typography>
                          </Box>
                        </MenuItem>
                      ))}
                    </FormTextField>
                  </Grid>

                  <Grid item xs={6}>
                    <FormTextField label="Day of Week" name="gameDay" select control={control}>
                      <MenuItem value={DayOfWeek.SUNDAY}>Sunday</MenuItem>
                      <MenuItem value={DayOfWeek.MONDAY}>Monday</MenuItem>
                      <MenuItem value={DayOfWeek.TUESDAY}>Tuesday</MenuItem>
                      <MenuItem value={DayOfWeek.WEDNESDAY}>Wednesday</MenuItem>
                      <MenuItem value={DayOfWeek.THURSDAY}>Thursday</MenuItem>
                      <MenuItem value={DayOfWeek.FRIDAY}>Friday</MenuItem>
                      <MenuItem value={DayOfWeek.SATURDAY}>Saturday</MenuItem>
                    </FormTextField>
                  </Grid>

                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name="gameTime"
                      render={({ field }) => (
                        <TimePicker
                          label="Game Time"
                          value={field.value}
                          onChange={(newValue) => {
                            field.onChange(newValue);
                          }}
                          renderInput={(params) => (
                            <FormLabel>
                              {params.label}
                              <TextField
                                {...params}
                                variant="outlined"
                                helperText={activeTeamProfile.team.timezone}
                                label={undefined}
                              />
                            </FormLabel>
                          )}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormTextField label="Frequency" name="frequency" control={control} select>
                      <MenuItem value={ReoccuringGameFrequency.WEEKLY}>Weekly</MenuItem>
                      <MenuItem value={ReoccuringGameFrequency.FORTNIGHTLY}>Fortnightly</MenuItem>
                      <MenuItem value={ReoccuringGameFrequency.MONTHLY}>Monthly</MenuItem>
                    </FormTextField>
                  </Grid>

                  <Grid item xs={12}>
                    <Divider variant="middle">
                      <Typography variant="subtitle2" color="GrayText">
                        Number of players
                      </Typography>
                    </Divider>
                  </Grid>
                  <Grid item xs={6}>
                    <FormTextField label="Min" name="minPlayers" control={control} />
                  </Grid>
                  <Grid item xs={6}>
                    <FormTextField label="Max" name="maxPlayers" control={control} />
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions>
                <SecondaryButton
                  variant="outlined"
                  size="medium"
                  startIcon={<CancelIcon />}
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </SecondaryButton>
                <SecondaryButton
                  size="medium"
                  type="submit"
                  disabled={isSubmitting || !isDirty}
                  startIcon={<SaveIcon />}
                >
                  {mode === "create" ? "Create" : "Save"}
                </SecondaryButton>
              </CardActions>
            </form>
          </Card>
        </Grid>
      </Grid>
      <ConfirmDialog
        isOpen={!!isConfirmDeleteOpen}
        handleClose={handleDeleteClosed}
        title="Delete Reoccuring Game"
        message="Are you sure you want to delete this reoccuring game?"
      />
    </Screen>
  );
}

export default EditScheduledGame;
