import { Stack, Card, CardContent, Skeleton, Typography } from "@mui/material";
import Screen from "components/layouts/Screen";

function LoadingSkeletonPage() {
  return (
    <Screen title="Loading...">
      <Stack spacing={2}>
        <Card>
          <CardContent>
            <Skeleton>
              <Typography variant="h6">Game name here</Typography>
            </Skeleton>
            <Skeleton>
              <Typography variant="body2">time and date</Typography>
            </Skeleton>
            <Skeleton>
              <Typography variant="body2">The name of the venue here</Typography>
            </Skeleton>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <Stack spacing={2}>
              <Skeleton width="50%" />
              <Skeleton width="40%" />
              <Skeleton width="44%" />
              <Skeleton width="54%" />
              <Skeleton width="34%" />
              <Skeleton width="54%" />
              <Skeleton width="84%" />
              <Skeleton width="44%" />
              <Skeleton width="64%" />
            </Stack>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <Skeleton>
              <Typography variant="h6">Game name here</Typography>
            </Skeleton>
            <Skeleton>
              <Typography variant="body2">time and date</Typography>
            </Skeleton>
            <Skeleton>
              <Typography variant="body2">The name of the venue here</Typography>
            </Skeleton>
          </CardContent>
        </Card>
      </Stack>
    </Screen>
  );
}

export default LoadingSkeletonPage;
