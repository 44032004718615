import { Box, LinearProgress, Typography } from "@mui/material";
import loadingAnimation from "assets/animations/basketball-spinner.json";
import { useIsMobile } from "hooks/utils/useIsMobile";
import { useLottie } from "lottie-react";

interface LoadingScreenProps {
  message: string;
}

export function LoadingScreen(props: LoadingScreenProps) {
  const { message } = props;

  const { View } = useLottie({
    animationData: loadingAnimation,
    loop: true,
    autoplay: true,
  });

  const isMobile = useIsMobile();

  return (
    <>
      <LinearProgress color="inherit" />
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography variant="overline" mt={4}>
          {message}
        </Typography>
        <div style={{ maxWidth: isMobile ? "50%" : "15%" }}>
          <>{View}</>
        </div>
      </Box>
    </>
  );
}
