import {
  NotificationImportant as BellIcon,
  NotificationsOutlined as MenuIcon,
} from "@mui/icons-material";
import {
  Badge,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { NotificationPriority } from "graphql/generated";
import { useNotifications } from "hooks/data/useNotifications";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { iconColor } from "types";

function NotificationMenu() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const {
    notifications,
    handlers: { marksNotificationsRead },
  } = useNotifications();

  function handleClick(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
  }
  function handleClose() {
    setAnchorEl(null);
    marksNotificationsRead();
  }

  function notificationPressed(notificationID: string) {
    const notification = notifications.find(
      (notification) => notification.id === notificationID
    );
    if (notification?.redirect) {
      navigate(notification.redirect);
    }
  }

  const isNotificationUnread = notifications.some(
    (notification) => !notification.isRead
  );

  return (
    <>
      <Tooltip title="Notifications">
        <IconButton onClick={handleClick} size="small" sx={{ mx: 1 }}>
          <Badge
            invisible={!isNotificationUnread}
            color="primary"
            variant="dot"
          >
            <MenuIcon />
          </Badge>
        </IconButton>
      </Tooltip>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <Typography
          px={2}
          variant="overline"
          onClick={() => navigate("/notifications")}
        >
          Notifications
        </Typography>
        {notifications.length === 0 ? (
          <Typography align="center" variant="subtitle2">
            No Notifications
          </Typography>
        ) : (
          notifications.map((notification) => {
            let color: iconColor = "inherit";

            switch (notification.priority) {
              case NotificationPriority.low:
                color = "success";
                break;
              case NotificationPriority.medium:
                color = "warning";
                break;
              case NotificationPriority.high:
                color = "error";
                break;
            }
            return (
              <MenuItem
                dense
                key={notification.id}
                onClick={() => notificationPressed(notification.id)}
              >
                <ListItemIcon>
                  <Badge
                    color="primary"
                    variant="dot"
                    invisible={notification.isRead}
                  >
                    <BellIcon fontSize="small" color={color} />
                  </Badge>
                </ListItemIcon>

                <ListItemText>
                  <Typography sx={{ whiteSpace: "normal" }} variant="body2">
                    {notification.message}
                  </Typography>
                </ListItemText>
              </MenuItem>
            );
          })
        )}
      </Menu>
    </>
  );
}

export default NotificationMenu;
