import {
  PeopleOutline as PlayersIcon,
  SportsSoccerOutlined as GamesIcon,
} from "@mui/icons-material";
import { Box, Grid, Stack, Typography } from "@mui/material";
import BallsAnimation from "assets/animations/sportballs-animation.json";
import PrimaryButton from "components/common/PrimaryButton";
import SecondaryButton from "components/common/SecondaryButton";
import Screen from "components/layouts/Screen";
import { useGetRecentlyFinalisedGamesQuery } from "graphql/generated";
import Lottie from "lottie-react";
import { useAuthStore } from "providers/AuthStoreProvider";
import { useMemo } from "react";
import toast from "react-hot-toast";
import QRCode from "react-qr-code";
import { useNavigate } from "react-router-dom";
import { version } from "../../../package.json";

function Home() {
  const profile = useAuthStore((store) => store.profile);
  const activeTeamProfile = profile.teamProfile!;
  const navigate = useNavigate();

  const { data } = useGetRecentlyFinalisedGamesQuery();

  const awaitingReviewCount = useMemo(() => {
    const recentlyFinalisedGames = data?.getPlayersRecentlyFinalisedGames ?? [];
    return recentlyFinalisedGames.filter((game) => !game.review).length;
  }, [data?.getPlayersRecentlyFinalisedGames]);

  const joinLink = `${import.meta.env.VITE_BACKEND_ENDPOINT}/general/whatsapp?playerID=${
    profile.player.id
  }&teamID=${activeTeamProfile?.team.id}`;

  async function handleJoinWhatsappPressed() {
    try {
      const result = await fetch(joinLink);
      const joinUrl = await result.text();
      window.open(joinUrl, "_blank");
    } catch (error) {
      console.error(error);
      toast.error("Error joining Whatsapp group");
    }
  }

  return (
    <Screen>
      <Typography
        align="center"
        variant="h4"
        component="h1"
        fontWeight="bold"
        sx={{
          color: (theme) => theme.palette.text.primary,
          fontSize: "3.5em",
          fontWeight: 800,
          letterSpacing: "-2.8px",
        }}
      >
        {profile.teamProfile?.team.name}
      </Typography>
      <Typography
        align="center"
        variant="h6"
        component="h2"
        fontWeight="bold"
        gutterBottom
        sx={{
          backgroundImage: (theme) =>
            `linear-gradient(45deg, ${theme.palette.primary.main} 25%, ${theme.palette.secondary.main} 90%)`,
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          pb: 4,
        }}
      >
        PTB.social
      </Typography>

      <Grid container>
        {!profile.teamProfile?.hasJoinedTeamChat &&
        activeTeamProfile?.team.whatsappJoinLink ? (
          <Grid item xs={12} my={4}>
            <Box display="flex" alignItems="center" flexDirection="column">
              <Typography variant="body2" mb={2}>
                Scan or click below to join the team chat on Whatsapp
              </Typography>
              <div style={{ background: "white" }}>
                <QRCode onClick={handleJoinWhatsappPressed} value={joinLink} size={100} />
              </div>
            </Box>
          </Grid>
        ) : null}

        <Grid item xs={12}>
          <Stack spacing={1} mx={1}>
            <PrimaryButton startIcon={<GamesIcon />} onClick={() => navigate("/games")}>
              Upcoming Games
            </PrimaryButton>
            <SecondaryButton
              startIcon={<PlayersIcon />}
              onClick={() => navigate("/players")}
            >
              Players
            </SecondaryButton>
            {activeTeamProfile?.isTeamAdmin ? (
              <SecondaryButton onClick={() => navigate(`/admin`)}>
                Team Settings
              </SecondaryButton>
            ) : null}
            {awaitingReviewCount ? (
              <SecondaryButton onClick={() => navigate(`/reviews`)}>
                {`${awaitingReviewCount} Awaiting Reviews`}
              </SecondaryButton>
            ) : null}

            <Typography
              align="center"
              color="gray"
              fontSize="0.8rem"
              variant="subtitle2"
              onClick={() => navigate("/changelog")}
            >
              {`v${version}`}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Lottie
            style={{
              paddingTop: "12px",
              height: "325px",
            }}
            animationData={BallsAnimation}
          />
        </Grid>
      </Grid>
    </Screen>
  );
}

export default Home;
