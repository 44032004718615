import { LinearProgress, Paper } from "@mui/material";
import { GoogleOAuthProvider } from "@react-oauth/google";
import App from "App";
import { LoadingScreen, LoadingTile } from "components/common";
import ErrorScreen from "components/common/ErrorScreen";
import PageNotFound from "components/common/PageNotFound";
import { env } from "config/env";
import { lazy, Suspense } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

const Login = lazy(() => import("screens/login/Login"));
const Register = lazy(() => import("screens/register/Register"));
const ConfirmEmailCallback = lazy(
  () => import("screens/awaitingConfirmation/ConfirmEmailCallback")
);
const TermsOfService = lazy(() => import("screens/legal/termsOfService/TermsOfService"));

function PublicRoutes() {
  const publicRouter = createBrowserRouter([
    {
      path: "*",
      element: <App />,
      errorElement: <ErrorScreen message="PTB has crashed due to an unexpected error" />,
    },
    {
      path: "/login",
      element: (
        <GoogleOAuthProvider clientId={env.VITE_GOOGLE_CLIENT_ID}>
          <Login />
        </GoogleOAuthProvider>
      ),
    },
    { path: "/register", element: <Register /> },
    { path: "/service", element: <TermsOfService /> },

    /**
     * When a player clicks on the link in the email, they will be redirected
     * to this page. This page will then call the API to confirm the email
     * address. */
    { path: "/auth/email/confirm/:token", element: <ConfirmEmailCallback /> },
    { path: "*", element: <PageNotFound /> },
  ]);

  return (
    <Suspense fallback={<LoadingScreen message="Loading PTB..." />}>
      <RouterProvider router={publicRouter} fallbackElement={<LoadingFallback />} />
    </Suspense>
  );
}

const LoadingFallback = () => {
  return (
    <Paper sx={{ backgroundColor: "#121212", minHeight: "100vh" }}>
      <LinearProgress color="inherit" />
      <LoadingTile message="Loading Page..." align="center" />
    </Paper>
  );
};

export default PublicRoutes;
