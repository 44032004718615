import create, { StoreApi } from "zustand";
import createContext from "zustand/context";
import { persist } from "zustand/middleware";

export interface GlobalStore {
  accessToken: string | null;
  setAccessToken: (accessToken: string | null) => void;
  clearStore: () => void;
}

const ZustandContext = createContext<StoreApi<GlobalStore>>();
export const { useStore: useGlobalStore } = ZustandContext;
const { Provider } = ZustandContext;

interface StoreProviderProps {
  children: React.ReactNode;
}

export const StoreCacheKey = "global-ptb-store";

/** Bump this when zustand store schema changes and implement a
 *  migration strategy for the new version
 */
const STORE_SCHEMA_VERSION = 1;

export function GlobalStoreProvider(props: StoreProviderProps) {
  const createStore = () =>
    create<GlobalStore>()(
      persist(
        (set) => ({
          accessToken: null,
          setAccessToken: (accessToken) => set({ accessToken }),
          clearStore: () => set({ accessToken: null }),
        }),
        {
          name: StoreCacheKey,
          getStorage: () => localStorage,
          // Busts the local cache each version bump
          version: STORE_SCHEMA_VERSION,
          migrate: (persistedState, _version) => {
            // No migrations yet
            return persistedState as GlobalStore;
          },
        }
      )
    );

  return <Provider createStore={createStore}>{props.children}</Provider>;
}
