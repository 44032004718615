import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache, split } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import * as Sentry from "@sentry/react";
import { env } from "config/env";
import { createClient } from "graphql-ws";
import { StrictTypedTypePolicies } from "graphql/generated/apollo-helper";
import React, { memo } from "react";
import packageInfo from "../../package.json";
import { useGlobalStore } from "./GlobalStoreProvider";

interface AuthorizedApolloProviderProps {
  children: React.ReactNode;
}

console.log("Setting graphql endpoint to %o", env.VITE_BACKEND_ENDPOINT);

const httpLink = createHttpLink({
  uri: `${env.VITE_BACKEND_ENDPOINT}/api/graphql`,
});

const errorLink = onError(({ networkError, graphQLErrors, operation }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      const { message, path } = error;
      console.error(`[GraphQL error]: ${message} at ${path?.join(", ")}`);
      Sentry.setContext("Graphql", {
        type: "graphql",
        client: "apollo-client",
        path: path?.join(", "),
        operation: operation.operationName,
        variables: JSON.stringify(operation.variables),
      });
      Sentry.captureException(new Error(message));
    });
  }
  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
    Sentry.captureException(networkError);
  }
});

let printed = false;

function AuthorizedApolloProvider(props: AuthorizedApolloProviderProps) {
  const accessToken = useGlobalStore((store) => store.accessToken);

  const wsLink = new GraphQLWsLink(
    createClient({
      url: env.VITE_PTB_SUBSCRIPTIONS_ENDPOINT,
      connectionParams: {
        token: accessToken,
      },
    })
  );

  const authLink = setContext(async (_, { headers }) => {
    // Print token to console once so it's easy to copy and paste
    if (env.isDev && !printed) {
      console.info(accessToken);
      printed = true;
    }
    return {
      headers: {
        ...headers,
        ...(accessToken && { authorization: `Bearer ${accessToken}` }),
      },
    };
  });

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === "OperationDefinition" && definition.operation === "subscription";
    },
    wsLink,
    httpLink
  );

  const typePolicies: StrictTypedTypePolicies = {
    Query: {
      fields: {
        searchGamesCount: {
          keyArgs: ["searchText", "status", "date"],
          merge: (existing, incoming) => {
            return existing ? existing : incoming;
          },
          read: (existing) => {
            return existing;
          },
        },
        searchGames: {
          keyArgs: ["searchText", "status", "date", "orderBy", "orderDirection"],
          merge: (existing, incoming, { args }) => {
            const { page, rowsPerPage } = args || {};
            if (!page || !rowsPerPage) throw new Error("Missing pagination args");
            const offset = (page - 1) * rowsPerPage;

            // Copy data as it's immutable
            const merged = existing ? [...existing] : [];

            // Fill in the array with the incoming data at their correct indices
            for (let i = 0; i < incoming.length; ++i) {
              merged[offset + i] = incoming[i];
            }
            return merged;
          },
          read: (existing, { args }) => {
            const { page = 1, rowsPerPage = 10 } = args || {};
            const offset = (page - 1) * rowsPerPage;
            const cacheRead = existing && existing.slice(offset, offset + rowsPerPage);
            return cacheRead;
          },
        },
        getPlayersProfile: {
          keyArgs: ["player", ["id"]],
        },
      },
    },
    Profile: {
      fields: {
        transactions: {
          keyArgs: ["playerId", "sortBy", "sortDirection"],
          merge(existing, incoming, { args }) {
            const { offset = 0 } = args || {};
            const merged = existing ? existing.slice(0) : [];
            for (let i = 0; i < incoming.length; ++i) {
              merged[offset + i] = incoming[i];
            }
            return merged;
          },
          read: (existing, { args }) => {
            const { offset = 0, limit = 10 } = args || {};
            const merged = existing && existing.slice(offset, offset + limit);
            console.log("Reading transactions: %o", merged);
            return merged;
          },
        },
      },
    },
    Game: {
      fields: {
        attendance: {
          // Always replace existing attendance
          merge(_existing, incoming) {
            return incoming;
          },
        },
        guests: {
          // Always replace existing guests
          merge(_existing, incoming) {
            return incoming;
          },
        },
        waitingList: {
          // Always replace existing list
          merge(_existing, incoming) {
            return incoming;
          },
        },
      },
    },
  };

  const apolloClient = new ApolloClient({
    name: packageInfo.name,
    version: packageInfo.version,
    connectToDevTools: true,
    cache: new InMemoryCache({ typePolicies }),
    link: authLink.concat(errorLink).concat(splitLink),
    credentials: "include",
  });

  return <ApolloProvider client={apolloClient}>{props.children}</ApolloProvider>;
}

export default memo(AuthorizedApolloProvider);
