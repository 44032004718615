import { Button, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";

const StyledButton = styled(Button)(
  ({ theme }) => `
  border-top: 2px solid ${theme.palette.primary.main};
  background-color: ${theme.palette.background.paper};
  padding: ${theme.spacing(2)};
  border-radius: 5px;
  display: flex;
  justify-content: center;
  width: 100%;
  color: inherit;
`
);

interface NavButtonProps {
  route: string;
  label: string;
  icon: JSX.Element;
}

export function NavButton(props: NavButtonProps) {
  const navigate = useNavigate();
  const { route, label, icon } = props;

  return (
    <StyledButton startIcon={icon} onClick={() => navigate(route)}>
      {label}
    </StyledButton>
  );
}
