import { AttachMoney as MoneyIcon } from "@mui/icons-material";
import { Box, Chip, Typography } from "@mui/material";
import PtbLogo from "assets/logo/PTB_Icon_50x50.png";
import { useAuthStore } from "providers/AuthStoreProvider";
import { useNavigate } from "react-router-dom";
import NotificationMenu from "../global/notifications/NotificationMenu";

function TopNavigation() {
  const navigate = useNavigate();
  const balance = useAuthStore((store) => store.profile.balance);

  return (
    <>
      <Box sx={{ flex: 1 }}>
        <Box display="flex" alignItems="center">
          <img
            src={PtbLogo}
            width="35"
            alt="PTB Logo"
            style={{ marginRight: "10px" }}
            onClick={() => navigate("/")}
          />
          <Typography
            variant="h6"
            component="a"
            sx={{
              cursor: "pointer",
            }}
            onClick={() => navigate("/")}
          >
            PTB
          </Typography>
        </Box>
      </Box>
      <Chip
        icon={<MoneyIcon />}
        color={balance >= 0 ? "success" : "error"}
        label={balance}
        size="small"
        onClick={() => navigate("/settings/balance/add")}
        sx={{ mx: 1 }}
      />
      <NotificationMenu />
    </>
  );
}

export default TopNavigation;
