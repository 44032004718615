import { FieldValues, Path, useController, UseControllerProps } from "react-hook-form";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { Box, BoxProps, FormLabel, InputAdornment } from "@mui/material";

type CustomTextfieldProps = {
  isMoney?: boolean;
  currencyCode?: string;
  locale?: string;
  isPercent?: boolean;
  readOnly?: boolean;
  rootProps?: BoxProps;
};

type BaseFormTextFieldProps<
  FormValues extends FieldValues,
  Name extends Path<FormValues>
> = UseControllerProps<FormValues, Name> & TextFieldProps & CustomTextfieldProps;

export type FormTextFieldProps<
  FormValues extends FieldValues,
  Name extends Path<FormValues>
> = BaseFormTextFieldProps<FormValues, Name>;

function FormTextField<FormValues extends FieldValues, Name extends Path<FormValues>>(
  props: BaseFormTextFieldProps<FormValues, Name>
) {
  const {
    name,
    rules,
    shouldUnregister,
    defaultValue,
    control,
    label,
    onChange,
    isMoney = false,
    currencyCode = "AUD",
    locale = "en-AU",
    isPercent = false,
    readOnly = false,
    rootProps,
    ...textFieldProps
  } = props;
  const controller = useController({
    name,
    rules,
    shouldUnregister,
    defaultValue,
    control,
  });
  const { field, fieldState, formState } = controller;

  const localMoney = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currencyCode,
  }).format(field.value);

  return (
    <Box {...rootProps}>
      <FormLabel error={!!fieldState.error} disabled={textFieldProps.disabled}>
        {label}
        <TextField
          {...textFieldProps}
          disabled={textFieldProps.disabled || formState.isSubmitting}
          name={name}
          onBlur={field.onBlur}
          value={field.value ?? ""}
          inputRef={field.ref}
          onChange={onChange ?? field.onChange}
          error={!!textFieldProps.error || !!fieldState.error}
          helperText={fieldState.error?.message ?? textFieldProps.helperText}
          type={isMoney ? "number" : textFieldProps.type}
          InputProps={{
            ...textFieldProps.InputProps,

            // If the field is set to read only
            readOnly,

            // If the field is a money field, add the currency symbol
            ...(isMoney && {
              endAdornment: (
                <InputAdornment position="end">{currencyCode}</InputAdornment>
              ),
              startAdornment: (
                <InputAdornment position="start">{localMoney}</InputAdornment>
              ),
            }),

            // If the field is a % field, add the %
            ...(isPercent && {
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }),
          }}
        />
      </FormLabel>
    </Box>
  );
}

export default FormTextField;
