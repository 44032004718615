import { Button, Stack } from "@mui/material";
import { useToggleActiveTeamMutation } from "graphql/generated";
import { useNavigate } from "react-router-dom";

interface SelectTeamFromListProps {
  teams: { id: string; name: string }[];
}

function SelectTeamFromList(props: SelectTeamFromListProps) {
  const { teams } = props;

  const [toggleTeam, toggleTeamQuery] = useToggleActiveTeamMutation();

  const navigate = useNavigate();

  const handleTeamSelected = (teamID: string) => async () => {
    await toggleTeam({
      variables: {
        newTeamID: teamID,
      },
      refetchQueries: "all",
      awaitRefetchQueries: true,
    });
    navigate("/");
  };

  return (
    <Stack spacing={2}>
      {teams.map(
        (team) =>
          team && (
            <Button
              key={team.id}
              variant="outlined"
              onClick={handleTeamSelected(team.id)}
              disabled={toggleTeamQuery.loading}
            >
              {team.name}
            </Button>
          )
      )}
    </Stack>
  );
}

export default SelectTeamFromList;
