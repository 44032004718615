import PublicScreen from "components/layouts/PublicScreen";
import Lottie from "lottie-react";
import PageNotFoundAnimation from "assets/animations/ptb_404.json";

function PageNotFound() {
  return (
    <PublicScreen>
      <Lottie height="100%" animationData={PageNotFoundAnimation} />
    </PublicScreen>
  );
}

export default PageNotFound;
