import { MyProfileFieldsFragment } from "graphql/generated";
import create, { StoreApi } from "zustand";
import createContext from "zustand/context";
import { persist } from "zustand/middleware";
import UpdateProvider from "./UpdateProvider";

export interface AuthGlobalStore {
  profile: MyProfileFieldsFragment;
  setProfile: (profile: MyProfileFieldsFragment) => void;
}

const StoreContext = createContext<StoreApi<AuthGlobalStore>>();
const { Provider } = StoreContext;
export const { useStore: useAuthStore } = StoreContext;

interface StoreProviderProps {
  profile: MyProfileFieldsFragment;
  children: React.ReactNode;
}

export const AuthStoreCacheKey = "ptb-auth-store";

/** Bump this when zustand store schema changes and implement a
 *  migration strategy for the new version
 */
const STORE_SCHEMA_VERSION = 1;

export function AuthStoreProvider(props: StoreProviderProps) {
  const { profile } = props;

  const createStore = () =>
    create<AuthGlobalStore>()(
      persist(
        (set) => ({
          profile,
          setProfile: (profile) => set({ profile }),
        }),
        {
          name: AuthStoreCacheKey,
          getStorage: () => localStorage,
          // Busts the local cache each version bump
          version: STORE_SCHEMA_VERSION,
          migrate: (persistedState, _version) => {
            // No migrations yet
            return persistedState as AuthGlobalStore;
          },
        }
      )
    );

  return (
    <Provider createStore={createStore}>
      <UpdateProvider>{props.children}</UpdateProvider>
    </Provider>
  );
}
