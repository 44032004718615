import { Container } from "@mui/material";
import { ScreenTitle } from "components/common";
import React from "react";
import { textVariant } from "types";

interface ScreenProps {
  title?: string;
  titleVariant?: textVariant;
  backButton?: boolean;
  backRoute?: string;
  customAction?: any;
  children?: React.ReactNode;
}

function Screen(props: ScreenProps) {
  return (
    <Container
      disableGutters
      maxWidth="sm"
      sx={{
        borderRadius: 0,
        display: "flex",
        minHeight: "100vh",
        flexDirection: "column",
        justifyContent: "space-between",
        px: 1.5,
        pt: 1
      }}
    >
      <div>
        {props.title ? <ScreenTitle title={props.title} {...props} /> : null}
        {props.children ?? null}
      </div>
    </Container>
  );
}

export default Screen;
