import { useApolloClient } from "@apollo/client";
import {
  AdminPanelSettings as AdminIcon,
  EmojiTransportationOutlined as VenuesIcon,
  Gavel as RulesIcon,
  Logout as LogoutIcon,
  ManageAccounts as AccountsIcon,
  Payment as SubscriptionIcon,
  TimerOutlined as ScheduledGamesIcon,
} from "@mui/icons-material";
import { Avatar, Divider, Menu as MuiMenu, Typography } from "@mui/material";
import { googleLogout } from "@react-oauth/google";
import { AuthProviders, useLogoutMutation } from "graphql/generated";
import { useAuthStore } from "providers/AuthStoreProvider";
import { useGlobalStore } from "providers/GlobalStoreProvider";
import { useMemo } from "react";
import MenuItem from "./MenuItem";

export interface PTBMenuItem {
  label: string;
  path?: string;
  icon: JSX.Element;
}

interface MenuProps {
  anchorEl: HTMLElement | null;
  onClose: () => void;
}

function Menu(props: MenuProps) {
  const { anchorEl, onClose } = props;
  const apolloClient = useApolloClient();
  const profile = useAuthStore((store) => store.profile);
  const activeTeamProfile = profile.teamProfile!;

  const [emailLogout] = useLogoutMutation();
  const clearGlobalStore = useGlobalStore((store) => store.clearStore);

  const topMenuItems = useMemo<PTBMenuItem[]>(
    () => [
      {
        label: "Team Profile",
        path: `/profile/${profile.player.id}`,
        icon: <Avatar alt={profile.player.name} src={activeTeamProfile.imageURL ?? ""} />,
      },
      { label: "Account", path: "/account", icon: <AccountsIcon /> },
    ],
    [activeTeamProfile.imageURL, profile.player.id, profile.player.name]
  );

  const adminMenuItems = useMemo<PTBMenuItem[]>(
    () => [
      { label: "Team Settings", path: "/admin", icon: <AdminIcon /> },
      { label: "Subscription", path: "/admin", icon: <SubscriptionIcon /> },
      { label: "Venues", path: "/admin/venues", icon: <VenuesIcon /> },
    ],
    []
  );

  const teamMenuItems = useMemo<PTBMenuItem[]>(
    () => [
      { label: "Schedule", path: "/schedule", icon: <ScheduledGamesIcon /> },
      { label: "Rules", path: "/roles", icon: <RulesIcon /> },
      { label: "Logout", icon: <LogoutIcon /> },
    ],
    []
  );

  const isMenuOpen = Boolean(anchorEl);

  const handleMenuClosed = () => {
    onClose();
  };

  const handleMenuItemPressed = (label: string) => async () => {
    onClose();

    if (label === "Logout") {
      await apolloClient.clearStore();
      if (profile.authProvider === AuthProviders.GOOGLE) {
        googleLogout();
      } else if (profile.authProvider === AuthProviders.EMAIL) {
        await emailLogout();
      }
      // Delete all local storage
      localStorage.clear();
      sessionStorage.clear();
      clearGlobalStore();
      return;
    }
  };

  return (
    <MuiMenu
      key={String(isMenuOpen)}
      keepMounted
      anchorEl={anchorEl}
      open={isMenuOpen}
      onClick={handleMenuClosed}
      onClose={handleMenuClosed}
      PaperProps={{
        elevation: 0,
        sx: {
          width: "200px",
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      {topMenuItems.map((menuItem) => (
        <MenuItem key={menuItem.label} {...menuItem} dense onClick={handleMenuItemPressed} />
      ))}

      {/* Admin menu items */}
      {activeTeamProfile.isTeamAdmin && (
        <div>
          <Divider variant="middle">
            <Typography variant="caption" color="GrayText">
              Admin Settings
            </Typography>
          </Divider>
          {adminMenuItems.map((menuItem) => (
            <MenuItem key={menuItem.label} {...menuItem} dense onClick={handleMenuItemPressed} />
          ))}
        </div>
      )}

      {/* Team member menu items */}
      <Divider variant="middle">
        <Typography variant="caption" color="GrayText">
          General
        </Typography>
      </Divider>
      {teamMenuItems.map((menuItem) => (
        <MenuItem key={menuItem.label} {...menuItem} dense onClick={handleMenuItemPressed} />
      ))}
    </MuiMenu>
  );
}

export default Menu;
