import { createTheme } from "@mui/material/styles";

// https://colorhunt.co/palette/2a09443b185fa12568fec260

// maybe this one is better
// https://colorhunt.co/palette/0000003e065f700b978e05c2

export default createTheme({
  typography: {
    fontFamily: [
      "Inter",
      "ui-sans-serif",
      "system-ui",
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Roboto",
      "Helvetica Neue",
      "Arial",
      "Noto Sans",
      "sans-serif",
      "Apple Color Emoji",
      "Segoe UI Emoji",
      "Segoe UI Symbol",
      "Noto Color Emoji",
    ].join(","),
  },
  palette: {
    mode: "dark",
    primary: {
      // main: "#700B97"
      // main: "#8E05C2", // bright purple
      // main: "#673AB7", // nicer purple
      main: "#ff1e56", // pink
    },
    secondary: {
      // main: "#40375C", // dark purple
      // main: "#ff8e53", // orange
      main: "#3b82f6", // blue
    },
    background: {
      default: "#1d1d1f",
      paper: "#292929",
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "#313133",
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
        margin: "dense",
      },
    },
    MuiCard: {
      defaultProps: {
        variant: "outlined",
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          fontSize: "1.1em",
          fontWeight: 500,
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          display: "flex",
          justifyContent: "flex-end",
        }
      }
    }
  },
});
