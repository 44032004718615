import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AccountNumber: any;
  BigInt: any;
  Byte: any;
  CountryCode: any;
  Cuid: any;
  Currency: any;
  DID: any;
  Date: string;
  DateTime: string;
  Duration: any;
  EmailAddress: any;
  GUID: any;
  HSL: any;
  HSLA: any;
  HexColorCode: any;
  Hexadecimal: any;
  IBAN: any;
  IP: any;
  IPv4: any;
  IPv6: any;
  ISBN: any;
  ISO8601Duration: any;
  JSON: string;
  JSONObject: any;
  JWT: any;
  Latitude: any;
  LocalDate: any;
  LocalEndTime: any;
  LocalTime: any;
  Locale: any;
  Long: any;
  Longitude: any;
  MAC: any;
  NegativeFloat: any;
  NegativeInt: any;
  NonEmptyString: any;
  NonNegativeFloat: any;
  NonNegativeInt: any;
  NonPositiveFloat: any;
  NonPositiveInt: any;
  ObjectID: any;
  PhoneNumber: any;
  Port: any;
  PositiveFloat: any;
  PositiveInt: any;
  PostalCode: any;
  RGB: any;
  RGBA: any;
  RoutingNumber: any;
  SafeInt: any;
  SemVer: any;
  Time: any;
  TimeZone: any;
  Timestamp: any;
  URL: string;
  USCurrency: any;
  UUID: string;
  UnsignedFloat: any;
  UnsignedInt: any;
  UtcOffset: any;
  Void: any;
};

export type Attendance = {
  __typename?: 'Attendance';
  game: Game;
  id: Scalars['String'];
  player: Player;
  team: Team;
};

/** A player on the team has signed up or withdrawn from a game */
export type AttendanceChangeEvent = {
  __typename?: 'AttendanceChangeEvent';
  attendanceID: Scalars['ID'];
  gameID: Scalars['ID'];
  /** True if the player has signed up, false if they are withdrawing */
  isSignup: Scalars['Boolean'];
  playerID: Scalars['ID'];
  teamID: Scalars['ID'];
};

/** How the player has signed up */
export enum AuthProviders {
  EMAIL = 'EMAIL',
  GOOGLE = 'GOOGLE'
}

export type CashOutRequestInputs = {
  accountName: Scalars['String'];
  accountNumber: Scalars['String'];
  amount: Scalars['Float'];
  bsb: Scalars['String'];
  note: Scalars['String'];
  type: CashOutType;
};

/** full or partial */
export enum CashOutType {
  full = 'full',
  partial = 'partial'
}

export type CommonFilters = {
  searchText?: InputMaybe<Scalars['String']>;
  /** Number of items to skip */
  skip?: InputMaybe<Scalars['Int']>;
  /** Sorting field */
  sortBy?: InputMaybe<Scalars['String']>;
  /** Sorting direction (asc or desc) */
  sortDirection?: InputMaybe<SortDirection>;
  /** Number of items to return */
  take?: InputMaybe<Scalars['Int']>;
};

export type CreateGameInput = {
  allowGuests: Scalars['Boolean'];
  date: Scalars['DateTime'];
  maxPlayers: Scalars['Int'];
  minPlayers: Scalars['Int'];
  name: Scalars['String'];
  venueID: Scalars['String'];
};

export type CreatePostGameReviewInputs = {
  gameID: Scalars['String'];
  goals: Scalars['Float'];
  highlight?: InputMaybe<Scalars['String']>;
  mvpVotePlayerID: Scalars['String'];
  teamRating: Scalars['Float'];
  win: Scalars['Boolean'];
};

export type CreateReoccuringGameInputs = {
  frequency: ReoccuringGameFrequency;
  gameDay: DayOfWeek;
  gameTime: Scalars['DateTime'];
  maxPlayers: Scalars['Int'];
  minPlayers: Scalars['Int'];
  name: Scalars['String'];
  venueID: Scalars['String'];
};

export type CreateSubscriptionResponse = {
  __typename?: 'CreateSubscriptionResponse';
  clientSecret: Scalars['String'];
  subscriptionID: Scalars['String'];
};

export type CreateTeamInput = {
  description: Scalars['String'];
  name: Scalars['String'];
  timeZone: Scalars['TimeZone'];
  whatsappJoinLink?: InputMaybe<Scalars['String']>;
};

export type CreateTeamRuleInputs = {
  ruleDetails: Scalars['String'];
  ruleTitle: Scalars['String'];
};

/** Day of the week */
export enum DayOfWeek {
  FRIDAY = 'FRIDAY',
  MONDAY = 'MONDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
  THURSDAY = 'THURSDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY'
}

export type EmailRegisterInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
};

export type FileUploadInputs = {
  filename: Scalars['String'];
  /** Add any required metadata for creating the Uploaded Image */
  metadata?: InputMaybe<Scalars['JSONObject']>;
  sizeBytes: Scalars['Int'];
  type: ImageType;
};

export type FinaliseGameInputs = {
  fee: Scalars['Float'];
  gameID: Scalars['String'];
  paidByPlayerID: Scalars['String'];
  playerIDs: Array<Scalars['String']>;
  transactionName: Scalars['String'];
};

export type Game = {
  __typename?: 'Game';
  allowGuests: Scalars['Boolean'];
  allowWaitingList: Scalars['Boolean'];
  attendance: Array<Attendance>;
  /** Date the game will is played */
  date: Scalars['DateTime'];
  /** Date the game was finalised and charged */
  finalisedDate?: Maybe<Scalars['DateTime']>;
  guests: Array<Guest>;
  id: Scalars['String'];
  inVoting: Scalars['Boolean'];
  /** Max number of players the game can play */
  maxPlayers: Scalars['Int'];
  /** Minimum number of players to have a game */
  minPlayers: Scalars['Int'];
  mvpPlayer?: Maybe<Player>;
  /** Name or title of the game */
  name: Scalars['String'];
  reviews: Array<PostGameReview>;
  status: GameStatus;
  team: Team;
  transactions: Array<Transaction>;
  updatedByPlayer: Player;
  venue: Venue;
  waitingList: Array<WaitingList>;
  weather?: Maybe<WeatherReport>;
};

/** When a game has been created, updated or deleted, tell the subscribed client to refetch */
export type GameChangeEvent = {
  __typename?: 'GameChangeEvent';
  teamID: Scalars['ID'];
  type: GameChangeEventType;
};

/** Upcoming games event type, used to descriminate between upcoming game event types */
export enum GameChangeEventType {
  Created = 'Created',
  Deleted = 'Deleted',
  Updated = 'Updated'
}

/** The possible states of a game, inferred by its fields */
export enum GameStatus {
  AwaitingFinalisation = 'AwaitingFinalisation',
  Complete = 'Complete',
  GameScheduled = 'GameScheduled',
  InReview = 'InReview'
}

export type GameWithPlayersReview = {
  __typename?: 'GameWithPlayersReview';
  game: Game;
  review?: Maybe<PostGameReview>;
};

export type GetHistoricalGamesResponse = {
  __typename?: 'GetHistoricalGamesResponse';
  games: Array<Game>;
  totalGames: Scalars['Int'];
};

export type GetNotificationInputs = {
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
};

/** The type of global event notification */
export enum GlobalEventNotificationType {
  PlayerProfileImageChangeEvent = 'PlayerProfileImageChangeEvent',
  VenueImageChangeEvent = 'VenueImageChangeEvent'
}

export type GlobalNotificationEvent = PlayerProfileImageChangeEvent | VenueImageChangeEvent;

export type Guest = {
  __typename?: 'Guest';
  invitedByPlayerID: Scalars['ID'];
  name: Scalars['String'];
};

/** The type or category of image */
export enum ImageType {
  teamprofile = 'teamprofile',
  venue = 'venue'
}

export type InvitePlayerInputs = {
  email: Scalars['String'];
};

export type JoinRequest = {
  __typename?: 'JoinRequest';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  player: Player;
  status: TeamJoinRequestStatus;
  team: Team;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  accessToken: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptJoinRequest: JoinRequest;
  addGuestToGame: Game;
  addPlayerToGame: Game;
  cancelSubscription: Scalars['Boolean'];
  cashOutRequest: Scalars['Void'];
  confirmEmail: Scalars['Boolean'];
  createGame: Game;
  createPostGameReview: PostGameReview;
  createReoccuringGame: ReoccuringGame;
  createTeam: Team;
  createTeamRule: Rule;
  deleteGame: Game;
  deleteReoccuringGame: ReoccuringGame;
  deleteTeamRule: Rule;
  deleteVenue: Venue;
  emailLogin: LoginResponse;
  emailRegister: LoginResponse;
  finaliseGame: Scalars['Boolean'];
  generateGamesFromReoccuringGames?: Maybe<Scalars['Void']>;
  googleLogin: LoginResponse;
  googleRegister: LoginResponse;
  initialiseSubscription: CreateSubscriptionResponse;
  initialiseTopupPayment: initialiseTopupPaymentResponse;
  invitePlayerToJoinTeam: Scalars['Boolean'];
  joinWaitingList: Game;
  leaveWaitingList: Game;
  logout: Scalars['Boolean'];
  markAllRead: Array<Notification>;
  rejectJoinRequest: JoinRequest;
  removeGuestFromGame: Game;
  removePlayerFromGame: Game;
  removePlayerFromTeam: Team;
  requestToJoinTeam: JoinRequest;
  resendConfirmationEmail: LoginResponse;
  /** Log a player out of PTB */
  revokeRefreshTokenForUser: Scalars['Boolean'];
  saveVenue: Venue;
  spendMoney: Array<Transaction>;
  toggleActiveTeam: Scalars['Boolean'];
  updateGame: Game;
  updatePlayer: Player;
  updatePlayersTeamProfile: TeamProfile;
  updatePostGameReview: PostGameReview;
  updateReoccuringGame: ReoccuringGame;
  updateTeam: Team;
  updateTeamRule: Rule;
};


export type MutationacceptJoinRequestArgs = {
  joinRequestID: Scalars['String'];
};


export type MutationaddGuestToGameArgs = {
  gameID: Scalars['String'];
  name: Scalars['String'];
};


export type MutationaddPlayerToGameArgs = {
  gameID: Scalars['String'];
  playerID: Scalars['String'];
};


export type MutationcashOutRequestArgs = {
  inputs: CashOutRequestInputs;
};


export type MutationconfirmEmailArgs = {
  token: Scalars['String'];
};


export type MutationcreateGameArgs = {
  data: CreateGameInput;
};


export type MutationcreatePostGameReviewArgs = {
  inputs: CreatePostGameReviewInputs;
};


export type MutationcreateReoccuringGameArgs = {
  inputs: CreateReoccuringGameInputs;
};


export type MutationcreateTeamArgs = {
  input: CreateTeamInput;
};


export type MutationcreateTeamRuleArgs = {
  inputs: CreateTeamRuleInputs;
};


export type MutationdeleteGameArgs = {
  gameID: Scalars['String'];
};


export type MutationdeleteReoccuringGameArgs = {
  id: Scalars['String'];
};


export type MutationdeleteTeamRuleArgs = {
  ruleID: Scalars['String'];
};


export type MutationdeleteVenueArgs = {
  venueid: Scalars['String'];
};


export type MutationemailLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationemailRegisterArgs = {
  input: EmailRegisterInput;
};


export type MutationfinaliseGameArgs = {
  input: FinaliseGameInputs;
};


export type MutationgoogleLoginArgs = {
  code: Scalars['String'];
};


export type MutationgoogleRegisterArgs = {
  code: Scalars['String'];
};


export type MutationinitialiseSubscriptionArgs = {
  pricingID: Scalars['String'];
};


export type MutationinitialiseTopupPaymentArgs = {
  pricingID: Scalars['String'];
};


export type MutationinvitePlayerToJoinTeamArgs = {
  input: InvitePlayerInputs;
};


export type MutationjoinWaitingListArgs = {
  GameID: Scalars['String'];
};


export type MutationleaveWaitingListArgs = {
  GameID: Scalars['String'];
};


export type MutationrejectJoinRequestArgs = {
  joinRequestID: Scalars['String'];
};


export type MutationremoveGuestFromGameArgs = {
  gameID: Scalars['String'];
  name: Scalars['String'];
};


export type MutationremovePlayerFromGameArgs = {
  gameID: Scalars['String'];
  playerID: Scalars['String'];
};


export type MutationremovePlayerFromTeamArgs = {
  teamProfileID: Scalars['String'];
};


export type MutationrequestToJoinTeamArgs = {
  joinCode: Scalars['String'];
};


export type MutationrevokeRefreshTokenForUserArgs = {
  playerID: Scalars['String'];
};


export type MutationsaveVenueArgs = {
  inputs: SaveVenueInputs;
};


export type MutationspendMoneyArgs = {
  inputs: SpendMoneyInputs;
};


export type MutationtoggleActiveTeamArgs = {
  newTeamID: Scalars['String'];
};


export type MutationupdateGameArgs = {
  data: UpdateGameInput;
};


export type MutationupdatePlayerArgs = {
  inputs: UpdatePlayerInputs;
};


export type MutationupdatePlayersTeamProfileArgs = {
  position?: InputMaybe<Scalars['String']>;
};


export type MutationupdatePostGameReviewArgs = {
  inputs: UpdatePostGameReviewInputs;
};


export type MutationupdateReoccuringGameArgs = {
  inputs: UpdateReoccuringGameInputs;
};


export type MutationupdateTeamArgs = {
  input: UpdateTeamInput;
};


export type MutationupdateTeamRuleArgs = {
  inputs: UpdateTeamRuleInputs;
};

export type Notification = {
  __typename?: 'Notification';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  isRead: Scalars['Boolean'];
  message: Scalars['String'];
  priority: NotificationPriority;
  redirect?: Maybe<Scalars['String']>;
};

/** low medium or high */
export enum NotificationPriority {
  high = 'high',
  low = 'low',
  medium = 'medium'
}

export type PaymentCard = {
  __typename?: 'PaymentCard';
  brand: Scalars['String'];
  last4: Scalars['String'];
};

export type PlaceDetails = {
  __typename?: 'PlaceDetails';
  formatted_address?: Maybe<Scalars['String']>;
  formatted_phone_number?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Float']>;
  types?: Maybe<Array<Scalars['String']>>;
  website?: Maybe<Scalars['String']>;
};

export type PlacePredictionResult = {
  __typename?: 'PlacePredictionResult';
  description: Scalars['String'];
  distance_meters?: Maybe<Scalars['Float']>;
  matched_substrings: Array<PredictionSubstring>;
  place_id: Scalars['String'];
  structured_formatting: StructuredFormatting;
  terms: Array<PredictionTerm>;
  types: Array<Scalars['String']>;
};

export type Player = {
  __typename?: 'Player';
  activeTeam?: Maybe<Team>;
  attendance: Array<Attendance>;
  balance: Scalars['Float'];
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  ownedTeams: Team;
  status: PlayerStatus;
  teamProfile?: Maybe<TeamProfile>;
};


export type PlayerattendanceArgs = {
  filters?: InputMaybe<CommonFilters>;
};

/** A players profile image has been changed */
export type PlayerProfileImageChangeEvent = {
  __typename?: 'PlayerProfileImageChangeEvent';
  eventType: GlobalEventNotificationType;
  imageURL: Scalars['URL'];
  teamID: Scalars['ID'];
  teamProfileID: Scalars['ID'];
};

/** Active, Inactive, or Deleted */
export enum PlayerStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
  INACTIVE = 'INACTIVE'
}

/** Active or Inactive */
export enum PlayerTeamsStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export type PostGameReview = {
  __typename?: 'PostGameReview';
  createdAt: Scalars['DateTime'];
  game: Game;
  goals: Scalars['Int'];
  highlight?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  mvpVotePlayer: Player;
  player: Player;
  team: Team;
  teamRating: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  win: Scalars['Boolean'];
};

export type PredictionSubstring = {
  __typename?: 'PredictionSubstring';
  length: Scalars['Float'];
  offset: Scalars['Float'];
};

export type PredictionTerm = {
  __typename?: 'PredictionTerm';
  offset: Scalars['String'];
  value: Scalars['String'];
};

export type Pricing = {
  __typename?: 'Pricing';
  amountCents: Scalars['Int'];
  currency: Scalars['String'];
  id: Scalars['String'];
  interval?: Maybe<SubscriptionInterval>;
};

export type Product = {
  __typename?: 'Product';
  description: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  pricing: Array<Pricing>;
  status: ProductStatus;
};

/** active, inactive or comingsoon */
export enum ProductStatus {
  Active = 'Active',
  ComingSoon = 'ComingSoon',
  Inactive = 'Inactive'
}

export type Profile = {
  __typename?: 'Profile';
  authProvider: AuthProviders;
  averageGoalsPerMatch: Scalars['Float'];
  balance: Scalars['Float'];
  emailConfirmed: Scalars['Boolean'];
  /** Is the requesting player an admin of the profiles team profile */
  isTeamAdmin: Scalars['Boolean'];
  player: Player;
  recentReviews: Array<PostGameReview>;
  teamProfile: TeamProfile;
  teams: Array<Team>;
  totalGames: Scalars['Int'];
  totalGamesWon: Scalars['Int'];
  totalGoals: Scalars['Int'];
  totalMvpVotes: Scalars['Int'];
  totalMvpWins: Scalars['Int'];
  transactions: Array<Transaction>;
  transactionsCount: Scalars['Int'];
};


export type ProfiletransactionsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  searchText?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<SortDirection>;
};


export type ProfiletransactionsCountArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  searchText?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<SortDirection>;
};

export type PtbSubscription = {
  __typename?: 'PtbSubscription';
  card?: Maybe<PaymentCard>;
  current_period_end: Scalars['DateTime'];
  id: Scalars['String'];
  product?: Maybe<Product>;
  status: SubscriptionStatus;
};

export type Query = {
  __typename?: 'Query';
  createFileUploadUrl: Scalars['String'];
  getActiveTeamJoinRequests: Array<JoinRequest>;
  getBalanceTopupPlans: Array<Product>;
  getGame?: Maybe<Game>;
  getHistoricalGames: GetHistoricalGamesResponse;
  getMyPendingJoinRequests: Array<JoinRequest>;
  getMyProfile: Profile;
  getNotifications: Array<Notification>;
  getPlayerBalance: Scalars['Float'];
  getPlayersActiveTeamProfile?: Maybe<TeamProfile>;
  getPlayersOnTeam: Array<Player>;
  getPlayersProfile: Profile;
  getPlayersRecentlyFinalisedGames: Array<GameWithPlayersReview>;
  /** Get all the players team profiles */
  getPlayersTeams: Array<TeamProfile>;
  getPostGameReview?: Maybe<PostGameReview>;
  getTeam?: Maybe<Team>;
  getTeamPaymentPlans: Array<Product>;
  getTeamRules: Array<Rule>;
  /** Get the subscription status of the players active team */
  getTeamSubscription?: Maybe<PtbSubscription>;
  getUpcomingGames: Array<Game>;
  getVenues: Array<Venue>;
  lookupPlace?: Maybe<PlaceDetails>;
  lookupPlacePredictions: Array<PlacePredictionResult>;
  searchGames: Array<Game>;
  searchGamesCount: Scalars['Int'];
  test: Scalars['String'];
  testSubscription2: Player;
};


export type QuerycreateFileUploadUrlArgs = {
  inputs: FileUploadInputs;
};


export type QuerygetGameArgs = {
  gameID: Scalars['String'];
};


export type QuerygetHistoricalGamesArgs = {
  date?: InputMaybe<Scalars['Date']>;
  orderBy: Scalars['String'];
  orderDirection: SortDirection;
  page?: Scalars['Int'];
  rowsPerPage?: Scalars['Int'];
  searchText?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<GameStatus>;
};


export type QuerygetNotificationsArgs = {
  inputs: GetNotificationInputs;
};


export type QuerygetPlayersProfileArgs = {
  playerID: Scalars['String'];
};


export type QuerygetPostGameReviewArgs = {
  reviewID: Scalars['String'];
};


export type QuerygetTeamArgs = {
  teamID: Scalars['String'];
};


export type QuerylookupPlaceArgs = {
  placeid: Scalars['String'];
};


export type QuerylookupPlacePredictionsArgs = {
  address: Scalars['String'];
};


export type QuerysearchGamesArgs = {
  date?: InputMaybe<Scalars['Date']>;
  orderBy: Scalars['String'];
  orderDirection: SortDirection;
  page?: Scalars['Int'];
  rowsPerPage?: Scalars['Int'];
  searchText?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<GameStatus>;
};


export type QuerysearchGamesCountArgs = {
  date?: InputMaybe<Scalars['Date']>;
  orderBy: Scalars['String'];
  orderDirection: SortDirection;
  page?: Scalars['Int'];
  rowsPerPage?: Scalars['Int'];
  searchText?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<GameStatus>;
};

export type ReoccuringGame = {
  __typename?: 'ReoccuringGame';
  frequency: ReoccuringGameFrequency;
  gameDay: DayOfWeek;
  gameTime: Scalars['DateTime'];
  id: Scalars['String'];
  maxPlayers: Scalars['Float'];
  minPlayers: Scalars['Float'];
  name: Scalars['String'];
  status: ReoccuringGameStatus;
  team: Team;
  venue: Venue;
};

/** Weekly, Fortnightly or Monthly */
export enum ReoccuringGameFrequency {
  FORTNIGHTLY = 'FORTNIGHTLY',
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY'
}

/** Running or Paused */
export enum ReoccuringGameStatus {
  PAUSED = 'PAUSED',
  RUNNING = 'RUNNING'
}

export type Rule = {
  __typename?: 'Rule';
  createdAt: Scalars['DateTime'];
  createdByPlayer: Player;
  id: Scalars['String'];
  ruleDetails: Scalars['String'];
  ruleTitle: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  updatedByPlayer: Player;
};

export type SaveVenueInputs = {
  address?: InputMaybe<Scalars['String']>;
  clearImage?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  postcode: Scalars['Int'];
  website?: InputMaybe<Scalars['String']>;
};

/** Sort direction of the sort; asc or desc */
export enum SortDirection {
  asc = 'asc',
  desc = 'desc'
}

export type SpendMoneyInputs = {
  amount: Scalars['Float'];
  description: Scalars['String'];
  playerIDs: Array<Scalars['String']>;
  whoPaid: Scalars['String'];
};

export type StructuredFormatting = {
  __typename?: 'StructuredFormatting';
  main_text: Scalars['String'];
  main_text_matched_substrings: Array<PredictionSubstring>;
  secondary_text: Scalars['String'];
  secondary_text_matched_substrings?: Maybe<Array<PredictionSubstring>>;
};

export type Subscription = {
  __typename?: 'Subscription';
  /** Subscribe to live notifications for a team */
  globalNotification: GlobalNotificationEvent;
  testSubscription: Player;
  /** Subscribe to events for upcoming games such as players signings up or games being created */
  upcomingGamesNotification: UpcomingGamesEvent;
};


export type SubscriptionglobalNotificationArgs = {
  teamID: Scalars['ID'];
};


export type SubscriptionupcomingGamesNotificationArgs = {
  teamID: Scalars['ID'];
};

/** month or year, based on stripe options */
export enum SubscriptionInterval {
  month = 'month',
  year = 'year'
}

/** Same as Stripe: https://stripe.com/docs/billing/subscriptions/overview#subscription-statuses */
export enum SubscriptionStatus {
  active = 'active',
  canceled = 'canceled',
  incomplete = 'incomplete',
  incomplete_expired = 'incomplete_expired',
  past_due = 'past_due',
  unpaid = 'unpaid'
}

export type Team = {
  __typename?: 'Team';
  active: Scalars['Boolean'];
  description: Scalars['String'];
  id: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  joinCode: Scalars['String'];
  mvpVotingMins: Scalars['Int'];
  name: Scalars['String'];
  owner: Player;
  players: Array<Player>;
  reoccuringGames: Array<ReoccuringGame>;
  rulesEditableByPlayers: Scalars['Boolean'];
  teamProfiles: Array<TeamProfile>;
  timezone: Scalars['TimeZone'];
  venues: Array<Venue>;
  whatsappJoinLink?: Maybe<Scalars['String']>;
};

/** Pending, approved or rejected */
export enum TeamJoinRequestStatus {
  Approved = 'Approved',
  Pending = 'Pending',
  Rejected = 'Rejected'
}

export type TeamProfile = {
  __typename?: 'TeamProfile';
  hasJoinedTeamChat: Scalars['Boolean'];
  id: Scalars['String'];
  imageURL?: Maybe<Scalars['URL']>;
  isTeamAdmin: Scalars['Boolean'];
  player: Player;
  position?: Maybe<Scalars['String']>;
  status: PlayerTeamsStatus;
  team: Team;
};

export type Transaction = {
  __typename?: 'Transaction';
  amount: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  game?: Maybe<Game>;
  id: Scalars['String'];
  name: Scalars['String'];
  player: Player;
  team: Team;
  type: TransactionType;
};

/** Debit or credit */
export enum TransactionType {
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT'
}

export type UpcomingGamesEvent = AttendanceChangeEvent | GameChangeEvent;

export type UpdateGameInput = {
  allowGuests?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['DateTime']>;
  gameID: Scalars['String'];
  maxPlayers?: InputMaybe<Scalars['Int']>;
  minPlayers?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  venueID?: InputMaybe<Scalars['String']>;
};

export type UpdatePlayerInputs = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdatePostGameReviewInputs = {
  goals?: InputMaybe<Scalars['Float']>;
  highlight?: InputMaybe<Scalars['String']>;
  mvpVotePlayerID?: InputMaybe<Scalars['String']>;
  postGameReviewID: Scalars['String'];
  teamRating?: InputMaybe<Scalars['Float']>;
  win?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateReoccuringGameInputs = {
  frequency?: InputMaybe<ReoccuringGameFrequency>;
  gameDay?: InputMaybe<DayOfWeek>;
  gameTime?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['String'];
  maxPlayers?: InputMaybe<Scalars['Int']>;
  minPlayers?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ReoccuringGameStatus>;
  venueID?: InputMaybe<Scalars['String']>;
};

export type UpdateTeamInput = {
  description: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  timeZone: Scalars['TimeZone'];
  whatsappJoinLink?: InputMaybe<Scalars['String']>;
};

export type UpdateTeamRuleInputs = {
  ruleDetails: Scalars['String'];
  ruleID: Scalars['String'];
  ruleTitle: Scalars['String'];
};

export type Venue = {
  __typename?: 'Venue';
  address?: Maybe<Scalars['String']>;
  games: Game;
  id: Scalars['ID'];
  imageURL?: Maybe<Scalars['URL']>;
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  postcode: Scalars['Int'];
  team: Venue;
  website?: Maybe<Scalars['String']>;
};

/** A venue image has been changed */
export type VenueImageChangeEvent = {
  __typename?: 'VenueImageChangeEvent';
  eventType: GlobalEventNotificationType;
  imageURL: Scalars['URL'];
  teamID: Scalars['ID'];
  venueID: Scalars['ID'];
};

export type WaitingList = {
  __typename?: 'WaitingList';
  createdAt: Scalars['DateTime'];
  playerID: Scalars['ObjectID'];
};

export type WeatherReport = {
  __typename?: 'WeatherReport';
  gameTemp: Scalars['Float'];
  max: Scalars['Int'];
  min: Scalars['Int'];
  precis: Scalars['String'];
  precisCode: Scalars['String'];
  rainfallProbability: Scalars['Int'];
};

export type initialiseTopupPaymentResponse = {
  __typename?: 'initialiseTopupPaymentResponse';
  clientSecret: Scalars['String'];
};

export type GetRecentlyFinalisedGamesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRecentlyFinalisedGamesQuery = { __typename?: 'Query', getPlayersRecentlyFinalisedGames: Array<{ __typename?: 'GameWithPlayersReview', game: { __typename?: 'Game', id: string, date: string, name: string, status: GameStatus, finalisedDate?: string | null, mvpPlayer?: { __typename?: 'Player', id: string, name: string } | null, venue: { __typename?: 'Venue', id: string, name: string }, attendance: Array<{ __typename?: 'Attendance', player: { __typename?: 'Player', id: string, name: string } }> }, review?: { __typename?: 'PostGameReview', id: string, goals: number, highlight?: string | null, updatedAt: string, mvpVotePlayer: { __typename?: 'Player', id: string, name: string } } | null }> };

export type CreatePostGameReviewMutationVariables = Exact<{
  inputs: CreatePostGameReviewInputs;
}>;


export type CreatePostGameReviewMutation = { __typename?: 'Mutation', createPostGameReview: { __typename?: 'PostGameReview', id: string } };

export type UpdatePostGameReviewMutationVariables = Exact<{
  inputs: UpdatePostGameReviewInputs;
}>;


export type UpdatePostGameReviewMutation = { __typename?: 'Mutation', updatePostGameReview: { __typename?: 'PostGameReview', id: string, goals: number, win: boolean, highlight?: string | null, teamRating: number, player: { __typename?: 'Player', id: string, name: string }, mvpVotePlayer: { __typename?: 'Player', id: string, name: string }, game: { __typename?: 'Game', id: string, name: string, attendance: Array<{ __typename?: 'Attendance', id: string, player: { __typename?: 'Player', id: string, name: string } }> }, team: { __typename?: 'Team', id: string, name: string } } };

export type GetPostGameReviewQueryVariables = Exact<{
  reviewId: Scalars['String'];
}>;


export type GetPostGameReviewQuery = { __typename?: 'Query', getPostGameReview?: { __typename?: 'PostGameReview', id: string, goals: number, win: boolean, highlight?: string | null, teamRating: number, player: { __typename?: 'Player', id: string, name: string }, mvpVotePlayer: { __typename?: 'Player', id: string, name: string }, game: { __typename?: 'Game', id: string, name: string, attendance: Array<{ __typename?: 'Attendance', id: string, player: { __typename?: 'Player', id: string, name: string } }> }, team: { __typename?: 'Team', id: string, name: string } } | null };

export type CreateGameMutationVariables = Exact<{
  data: CreateGameInput;
}>;


export type CreateGameMutation = { __typename?: 'Mutation', createGame: { __typename?: 'Game', id: string } };

export type UpdateGameMutationVariables = Exact<{
  data: UpdateGameInput;
}>;


export type UpdateGameMutation = { __typename?: 'Mutation', updateGame: { __typename?: 'Game', id: string } };

export type GetGameQueryVariables = Exact<{
  gameId: Scalars['String'];
}>;


export type GetGameQuery = { __typename?: 'Query', getGame?: { __typename?: 'Game', id: string, date: string, status: GameStatus, name: string, maxPlayers: number, minPlayers: number, finalisedDate?: string | null, mvpPlayer?: { __typename?: 'Player', id: string, name: string } | null, reviews: Array<{ __typename?: 'PostGameReview', id: string, goals: number, highlight?: string | null, win: boolean, teamRating: number, createdAt: string, mvpVotePlayer: { __typename?: 'Player', id: string, name: string }, player: { __typename?: 'Player', id: string, name: string } }>, venue: { __typename?: 'Venue', id: string, name: string, isActive: boolean, address?: string | null, postcode: number, imageURL?: string | null, phone?: string | null, website?: string | null }, attendance: Array<{ __typename?: 'Attendance', id: string, player: { __typename?: 'Player', id: string, name: string } }> } | null };

export type FinaliseGameMutationVariables = Exact<{
  input: FinaliseGameInputs;
}>;


export type FinaliseGameMutation = { __typename?: 'Mutation', finaliseGame: boolean };

export type DeleteGameMutationVariables = Exact<{
  gameId: Scalars['String'];
}>;


export type DeleteGameMutation = { __typename?: 'Mutation', deleteGame: { __typename?: 'Game', id: string } };

export type InitialiseSubscriptionMutationVariables = Exact<{
  pricingId: Scalars['String'];
}>;


export type InitialiseSubscriptionMutation = { __typename?: 'Mutation', initialiseSubscription: { __typename?: 'CreateSubscriptionResponse', subscriptionID: string, clientSecret: string } };

export type GetTeamSubscriptionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTeamSubscriptionQuery = { __typename?: 'Query', getTeamSubscription?: { __typename?: 'PtbSubscription', id: string, status: SubscriptionStatus, current_period_end: string, product?: { __typename?: 'Product', id: string, name: string, description: string, status: ProductStatus, pricing: Array<{ __typename?: 'Pricing', id: string, currency: string, amountCents: number, interval?: SubscriptionInterval | null }> } | null, card?: { __typename?: 'PaymentCard', brand: string, last4: string } | null } | null };

export type GetTeamPaymentPlansQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTeamPaymentPlansQuery = { __typename?: 'Query', getTeamPaymentPlans: Array<{ __typename?: 'Product', id: string, name: string, description: string, status: ProductStatus, pricing: Array<{ __typename?: 'Pricing', id: string, amountCents: number, currency: string, interval?: SubscriptionInterval | null }> }> };

export type CancelTeamSubscriptionMutationVariables = Exact<{ [key: string]: never; }>;


export type CancelTeamSubscriptionMutation = { __typename?: 'Mutation', cancelSubscription: boolean };

export type SpendMoneyMutationVariables = Exact<{
  inputs: SpendMoneyInputs;
}>;


export type SpendMoneyMutation = { __typename?: 'Mutation', spendMoney: Array<{ __typename?: 'Transaction', id: string }> };

export type GetBalanceTopupPlansQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBalanceTopupPlansQuery = { __typename?: 'Query', getBalanceTopupPlans: Array<{ __typename?: 'Product', id: string, name: string, description: string, status: ProductStatus, pricing: Array<{ __typename?: 'Pricing', id: string, currency: string, amountCents: number }> }> };

export type InitTopupPaymentMutationVariables = Exact<{
  pricingId: Scalars['String'];
}>;


export type InitTopupPaymentMutation = { __typename?: 'Mutation', initialiseTopupPayment: { __typename?: 'initialiseTopupPaymentResponse', clientSecret: string } };

export type RemovePlayerFromTeamMutationVariables = Exact<{
  teamProfileID: Scalars['String'];
}>;


export type RemovePlayerFromTeamMutation = { __typename?: 'Mutation', removePlayerFromTeam: { __typename?: 'Team', id: string } };

export type GetMyPendingJoinRequestsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyPendingJoinRequestsQuery = { __typename?: 'Query', getMyPendingJoinRequests: Array<{ __typename?: 'JoinRequest', id: string, status: TeamJoinRequestStatus, createdAt: string, team: { __typename?: 'Team', name: string } }> };

export type CashOutRequestMutationVariables = Exact<{
  inputs: CashOutRequestInputs;
}>;


export type CashOutRequestMutation = { __typename?: 'Mutation', cashOutRequest: any };

export type ToggleActiveTeamMutationVariables = Exact<{
  newTeamID: Scalars['String'];
}>;


export type ToggleActiveTeamMutation = { __typename?: 'Mutation', toggleActiveTeam: boolean };

export type GetActiveTeamQueryVariables = Exact<{ [key: string]: never; }>;


export type GetActiveTeamQuery = { __typename?: 'Query', getPlayersActiveTeamProfile?: { __typename?: 'TeamProfile', team: { __typename?: 'Team', id: string, name: string } } | null };

export type GetPlayersTeamsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPlayersTeamsQuery = { __typename?: 'Query', getPlayersTeams: Array<{ __typename?: 'TeamProfile', id: string, isTeamAdmin: boolean, imageURL?: string | null, position?: string | null, status: PlayerTeamsStatus, team: { __typename?: 'Team', id: string, name: string, active: boolean, description: string, whatsappJoinLink?: string | null, joinCode: string, owner: { __typename?: 'Player', id: string, name: string } } }> };

export type CreateTeamMutationVariables = Exact<{
  input: CreateTeamInput;
}>;


export type CreateTeamMutation = { __typename?: 'Mutation', createTeam: { __typename?: 'Team', id: string } };

export type UpdateTeamMutationVariables = Exact<{
  input: UpdateTeamInput;
}>;


export type UpdateTeamMutation = { __typename?: 'Mutation', updateTeam: { __typename?: 'Team', id: string } };

export type GetTeamQueryVariables = Exact<{
  teamId: Scalars['String'];
}>;


export type GetTeamQuery = { __typename?: 'Query', getTeam?: { __typename?: 'Team', id: string, active: boolean, name: string, description: string, whatsappJoinLink?: string | null, joinCode: string, image?: string | null, venues: Array<{ __typename?: 'Venue', id: string, name: string, isActive: boolean, address?: string | null, postcode: number, imageURL?: string | null, phone?: string | null, website?: string | null }> } | null };

export type GetTeamRulesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTeamRulesQuery = { __typename?: 'Query', getTeamRules: Array<{ __typename?: 'Rule', id: string, ruleTitle: string, ruleDetails: string, createdAt: string, updatedAt: string, createdByPlayer: { __typename?: 'Player', id: string, name: string }, updatedByPlayer: { __typename?: 'Player', id: string, name: string } }> };

export type CreateTeamRuleMutationVariables = Exact<{
  inputs: CreateTeamRuleInputs;
}>;


export type CreateTeamRuleMutation = { __typename?: 'Mutation', createTeamRule: { __typename?: 'Rule', id: string } };

export type UpdateTeamRuleMutationVariables = Exact<{
  inputs: UpdateTeamRuleInputs;
}>;


export type UpdateTeamRuleMutation = { __typename?: 'Mutation', updateTeamRule: { __typename?: 'Rule', id: string } };

export type DeleteTeamRuleMutationVariables = Exact<{
  ruleId: Scalars['String'];
}>;


export type DeleteTeamRuleMutation = { __typename?: 'Mutation', deleteTeamRule: { __typename?: 'Rule', id: string } };

export type InvitePlayerToJoinTeamMutationVariables = Exact<{
  input: InvitePlayerInputs;
}>;


export type InvitePlayerToJoinTeamMutation = { __typename?: 'Mutation', invitePlayerToJoinTeam: boolean };

export type GetMyBalanceQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyBalanceQuery = { __typename?: 'Query', getPlayerBalance: number };

export type LookupPlacePredictionsQueryVariables = Exact<{
  address: Scalars['String'];
}>;


export type LookupPlacePredictionsQuery = { __typename?: 'Query', lookupPlacePredictions: Array<{ __typename?: 'PlacePredictionResult', description: string, distance_meters?: number | null, place_id: string, types: Array<string>, terms: Array<{ __typename?: 'PredictionTerm', offset: string, value: string }>, matched_substrings: Array<{ __typename?: 'PredictionSubstring', length: number, offset: number }>, structured_formatting: { __typename?: 'StructuredFormatting', main_text: string, secondary_text: string, main_text_matched_substrings: Array<{ __typename?: 'PredictionSubstring', offset: number, length: number }>, secondary_text_matched_substrings?: Array<{ __typename?: 'PredictionSubstring', length: number, offset: number }> | null } }> };

export type LookupPlaceDetailsQueryVariables = Exact<{
  placeid: Scalars['String'];
}>;


export type LookupPlaceDetailsQuery = { __typename?: 'Query', lookupPlace?: { __typename?: 'PlaceDetails', name?: string | null, formatted_address?: string | null, formatted_phone_number?: string | null, website?: string | null, rating?: number | null, types?: Array<string> | null, postcode?: string | null, lat?: number | null, lng?: number | null } | null };

export type FileUploadURLQueryVariables = Exact<{
  inputs: FileUploadInputs;
}>;


export type FileUploadURLQuery = { __typename?: 'Query', createFileUploadUrl: string };

export type MyProfileFieldsFragment = { __typename?: 'Profile', balance: number, authProvider: AuthProviders, emailConfirmed: boolean, player: { __typename?: 'Player', id: string, name: string, email: string }, teamProfile: { __typename?: 'TeamProfile', id: string, position?: string | null, imageURL?: string | null, isTeamAdmin: boolean, hasJoinedTeamChat: boolean, team: { __typename?: 'Team', id: string, name: string, image?: string | null, description: string, whatsappJoinLink?: string | null, rulesEditableByPlayers: boolean, timezone: any, owner: { __typename?: 'Player', id: string, name: string }, players: Array<{ __typename?: 'Player', id: string, name: string, status: PlayerStatus, balance: number, teamProfile?: { __typename?: 'TeamProfile', id: string, imageURL?: string | null, position?: string | null, isTeamAdmin: boolean, status: PlayerTeamsStatus } | null }> } }, teams: Array<{ __typename?: 'Team', id: string, name: string }> };

export type GetMyProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyProfileQuery = { __typename?: 'Query', getMyProfile: { __typename?: 'Profile', balance: number, authProvider: AuthProviders, emailConfirmed: boolean, player: { __typename?: 'Player', id: string, name: string, email: string }, teamProfile: { __typename?: 'TeamProfile', id: string, position?: string | null, imageURL?: string | null, isTeamAdmin: boolean, hasJoinedTeamChat: boolean, team: { __typename?: 'Team', id: string, name: string, image?: string | null, description: string, whatsappJoinLink?: string | null, rulesEditableByPlayers: boolean, timezone: any, owner: { __typename?: 'Player', id: string, name: string }, players: Array<{ __typename?: 'Player', id: string, name: string, status: PlayerStatus, balance: number, teamProfile?: { __typename?: 'TeamProfile', id: string, imageURL?: string | null, position?: string | null, isTeamAdmin: boolean, status: PlayerTeamsStatus } | null }> } }, teams: Array<{ __typename?: 'Team', id: string, name: string }> } };

export type GetProfileQueryVariables = Exact<{
  playerID: Scalars['String'];
}>;


export type GetProfileQuery = { __typename?: 'Query', getPlayersProfile: { __typename?: 'Profile', totalGames: number, totalGamesWon: number, totalMvpVotes: number, totalGoals: number, averageGoalsPerMatch: number, totalMvpWins: number, player: { __typename?: 'Player', id: string, name: string }, teamProfile: { __typename?: 'TeamProfile', position?: string | null, imageURL?: string | null, isTeamAdmin: boolean, hasJoinedTeamChat: boolean, team: { __typename?: 'Team', id: string, name: string, image?: string | null, rulesEditableByPlayers: boolean, owner: { __typename?: 'Player', id: string, name: string } } }, teams: Array<{ __typename?: 'Team', id: string, name: string, whatsappJoinLink?: string | null }>, recentReviews: Array<{ __typename?: 'PostGameReview', id: string, highlight?: string | null, win: boolean, goals: number, createdAt: string }>, transactions: Array<{ __typename?: 'Transaction', id: string, amount: number, name: string, type: TransactionType, createdAt: string }> } };

export type GetNotificationsQueryVariables = Exact<{
  inputs: GetNotificationInputs;
}>;


export type GetNotificationsQuery = { __typename?: 'Query', getNotifications: Array<{ __typename?: 'Notification', id: string, priority: NotificationPriority, isRead: boolean, message: string, redirect?: string | null, createdAt: string }> };

export type MarkNotificationsReadMutationVariables = Exact<{ [key: string]: never; }>;


export type MarkNotificationsReadMutation = { __typename?: 'Mutation', markAllRead: Array<{ __typename?: 'Notification', id: string }> };

export type GlobalNotificationSubscriptionVariables = Exact<{
  teamID: Scalars['ID'];
}>;


export type GlobalNotificationSubscription = { __typename?: 'Subscription', globalNotification: { __typename?: 'PlayerProfileImageChangeEvent', teamID: string, eventType: GlobalEventNotificationType, teamProfileID: string, imageURL: string } | { __typename?: 'VenueImageChangeEvent', teamID: string, eventType: GlobalEventNotificationType, venueID: string, imageURL: string } };

export type AddPlayerToGameMutationVariables = Exact<{
  gameId: Scalars['String'];
  playerId: Scalars['String'];
}>;


export type AddPlayerToGameMutation = { __typename?: 'Mutation', addPlayerToGame: { __typename?: 'Game', id: string, date: string, name: string, maxPlayers: number, minPlayers: number, allowGuests: boolean, allowWaitingList: boolean, status: GameStatus, guests: Array<{ __typename?: 'Guest', invitedByPlayerID: string, name: string }>, waitingList: Array<{ __typename?: 'WaitingList', playerID: any, createdAt: string }>, weather?: { __typename?: 'WeatherReport', precis: string, precisCode: string, rainfallProbability: number, gameTemp: number, min: number, max: number } | null, attendance: Array<{ __typename?: 'Attendance', id: string, player: { __typename?: 'Player', id: string, name: string, teamProfile?: { __typename?: 'TeamProfile', id: string, position?: string | null, imageURL?: string | null } | null } }>, venue: { __typename?: 'Venue', id: string, name: string, isActive: boolean, address?: string | null, postcode: number, imageURL?: string | null, phone?: string | null, website?: string | null } } };

export type RemovePlayerFromGameMutationVariables = Exact<{
  playerId: Scalars['String'];
  gameId: Scalars['String'];
}>;


export type RemovePlayerFromGameMutation = { __typename?: 'Mutation', removePlayerFromGame: { __typename?: 'Game', id: string, date: string, name: string, maxPlayers: number, minPlayers: number, allowGuests: boolean, allowWaitingList: boolean, status: GameStatus, guests: Array<{ __typename?: 'Guest', invitedByPlayerID: string, name: string }>, waitingList: Array<{ __typename?: 'WaitingList', playerID: any, createdAt: string }>, weather?: { __typename?: 'WeatherReport', precis: string, precisCode: string, rainfallProbability: number, gameTemp: number, min: number, max: number } | null, attendance: Array<{ __typename?: 'Attendance', id: string, player: { __typename?: 'Player', id: string, name: string, teamProfile?: { __typename?: 'TeamProfile', id: string, position?: string | null, imageURL?: string | null } | null } }>, venue: { __typename?: 'Venue', id: string, name: string, isActive: boolean, address?: string | null, postcode: number, imageURL?: string | null, phone?: string | null, website?: string | null } } };

export type JoinWaitingListMutationVariables = Exact<{
  gameID: Scalars['String'];
}>;


export type JoinWaitingListMutation = { __typename?: 'Mutation', joinWaitingList: { __typename?: 'Game', id: string, waitingList: Array<{ __typename?: 'WaitingList', playerID: any, createdAt: string }> } };

export type LeaveWaitingListMutationVariables = Exact<{
  gameID: Scalars['String'];
}>;


export type LeaveWaitingListMutation = { __typename?: 'Mutation', leaveWaitingList: { __typename?: 'Game', id: string, waitingList: Array<{ __typename?: 'WaitingList', playerID: any, createdAt: string }> } };

export type GetHistoricalGamesQueryVariables = Exact<{
  rowsPerPage: Scalars['Int'];
  orderBy: Scalars['String'];
  orderDirection: SortDirection;
  page: Scalars['Int'];
  date?: InputMaybe<Scalars['Date']>;
  status?: InputMaybe<GameStatus>;
  searchText?: InputMaybe<Scalars['String']>;
}>;


export type GetHistoricalGamesQuery = { __typename?: 'Query', getHistoricalGames: { __typename?: 'GetHistoricalGamesResponse', totalGames: number, games: Array<{ __typename?: 'Game', id: string, date: string, name: string }> } };

export type SearchGamesQueryVariables = Exact<{
  rowsPerPage: Scalars['Int'];
  orderBy: Scalars['String'];
  orderDirection: SortDirection;
  page: Scalars['Int'];
  date?: InputMaybe<Scalars['Date']>;
  status?: InputMaybe<GameStatus>;
  searchText?: InputMaybe<Scalars['String']>;
}>;


export type SearchGamesQuery = { __typename?: 'Query', searchGamesCount: number, searchGames: Array<{ __typename?: 'Game', id: string, date: string, name: string }> };

export type GuestFieldsFragment = { __typename?: 'Guest', invitedByPlayerID: string, name: string };

export type WaitingListFieldsFragment = { __typename?: 'WaitingList', playerID: any, createdAt: string };

export type ViewGameFieldsFragment = { __typename?: 'Game', id: string, name: string, date: string, status: GameStatus, inVoting: boolean, allowGuests: boolean, allowWaitingList: boolean, minPlayers: number, maxPlayers: number, finalisedDate?: string | null, guests: Array<{ __typename?: 'Guest', invitedByPlayerID: string, name: string }>, waitingList: Array<{ __typename?: 'WaitingList', playerID: any, createdAt: string }>, mvpPlayer?: { __typename?: 'Player', id: string, name: string, teamProfile?: { __typename?: 'TeamProfile', id: string, imageURL?: string | null } | null } | null, reviews: Array<{ __typename?: 'PostGameReview', id: string, goals: number, highlight?: string | null, win: boolean, teamRating: number, createdAt: string, mvpVotePlayer: { __typename?: 'Player', id: string, name: string }, player: { __typename?: 'Player', id: string, name: string, teamProfile?: { __typename?: 'TeamProfile', id: string, imageURL?: string | null } | null } }>, venue: { __typename?: 'Venue', id: string, name: string, isActive: boolean, address?: string | null, postcode: number, imageURL?: string | null, phone?: string | null, website?: string | null }, attendance: Array<{ __typename?: 'Attendance', id: string, player: { __typename?: 'Player', id: string, name: string, teamProfile?: { __typename?: 'TeamProfile', id: string, imageURL?: string | null, position?: string | null } | null } }> };

export type ViewGameQueryVariables = Exact<{
  gameId: Scalars['String'];
}>;


export type ViewGameQuery = { __typename?: 'Query', getGame?: { __typename?: 'Game', id: string, name: string, date: string, status: GameStatus, inVoting: boolean, allowGuests: boolean, allowWaitingList: boolean, minPlayers: number, maxPlayers: number, finalisedDate?: string | null, guests: Array<{ __typename?: 'Guest', invitedByPlayerID: string, name: string }>, waitingList: Array<{ __typename?: 'WaitingList', playerID: any, createdAt: string }>, mvpPlayer?: { __typename?: 'Player', id: string, name: string, teamProfile?: { __typename?: 'TeamProfile', id: string, imageURL?: string | null } | null } | null, reviews: Array<{ __typename?: 'PostGameReview', id: string, goals: number, highlight?: string | null, win: boolean, teamRating: number, createdAt: string, mvpVotePlayer: { __typename?: 'Player', id: string, name: string }, player: { __typename?: 'Player', id: string, name: string, teamProfile?: { __typename?: 'TeamProfile', id: string, imageURL?: string | null } | null } }>, venue: { __typename?: 'Venue', id: string, name: string, isActive: boolean, address?: string | null, postcode: number, imageURL?: string | null, phone?: string | null, website?: string | null }, attendance: Array<{ __typename?: 'Attendance', id: string, player: { __typename?: 'Player', id: string, name: string, teamProfile?: { __typename?: 'TeamProfile', id: string, imageURL?: string | null, position?: string | null } | null } }> } | null };

export type AddGuestToGameMutationVariables = Exact<{
  gameId: Scalars['String'];
  name: Scalars['String'];
}>;


export type AddGuestToGameMutation = { __typename?: 'Mutation', addGuestToGame: { __typename?: 'Game', id: string, guests: Array<{ __typename?: 'Guest', name: string, invitedByPlayerID: string }> } };

export type RemoveGuestFromGameMutationVariables = Exact<{
  gameId: Scalars['String'];
  name: Scalars['String'];
}>;


export type RemoveGuestFromGameMutation = { __typename?: 'Mutation', removeGuestFromGame: { __typename?: 'Game', id: string, guests: Array<{ __typename?: 'Guest', name: string, invitedByPlayerID: string }> } };

export type UpcomingGameFieldsFragment = { __typename?: 'Game', id: string, date: string, name: string, maxPlayers: number, minPlayers: number, allowGuests: boolean, allowWaitingList: boolean, status: GameStatus, guests: Array<{ __typename?: 'Guest', invitedByPlayerID: string, name: string }>, waitingList: Array<{ __typename?: 'WaitingList', playerID: any, createdAt: string }>, weather?: { __typename?: 'WeatherReport', precis: string, precisCode: string, rainfallProbability: number, gameTemp: number, min: number, max: number } | null, attendance: Array<{ __typename?: 'Attendance', id: string, player: { __typename?: 'Player', id: string, name: string, teamProfile?: { __typename?: 'TeamProfile', id: string, position?: string | null, imageURL?: string | null } | null } }>, venue: { __typename?: 'Venue', id: string, name: string, isActive: boolean, address?: string | null, postcode: number, imageURL?: string | null, phone?: string | null, website?: string | null } };

export type GetUpcomingGamesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUpcomingGamesQuery = { __typename?: 'Query', getUpcomingGames: Array<{ __typename?: 'Game', id: string, date: string, name: string, maxPlayers: number, minPlayers: number, allowGuests: boolean, allowWaitingList: boolean, status: GameStatus, guests: Array<{ __typename?: 'Guest', invitedByPlayerID: string, name: string }>, waitingList: Array<{ __typename?: 'WaitingList', playerID: any, createdAt: string }>, weather?: { __typename?: 'WeatherReport', precis: string, precisCode: string, rainfallProbability: number, gameTemp: number, min: number, max: number } | null, attendance: Array<{ __typename?: 'Attendance', id: string, player: { __typename?: 'Player', id: string, name: string, teamProfile?: { __typename?: 'TeamProfile', id: string, position?: string | null, imageURL?: string | null } | null } }>, venue: { __typename?: 'Venue', id: string, name: string, isActive: boolean, address?: string | null, postcode: number, imageURL?: string | null, phone?: string | null, website?: string | null } }> };

export type UpcomingGamesNotificationsSubscriptionVariables = Exact<{
  teamId: Scalars['ID'];
}>;


export type UpcomingGamesNotificationsSubscription = { __typename?: 'Subscription', upcomingGamesNotification: { __typename?: 'AttendanceChangeEvent', teamID: string, attendanceID: string, gameID: string, playerID: string, isSignup: boolean } | { __typename?: 'GameChangeEvent', teamID: string, type: GameChangeEventType } };

export type GetTeamJoinRequestsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTeamJoinRequestsQuery = { __typename?: 'Query', getActiveTeamJoinRequests: Array<{ __typename?: 'JoinRequest', id: string, status: TeamJoinRequestStatus, createdAt: string, player: { __typename?: 'Player', id: string, name: string, email: string }, team: { __typename?: 'Team', id: string, name: string } }> };

export type AcceptJoinRequestMutationVariables = Exact<{
  joinRequestId: Scalars['String'];
}>;


export type AcceptJoinRequestMutation = { __typename?: 'Mutation', acceptJoinRequest: { __typename?: 'JoinRequest', id: string } };

export type RejectJoinRequestMutationVariables = Exact<{
  joinRequestId: Scalars['String'];
}>;


export type RejectJoinRequestMutation = { __typename?: 'Mutation', rejectJoinRequest: { __typename?: 'JoinRequest', id: string } };

export type PlayerProfileFieldsFragment = { __typename?: 'Profile', totalGames: number, totalGamesWon: number, totalMvpVotes: number, totalMvpWins: number, totalGoals: number, averageGoalsPerMatch: number, player: { __typename?: 'Player', id: string, name: string }, teamProfile: { __typename?: 'TeamProfile', id: string, imageURL?: string | null, position?: string | null }, recentReviews: Array<{ __typename?: 'PostGameReview', id: string, goals: number, win: boolean, highlight?: string | null, teamRating: number, createdAt: string, mvpVotePlayer: { __typename?: 'Player', id: string, name: string } }> };

export type SearchProfileQueryVariables = Exact<{
  playerId: Scalars['String'];
}>;


export type SearchProfileQuery = { __typename?: 'Query', getPlayersProfile: { __typename?: 'Profile', totalGames: number, totalGamesWon: number, totalMvpVotes: number, totalMvpWins: number, totalGoals: number, averageGoalsPerMatch: number, player: { __typename?: 'Player', id: string, name: string }, teamProfile: { __typename?: 'TeamProfile', id: string, imageURL?: string | null, position?: string | null }, recentReviews: Array<{ __typename?: 'PostGameReview', id: string, goals: number, win: boolean, highlight?: string | null, teamRating: number, createdAt: string, mvpVotePlayer: { __typename?: 'Player', id: string, name: string } }> } };

export type ProfileTransactionsQueryVariables = Exact<{
  playerId: Scalars['String'];
  sortBy?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<SortDirection>;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type ProfileTransactionsQuery = { __typename?: 'Query', getPlayersProfile: { __typename?: 'Profile', transactionsCount: number, player: { __typename?: 'Player', id: string }, transactions: Array<{ __typename?: 'Transaction', id: string, amount: number, name: string, type: TransactionType, createdAt: string }> } };

export type UpdatePlayersTeamProfileMutationVariables = Exact<{
  position?: InputMaybe<Scalars['String']>;
}>;


export type UpdatePlayersTeamProfileMutation = { __typename?: 'Mutation', updatePlayersTeamProfile: { __typename?: 'TeamProfile', id: string, position?: string | null } };

export type ReoccuringGameFieldsFragment = { __typename?: 'ReoccuringGame', id: string, name: string, status: ReoccuringGameStatus, frequency: ReoccuringGameFrequency, minPlayers: number, maxPlayers: number, gameDay: DayOfWeek, gameTime: string, venue: { __typename?: 'Venue', id: string, name: string, isActive: boolean, address?: string | null, postcode: number, imageURL?: string | null, phone?: string | null, website?: string | null } };

export type TeamReoccuringGamesQueryVariables = Exact<{
  teamId: Scalars['String'];
}>;


export type TeamReoccuringGamesQuery = { __typename?: 'Query', getTeam?: { __typename?: 'Team', id: string, reoccuringGames: Array<{ __typename?: 'ReoccuringGame', id: string, name: string, status: ReoccuringGameStatus, frequency: ReoccuringGameFrequency, minPlayers: number, maxPlayers: number, gameDay: DayOfWeek, gameTime: string, venue: { __typename?: 'Venue', id: string, name: string, isActive: boolean, address?: string | null, postcode: number, imageURL?: string | null, phone?: string | null, website?: string | null } }> } | null };

export type CreateReoccuringGameMutationVariables = Exact<{
  inputs: CreateReoccuringGameInputs;
}>;


export type CreateReoccuringGameMutation = { __typename?: 'Mutation', createReoccuringGame: { __typename?: 'ReoccuringGame', id: string, name: string, status: ReoccuringGameStatus, frequency: ReoccuringGameFrequency, minPlayers: number, maxPlayers: number, gameDay: DayOfWeek, gameTime: string, venue: { __typename?: 'Venue', id: string, name: string, isActive: boolean, address?: string | null, postcode: number, imageURL?: string | null, phone?: string | null, website?: string | null } } };

export type UpdateReoccuringGameMutationVariables = Exact<{
  inputs: UpdateReoccuringGameInputs;
}>;


export type UpdateReoccuringGameMutation = { __typename?: 'Mutation', updateReoccuringGame: { __typename?: 'ReoccuringGame', id: string, name: string, status: ReoccuringGameStatus, frequency: ReoccuringGameFrequency, minPlayers: number, maxPlayers: number, gameDay: DayOfWeek, gameTime: string, venue: { __typename?: 'Venue', id: string, name: string, isActive: boolean, address?: string | null, postcode: number, imageURL?: string | null, phone?: string | null, website?: string | null } } };

export type DeleteReoccuringGameMutationVariables = Exact<{
  deleteReoccuringGameId: Scalars['String'];
}>;


export type DeleteReoccuringGameMutation = { __typename?: 'Mutation', deleteReoccuringGame: { __typename?: 'ReoccuringGame', id: string } };

export type GenerateGamesMutationVariables = Exact<{ [key: string]: never; }>;


export type GenerateGamesMutation = { __typename?: 'Mutation', generateGamesFromReoccuringGames?: any | null };

export type VenueFieldsFragment = { __typename?: 'Venue', id: string, name: string, isActive: boolean, address?: string | null, postcode: number, imageURL?: string | null, phone?: string | null, website?: string | null };

export type TeamVenuesQueryVariables = Exact<{ [key: string]: never; }>;


export type TeamVenuesQuery = { __typename?: 'Query', getVenues: Array<{ __typename?: 'Venue', id: string, name: string, isActive: boolean, address?: string | null, postcode: number, imageURL?: string | null, phone?: string | null, website?: string | null }> };

export type SaveVenueMutationVariables = Exact<{
  inputs: SaveVenueInputs;
}>;


export type SaveVenueMutation = { __typename?: 'Mutation', saveVenue: { __typename?: 'Venue', id: string, name: string, isActive: boolean, address?: string | null, postcode: number, imageURL?: string | null, phone?: string | null, website?: string | null } };

export type DeleteVenueMutationVariables = Exact<{
  deleteVenueId: Scalars['String'];
}>;


export type DeleteVenueMutation = { __typename?: 'Mutation', deleteVenue: { __typename?: 'Venue', id: string } };

export type EmailLoginMutationVariables = Exact<{
  password: Scalars['String'];
  email: Scalars['String'];
}>;


export type EmailLoginMutation = { __typename?: 'Mutation', emailLogin: { __typename?: 'LoginResponse', accessToken: string } };

export type GoogleLoginMutationVariables = Exact<{
  code: Scalars['String'];
}>;


export type GoogleLoginMutation = { __typename?: 'Mutation', googleLogin: { __typename?: 'LoginResponse', accessToken: string } };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', logout: boolean };

export type ConfirmEmailMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type ConfirmEmailMutation = { __typename?: 'Mutation', confirmEmail: boolean };

export type ResendConfirmationEmailMutationVariables = Exact<{ [key: string]: never; }>;


export type ResendConfirmationEmailMutation = { __typename?: 'Mutation', resendConfirmationEmail: { __typename?: 'LoginResponse', accessToken: string } };

export type RequestToJoinTeamMutationVariables = Exact<{
  joinCode: Scalars['String'];
}>;


export type RequestToJoinTeamMutation = { __typename?: 'Mutation', requestToJoinTeam: { __typename?: 'JoinRequest', id: string, status: TeamJoinRequestStatus } };

export type GoogleRegisterMutationVariables = Exact<{
  code: Scalars['String'];
}>;


export type GoogleRegisterMutation = { __typename?: 'Mutation', googleRegister: { __typename?: 'LoginResponse', accessToken: string } };

export type EmailRegisterMutationVariables = Exact<{
  input: EmailRegisterInput;
}>;


export type EmailRegisterMutation = { __typename?: 'Mutation', emailRegister: { __typename?: 'LoginResponse', accessToken: string } };

export const MyProfileFieldsFragmentDoc = gql`
    fragment MyProfileFields on Profile {
  balance
  authProvider
  emailConfirmed
  player {
    id
    name
    email
  }
  teamProfile {
    id
    position
    imageURL
    isTeamAdmin
    hasJoinedTeamChat
    team {
      id
      name
      image
      description
      whatsappJoinLink
      rulesEditableByPlayers
      timezone
      owner {
        id
        name
      }
      players {
        id
        name
        status
        balance
        teamProfile {
          id
          imageURL
          position
          isTeamAdmin
          status
        }
      }
    }
  }
  teams {
    id
    name
  }
}
    `;
export const GuestFieldsFragmentDoc = gql`
    fragment GuestFields on Guest {
  invitedByPlayerID
  name
}
    `;
export const WaitingListFieldsFragmentDoc = gql`
    fragment WaitingListFields on WaitingList {
  playerID
  createdAt
}
    `;
export const VenueFieldsFragmentDoc = gql`
    fragment VenueFields on Venue {
  id
  name
  isActive
  address
  postcode
  imageURL
  phone
  website
}
    `;
export const ViewGameFieldsFragmentDoc = gql`
    fragment ViewGameFields on Game {
  id
  name
  date
  status
  inVoting
  allowGuests
  guests {
    ...GuestFields
  }
  allowWaitingList
  waitingList {
    ...WaitingListFields
  }
  minPlayers
  maxPlayers
  mvpPlayer {
    id
    name
    teamProfile {
      id
      imageURL
    }
  }
  reviews {
    id
    goals
    highlight
    win
    teamRating
    createdAt
    mvpVotePlayer {
      id
      name
    }
    player {
      id
      name
      teamProfile {
        id
        imageURL
      }
    }
  }
  finalisedDate
  venue {
    ...VenueFields
  }
  attendance {
    id
    player {
      id
      name
      teamProfile {
        id
        imageURL
        position
      }
    }
  }
}
    ${GuestFieldsFragmentDoc}
${WaitingListFieldsFragmentDoc}
${VenueFieldsFragmentDoc}`;
export const UpcomingGameFieldsFragmentDoc = gql`
    fragment UpcomingGameFields on Game {
  id
  date
  name
  maxPlayers
  minPlayers
  allowGuests
  guests {
    ...GuestFields
  }
  allowWaitingList
  waitingList {
    ...WaitingListFields
  }
  status
  weather {
    precis
    precisCode
    rainfallProbability
    gameTemp
    min
    max
  }
  attendance {
    id
    player {
      id
      name
      teamProfile {
        id
        position
        imageURL
      }
    }
  }
  venue {
    ...VenueFields
  }
}
    ${GuestFieldsFragmentDoc}
${WaitingListFieldsFragmentDoc}
${VenueFieldsFragmentDoc}`;
export const PlayerProfileFieldsFragmentDoc = gql`
    fragment PlayerProfileFields on Profile {
  player {
    id
    name
  }
  totalGames
  totalGamesWon
  totalMvpVotes
  totalMvpWins
  totalGoals
  averageGoalsPerMatch
  teamProfile {
    id
    imageURL
    position
  }
  recentReviews {
    id
    goals
    win
    highlight
    teamRating
    createdAt
    mvpVotePlayer {
      id
      name
    }
  }
}
    `;
export const ReoccuringGameFieldsFragmentDoc = gql`
    fragment ReoccuringGameFields on ReoccuringGame {
  id
  name
  status
  frequency
  minPlayers
  maxPlayers
  gameDay
  gameTime
  venue {
    ...VenueFields
  }
}
    ${VenueFieldsFragmentDoc}`;
export const GetRecentlyFinalisedGamesDocument = gql`
    query GetRecentlyFinalisedGames {
  getPlayersRecentlyFinalisedGames {
    game {
      id
      date
      name
      status
      finalisedDate
      mvpPlayer {
        id
        name
      }
      venue {
        id
        name
      }
      attendance {
        player {
          id
          name
        }
      }
    }
    review {
      id
      goals
      highlight
      mvpVotePlayer {
        id
        name
      }
      updatedAt
    }
  }
}
    `;

/**
 * __useGetRecentlyFinalisedGamesQuery__
 *
 * To run a query within a React component, call `useGetRecentlyFinalisedGamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecentlyFinalisedGamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecentlyFinalisedGamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRecentlyFinalisedGamesQuery(baseOptions?: Apollo.QueryHookOptions<GetRecentlyFinalisedGamesQuery, GetRecentlyFinalisedGamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRecentlyFinalisedGamesQuery, GetRecentlyFinalisedGamesQueryVariables>(GetRecentlyFinalisedGamesDocument, options);
      }
export function useGetRecentlyFinalisedGamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecentlyFinalisedGamesQuery, GetRecentlyFinalisedGamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRecentlyFinalisedGamesQuery, GetRecentlyFinalisedGamesQueryVariables>(GetRecentlyFinalisedGamesDocument, options);
        }
export type GetRecentlyFinalisedGamesQueryHookResult = ReturnType<typeof useGetRecentlyFinalisedGamesQuery>;
export type GetRecentlyFinalisedGamesLazyQueryHookResult = ReturnType<typeof useGetRecentlyFinalisedGamesLazyQuery>;
export type GetRecentlyFinalisedGamesQueryResult = Apollo.QueryResult<GetRecentlyFinalisedGamesQuery, GetRecentlyFinalisedGamesQueryVariables>;
export const CreatePostGameReviewDocument = gql`
    mutation CreatePostGameReview($inputs: CreatePostGameReviewInputs!) {
  createPostGameReview(inputs: $inputs) {
    id
  }
}
    `;
export type CreatePostGameReviewMutationFn = Apollo.MutationFunction<CreatePostGameReviewMutation, CreatePostGameReviewMutationVariables>;

/**
 * __useCreatePostGameReviewMutation__
 *
 * To run a mutation, you first call `useCreatePostGameReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePostGameReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPostGameReviewMutation, { data, loading, error }] = useCreatePostGameReviewMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useCreatePostGameReviewMutation(baseOptions?: Apollo.MutationHookOptions<CreatePostGameReviewMutation, CreatePostGameReviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePostGameReviewMutation, CreatePostGameReviewMutationVariables>(CreatePostGameReviewDocument, options);
      }
export type CreatePostGameReviewMutationHookResult = ReturnType<typeof useCreatePostGameReviewMutation>;
export type CreatePostGameReviewMutationResult = Apollo.MutationResult<CreatePostGameReviewMutation>;
export type CreatePostGameReviewMutationOptions = Apollo.BaseMutationOptions<CreatePostGameReviewMutation, CreatePostGameReviewMutationVariables>;
export const UpdatePostGameReviewDocument = gql`
    mutation UpdatePostGameReview($inputs: UpdatePostGameReviewInputs!) {
  updatePostGameReview(inputs: $inputs) {
    id
    player {
      id
      name
    }
    mvpVotePlayer {
      id
      name
    }
    goals
    win
    highlight
    teamRating
    game {
      id
      name
      attendance {
        id
        player {
          id
          name
        }
      }
    }
    team {
      id
      name
    }
    mvpVotePlayer {
      id
      name
    }
  }
}
    `;
export type UpdatePostGameReviewMutationFn = Apollo.MutationFunction<UpdatePostGameReviewMutation, UpdatePostGameReviewMutationVariables>;

/**
 * __useUpdatePostGameReviewMutation__
 *
 * To run a mutation, you first call `useUpdatePostGameReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePostGameReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePostGameReviewMutation, { data, loading, error }] = useUpdatePostGameReviewMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useUpdatePostGameReviewMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePostGameReviewMutation, UpdatePostGameReviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePostGameReviewMutation, UpdatePostGameReviewMutationVariables>(UpdatePostGameReviewDocument, options);
      }
export type UpdatePostGameReviewMutationHookResult = ReturnType<typeof useUpdatePostGameReviewMutation>;
export type UpdatePostGameReviewMutationResult = Apollo.MutationResult<UpdatePostGameReviewMutation>;
export type UpdatePostGameReviewMutationOptions = Apollo.BaseMutationOptions<UpdatePostGameReviewMutation, UpdatePostGameReviewMutationVariables>;
export const GetPostGameReviewDocument = gql`
    query GetPostGameReview($reviewId: String!) {
  getPostGameReview(reviewID: $reviewId) {
    id
    player {
      id
      name
    }
    mvpVotePlayer {
      id
      name
    }
    goals
    win
    highlight
    teamRating
    game {
      id
      name
      attendance {
        id
        player {
          id
          name
        }
      }
    }
    team {
      id
      name
    }
    mvpVotePlayer {
      id
      name
    }
  }
}
    `;

/**
 * __useGetPostGameReviewQuery__
 *
 * To run a query within a React component, call `useGetPostGameReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPostGameReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPostGameReviewQuery({
 *   variables: {
 *      reviewId: // value for 'reviewId'
 *   },
 * });
 */
export function useGetPostGameReviewQuery(baseOptions: Apollo.QueryHookOptions<GetPostGameReviewQuery, GetPostGameReviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPostGameReviewQuery, GetPostGameReviewQueryVariables>(GetPostGameReviewDocument, options);
      }
export function useGetPostGameReviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPostGameReviewQuery, GetPostGameReviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPostGameReviewQuery, GetPostGameReviewQueryVariables>(GetPostGameReviewDocument, options);
        }
export type GetPostGameReviewQueryHookResult = ReturnType<typeof useGetPostGameReviewQuery>;
export type GetPostGameReviewLazyQueryHookResult = ReturnType<typeof useGetPostGameReviewLazyQuery>;
export type GetPostGameReviewQueryResult = Apollo.QueryResult<GetPostGameReviewQuery, GetPostGameReviewQueryVariables>;
export const CreateGameDocument = gql`
    mutation CreateGame($data: CreateGameInput!) {
  createGame(data: $data) {
    id
  }
}
    `;
export type CreateGameMutationFn = Apollo.MutationFunction<CreateGameMutation, CreateGameMutationVariables>;

/**
 * __useCreateGameMutation__
 *
 * To run a mutation, you first call `useCreateGameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGameMutation, { data, loading, error }] = useCreateGameMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateGameMutation(baseOptions?: Apollo.MutationHookOptions<CreateGameMutation, CreateGameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGameMutation, CreateGameMutationVariables>(CreateGameDocument, options);
      }
export type CreateGameMutationHookResult = ReturnType<typeof useCreateGameMutation>;
export type CreateGameMutationResult = Apollo.MutationResult<CreateGameMutation>;
export type CreateGameMutationOptions = Apollo.BaseMutationOptions<CreateGameMutation, CreateGameMutationVariables>;
export const UpdateGameDocument = gql`
    mutation UpdateGame($data: UpdateGameInput!) {
  updateGame(data: $data) {
    id
  }
}
    `;
export type UpdateGameMutationFn = Apollo.MutationFunction<UpdateGameMutation, UpdateGameMutationVariables>;

/**
 * __useUpdateGameMutation__
 *
 * To run a mutation, you first call `useUpdateGameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGameMutation, { data, loading, error }] = useUpdateGameMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateGameMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGameMutation, UpdateGameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGameMutation, UpdateGameMutationVariables>(UpdateGameDocument, options);
      }
export type UpdateGameMutationHookResult = ReturnType<typeof useUpdateGameMutation>;
export type UpdateGameMutationResult = Apollo.MutationResult<UpdateGameMutation>;
export type UpdateGameMutationOptions = Apollo.BaseMutationOptions<UpdateGameMutation, UpdateGameMutationVariables>;
export const GetGameDocument = gql`
    query GetGame($gameId: String!) {
  getGame(gameID: $gameId) {
    id
    date
    status
    name
    maxPlayers
    minPlayers
    mvpPlayer {
      id
      name
    }
    finalisedDate
    reviews {
      id
      goals
      highlight
      win
      teamRating
      createdAt
      mvpVotePlayer {
        id
        name
      }
      player {
        id
        name
      }
    }
    venue {
      ...VenueFields
    }
    mvpPlayer {
      id
    }
    attendance {
      id
      player {
        id
        name
      }
    }
  }
}
    ${VenueFieldsFragmentDoc}`;

/**
 * __useGetGameQuery__
 *
 * To run a query within a React component, call `useGetGameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGameQuery({
 *   variables: {
 *      gameId: // value for 'gameId'
 *   },
 * });
 */
export function useGetGameQuery(baseOptions: Apollo.QueryHookOptions<GetGameQuery, GetGameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGameQuery, GetGameQueryVariables>(GetGameDocument, options);
      }
export function useGetGameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGameQuery, GetGameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGameQuery, GetGameQueryVariables>(GetGameDocument, options);
        }
export type GetGameQueryHookResult = ReturnType<typeof useGetGameQuery>;
export type GetGameLazyQueryHookResult = ReturnType<typeof useGetGameLazyQuery>;
export type GetGameQueryResult = Apollo.QueryResult<GetGameQuery, GetGameQueryVariables>;
export const FinaliseGameDocument = gql`
    mutation FinaliseGame($input: FinaliseGameInputs!) {
  finaliseGame(input: $input)
}
    `;
export type FinaliseGameMutationFn = Apollo.MutationFunction<FinaliseGameMutation, FinaliseGameMutationVariables>;

/**
 * __useFinaliseGameMutation__
 *
 * To run a mutation, you first call `useFinaliseGameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinaliseGameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finaliseGameMutation, { data, loading, error }] = useFinaliseGameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFinaliseGameMutation(baseOptions?: Apollo.MutationHookOptions<FinaliseGameMutation, FinaliseGameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FinaliseGameMutation, FinaliseGameMutationVariables>(FinaliseGameDocument, options);
      }
export type FinaliseGameMutationHookResult = ReturnType<typeof useFinaliseGameMutation>;
export type FinaliseGameMutationResult = Apollo.MutationResult<FinaliseGameMutation>;
export type FinaliseGameMutationOptions = Apollo.BaseMutationOptions<FinaliseGameMutation, FinaliseGameMutationVariables>;
export const DeleteGameDocument = gql`
    mutation DeleteGame($gameId: String!) {
  deleteGame(gameID: $gameId) {
    id
  }
}
    `;
export type DeleteGameMutationFn = Apollo.MutationFunction<DeleteGameMutation, DeleteGameMutationVariables>;

/**
 * __useDeleteGameMutation__
 *
 * To run a mutation, you first call `useDeleteGameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGameMutation, { data, loading, error }] = useDeleteGameMutation({
 *   variables: {
 *      gameId: // value for 'gameId'
 *   },
 * });
 */
export function useDeleteGameMutation(baseOptions?: Apollo.MutationHookOptions<DeleteGameMutation, DeleteGameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteGameMutation, DeleteGameMutationVariables>(DeleteGameDocument, options);
      }
export type DeleteGameMutationHookResult = ReturnType<typeof useDeleteGameMutation>;
export type DeleteGameMutationResult = Apollo.MutationResult<DeleteGameMutation>;
export type DeleteGameMutationOptions = Apollo.BaseMutationOptions<DeleteGameMutation, DeleteGameMutationVariables>;
export const InitialiseSubscriptionDocument = gql`
    mutation InitialiseSubscription($pricingId: String!) {
  initialiseSubscription(pricingID: $pricingId) {
    subscriptionID
    clientSecret
  }
}
    `;
export type InitialiseSubscriptionMutationFn = Apollo.MutationFunction<InitialiseSubscriptionMutation, InitialiseSubscriptionMutationVariables>;

/**
 * __useInitialiseSubscriptionMutation__
 *
 * To run a mutation, you first call `useInitialiseSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitialiseSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initialiseSubscriptionMutation, { data, loading, error }] = useInitialiseSubscriptionMutation({
 *   variables: {
 *      pricingId: // value for 'pricingId'
 *   },
 * });
 */
export function useInitialiseSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<InitialiseSubscriptionMutation, InitialiseSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InitialiseSubscriptionMutation, InitialiseSubscriptionMutationVariables>(InitialiseSubscriptionDocument, options);
      }
export type InitialiseSubscriptionMutationHookResult = ReturnType<typeof useInitialiseSubscriptionMutation>;
export type InitialiseSubscriptionMutationResult = Apollo.MutationResult<InitialiseSubscriptionMutation>;
export type InitialiseSubscriptionMutationOptions = Apollo.BaseMutationOptions<InitialiseSubscriptionMutation, InitialiseSubscriptionMutationVariables>;
export const GetTeamSubscriptionDocument = gql`
    query GetTeamSubscription {
  getTeamSubscription {
    id
    status
    current_period_end
    product {
      id
      name
      description
      status
      pricing {
        id
        currency
        amountCents
        interval
      }
    }
    card {
      brand
      last4
    }
  }
}
    `;

/**
 * __useGetTeamSubscriptionQuery__
 *
 * To run a query within a React component, call `useGetTeamSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamSubscriptionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTeamSubscriptionQuery(baseOptions?: Apollo.QueryHookOptions<GetTeamSubscriptionQuery, GetTeamSubscriptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeamSubscriptionQuery, GetTeamSubscriptionQueryVariables>(GetTeamSubscriptionDocument, options);
      }
export function useGetTeamSubscriptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeamSubscriptionQuery, GetTeamSubscriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeamSubscriptionQuery, GetTeamSubscriptionQueryVariables>(GetTeamSubscriptionDocument, options);
        }
export type GetTeamSubscriptionQueryHookResult = ReturnType<typeof useGetTeamSubscriptionQuery>;
export type GetTeamSubscriptionLazyQueryHookResult = ReturnType<typeof useGetTeamSubscriptionLazyQuery>;
export type GetTeamSubscriptionQueryResult = Apollo.QueryResult<GetTeamSubscriptionQuery, GetTeamSubscriptionQueryVariables>;
export const GetTeamPaymentPlansDocument = gql`
    query GetTeamPaymentPlans {
  getTeamPaymentPlans {
    id
    name
    description
    status
    pricing {
      id
      amountCents
      currency
      interval
    }
  }
}
    `;

/**
 * __useGetTeamPaymentPlansQuery__
 *
 * To run a query within a React component, call `useGetTeamPaymentPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamPaymentPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamPaymentPlansQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTeamPaymentPlansQuery(baseOptions?: Apollo.QueryHookOptions<GetTeamPaymentPlansQuery, GetTeamPaymentPlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeamPaymentPlansQuery, GetTeamPaymentPlansQueryVariables>(GetTeamPaymentPlansDocument, options);
      }
export function useGetTeamPaymentPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeamPaymentPlansQuery, GetTeamPaymentPlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeamPaymentPlansQuery, GetTeamPaymentPlansQueryVariables>(GetTeamPaymentPlansDocument, options);
        }
export type GetTeamPaymentPlansQueryHookResult = ReturnType<typeof useGetTeamPaymentPlansQuery>;
export type GetTeamPaymentPlansLazyQueryHookResult = ReturnType<typeof useGetTeamPaymentPlansLazyQuery>;
export type GetTeamPaymentPlansQueryResult = Apollo.QueryResult<GetTeamPaymentPlansQuery, GetTeamPaymentPlansQueryVariables>;
export const CancelTeamSubscriptionDocument = gql`
    mutation CancelTeamSubscription {
  cancelSubscription
}
    `;
export type CancelTeamSubscriptionMutationFn = Apollo.MutationFunction<CancelTeamSubscriptionMutation, CancelTeamSubscriptionMutationVariables>;

/**
 * __useCancelTeamSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelTeamSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelTeamSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelTeamSubscriptionMutation, { data, loading, error }] = useCancelTeamSubscriptionMutation({
 *   variables: {
 *   },
 * });
 */
export function useCancelTeamSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CancelTeamSubscriptionMutation, CancelTeamSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelTeamSubscriptionMutation, CancelTeamSubscriptionMutationVariables>(CancelTeamSubscriptionDocument, options);
      }
export type CancelTeamSubscriptionMutationHookResult = ReturnType<typeof useCancelTeamSubscriptionMutation>;
export type CancelTeamSubscriptionMutationResult = Apollo.MutationResult<CancelTeamSubscriptionMutation>;
export type CancelTeamSubscriptionMutationOptions = Apollo.BaseMutationOptions<CancelTeamSubscriptionMutation, CancelTeamSubscriptionMutationVariables>;
export const SpendMoneyDocument = gql`
    mutation SpendMoney($inputs: SpendMoneyInputs!) {
  spendMoney(inputs: $inputs) {
    id
  }
}
    `;
export type SpendMoneyMutationFn = Apollo.MutationFunction<SpendMoneyMutation, SpendMoneyMutationVariables>;

/**
 * __useSpendMoneyMutation__
 *
 * To run a mutation, you first call `useSpendMoneyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendMoneyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendMoneyMutation, { data, loading, error }] = useSpendMoneyMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useSpendMoneyMutation(baseOptions?: Apollo.MutationHookOptions<SpendMoneyMutation, SpendMoneyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SpendMoneyMutation, SpendMoneyMutationVariables>(SpendMoneyDocument, options);
      }
export type SpendMoneyMutationHookResult = ReturnType<typeof useSpendMoneyMutation>;
export type SpendMoneyMutationResult = Apollo.MutationResult<SpendMoneyMutation>;
export type SpendMoneyMutationOptions = Apollo.BaseMutationOptions<SpendMoneyMutation, SpendMoneyMutationVariables>;
export const GetBalanceTopupPlansDocument = gql`
    query GetBalanceTopupPlans {
  getBalanceTopupPlans {
    id
    name
    description
    status
    pricing {
      id
      currency
      amountCents
    }
  }
}
    `;

/**
 * __useGetBalanceTopupPlansQuery__
 *
 * To run a query within a React component, call `useGetBalanceTopupPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBalanceTopupPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBalanceTopupPlansQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBalanceTopupPlansQuery(baseOptions?: Apollo.QueryHookOptions<GetBalanceTopupPlansQuery, GetBalanceTopupPlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBalanceTopupPlansQuery, GetBalanceTopupPlansQueryVariables>(GetBalanceTopupPlansDocument, options);
      }
export function useGetBalanceTopupPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBalanceTopupPlansQuery, GetBalanceTopupPlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBalanceTopupPlansQuery, GetBalanceTopupPlansQueryVariables>(GetBalanceTopupPlansDocument, options);
        }
export type GetBalanceTopupPlansQueryHookResult = ReturnType<typeof useGetBalanceTopupPlansQuery>;
export type GetBalanceTopupPlansLazyQueryHookResult = ReturnType<typeof useGetBalanceTopupPlansLazyQuery>;
export type GetBalanceTopupPlansQueryResult = Apollo.QueryResult<GetBalanceTopupPlansQuery, GetBalanceTopupPlansQueryVariables>;
export const InitTopupPaymentDocument = gql`
    mutation InitTopupPayment($pricingId: String!) {
  initialiseTopupPayment(pricingID: $pricingId) {
    clientSecret
  }
}
    `;
export type InitTopupPaymentMutationFn = Apollo.MutationFunction<InitTopupPaymentMutation, InitTopupPaymentMutationVariables>;

/**
 * __useInitTopupPaymentMutation__
 *
 * To run a mutation, you first call `useInitTopupPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitTopupPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initTopupPaymentMutation, { data, loading, error }] = useInitTopupPaymentMutation({
 *   variables: {
 *      pricingId: // value for 'pricingId'
 *   },
 * });
 */
export function useInitTopupPaymentMutation(baseOptions?: Apollo.MutationHookOptions<InitTopupPaymentMutation, InitTopupPaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InitTopupPaymentMutation, InitTopupPaymentMutationVariables>(InitTopupPaymentDocument, options);
      }
export type InitTopupPaymentMutationHookResult = ReturnType<typeof useInitTopupPaymentMutation>;
export type InitTopupPaymentMutationResult = Apollo.MutationResult<InitTopupPaymentMutation>;
export type InitTopupPaymentMutationOptions = Apollo.BaseMutationOptions<InitTopupPaymentMutation, InitTopupPaymentMutationVariables>;
export const RemovePlayerFromTeamDocument = gql`
    mutation RemovePlayerFromTeam($teamProfileID: String!) {
  removePlayerFromTeam(teamProfileID: $teamProfileID) {
    id
  }
}
    `;
export type RemovePlayerFromTeamMutationFn = Apollo.MutationFunction<RemovePlayerFromTeamMutation, RemovePlayerFromTeamMutationVariables>;

/**
 * __useRemovePlayerFromTeamMutation__
 *
 * To run a mutation, you first call `useRemovePlayerFromTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePlayerFromTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePlayerFromTeamMutation, { data, loading, error }] = useRemovePlayerFromTeamMutation({
 *   variables: {
 *      teamProfileID: // value for 'teamProfileID'
 *   },
 * });
 */
export function useRemovePlayerFromTeamMutation(baseOptions?: Apollo.MutationHookOptions<RemovePlayerFromTeamMutation, RemovePlayerFromTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemovePlayerFromTeamMutation, RemovePlayerFromTeamMutationVariables>(RemovePlayerFromTeamDocument, options);
      }
export type RemovePlayerFromTeamMutationHookResult = ReturnType<typeof useRemovePlayerFromTeamMutation>;
export type RemovePlayerFromTeamMutationResult = Apollo.MutationResult<RemovePlayerFromTeamMutation>;
export type RemovePlayerFromTeamMutationOptions = Apollo.BaseMutationOptions<RemovePlayerFromTeamMutation, RemovePlayerFromTeamMutationVariables>;
export const GetMyPendingJoinRequestsDocument = gql`
    query GetMyPendingJoinRequests {
  getMyPendingJoinRequests {
    id
    team {
      name
    }
    status
    createdAt
  }
}
    `;

/**
 * __useGetMyPendingJoinRequestsQuery__
 *
 * To run a query within a React component, call `useGetMyPendingJoinRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyPendingJoinRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyPendingJoinRequestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyPendingJoinRequestsQuery(baseOptions?: Apollo.QueryHookOptions<GetMyPendingJoinRequestsQuery, GetMyPendingJoinRequestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyPendingJoinRequestsQuery, GetMyPendingJoinRequestsQueryVariables>(GetMyPendingJoinRequestsDocument, options);
      }
export function useGetMyPendingJoinRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyPendingJoinRequestsQuery, GetMyPendingJoinRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyPendingJoinRequestsQuery, GetMyPendingJoinRequestsQueryVariables>(GetMyPendingJoinRequestsDocument, options);
        }
export type GetMyPendingJoinRequestsQueryHookResult = ReturnType<typeof useGetMyPendingJoinRequestsQuery>;
export type GetMyPendingJoinRequestsLazyQueryHookResult = ReturnType<typeof useGetMyPendingJoinRequestsLazyQuery>;
export type GetMyPendingJoinRequestsQueryResult = Apollo.QueryResult<GetMyPendingJoinRequestsQuery, GetMyPendingJoinRequestsQueryVariables>;
export const CashOutRequestDocument = gql`
    mutation CashOutRequest($inputs: CashOutRequestInputs!) {
  cashOutRequest(inputs: $inputs)
}
    `;
export type CashOutRequestMutationFn = Apollo.MutationFunction<CashOutRequestMutation, CashOutRequestMutationVariables>;

/**
 * __useCashOutRequestMutation__
 *
 * To run a mutation, you first call `useCashOutRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCashOutRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cashOutRequestMutation, { data, loading, error }] = useCashOutRequestMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useCashOutRequestMutation(baseOptions?: Apollo.MutationHookOptions<CashOutRequestMutation, CashOutRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CashOutRequestMutation, CashOutRequestMutationVariables>(CashOutRequestDocument, options);
      }
export type CashOutRequestMutationHookResult = ReturnType<typeof useCashOutRequestMutation>;
export type CashOutRequestMutationResult = Apollo.MutationResult<CashOutRequestMutation>;
export type CashOutRequestMutationOptions = Apollo.BaseMutationOptions<CashOutRequestMutation, CashOutRequestMutationVariables>;
export const ToggleActiveTeamDocument = gql`
    mutation ToggleActiveTeam($newTeamID: String!) {
  toggleActiveTeam(newTeamID: $newTeamID)
}
    `;
export type ToggleActiveTeamMutationFn = Apollo.MutationFunction<ToggleActiveTeamMutation, ToggleActiveTeamMutationVariables>;

/**
 * __useToggleActiveTeamMutation__
 *
 * To run a mutation, you first call `useToggleActiveTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleActiveTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleActiveTeamMutation, { data, loading, error }] = useToggleActiveTeamMutation({
 *   variables: {
 *      newTeamID: // value for 'newTeamID'
 *   },
 * });
 */
export function useToggleActiveTeamMutation(baseOptions?: Apollo.MutationHookOptions<ToggleActiveTeamMutation, ToggleActiveTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleActiveTeamMutation, ToggleActiveTeamMutationVariables>(ToggleActiveTeamDocument, options);
      }
export type ToggleActiveTeamMutationHookResult = ReturnType<typeof useToggleActiveTeamMutation>;
export type ToggleActiveTeamMutationResult = Apollo.MutationResult<ToggleActiveTeamMutation>;
export type ToggleActiveTeamMutationOptions = Apollo.BaseMutationOptions<ToggleActiveTeamMutation, ToggleActiveTeamMutationVariables>;
export const GetActiveTeamDocument = gql`
    query GetActiveTeam {
  getPlayersActiveTeamProfile {
    team {
      id
      name
    }
  }
}
    `;

/**
 * __useGetActiveTeamQuery__
 *
 * To run a query within a React component, call `useGetActiveTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveTeamQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetActiveTeamQuery(baseOptions?: Apollo.QueryHookOptions<GetActiveTeamQuery, GetActiveTeamQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActiveTeamQuery, GetActiveTeamQueryVariables>(GetActiveTeamDocument, options);
      }
export function useGetActiveTeamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActiveTeamQuery, GetActiveTeamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActiveTeamQuery, GetActiveTeamQueryVariables>(GetActiveTeamDocument, options);
        }
export type GetActiveTeamQueryHookResult = ReturnType<typeof useGetActiveTeamQuery>;
export type GetActiveTeamLazyQueryHookResult = ReturnType<typeof useGetActiveTeamLazyQuery>;
export type GetActiveTeamQueryResult = Apollo.QueryResult<GetActiveTeamQuery, GetActiveTeamQueryVariables>;
export const GetPlayersTeamsDocument = gql`
    query GetPlayersTeams {
  getPlayersTeams {
    id
    isTeamAdmin
    imageURL
    position
    status
    team {
      id
      name
      active
      description
      whatsappJoinLink
      joinCode
      owner {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetPlayersTeamsQuery__
 *
 * To run a query within a React component, call `useGetPlayersTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlayersTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlayersTeamsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPlayersTeamsQuery(baseOptions?: Apollo.QueryHookOptions<GetPlayersTeamsQuery, GetPlayersTeamsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPlayersTeamsQuery, GetPlayersTeamsQueryVariables>(GetPlayersTeamsDocument, options);
      }
export function useGetPlayersTeamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlayersTeamsQuery, GetPlayersTeamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPlayersTeamsQuery, GetPlayersTeamsQueryVariables>(GetPlayersTeamsDocument, options);
        }
export type GetPlayersTeamsQueryHookResult = ReturnType<typeof useGetPlayersTeamsQuery>;
export type GetPlayersTeamsLazyQueryHookResult = ReturnType<typeof useGetPlayersTeamsLazyQuery>;
export type GetPlayersTeamsQueryResult = Apollo.QueryResult<GetPlayersTeamsQuery, GetPlayersTeamsQueryVariables>;
export const CreateTeamDocument = gql`
    mutation CreateTeam($input: CreateTeamInput!) {
  createTeam(input: $input) {
    id
  }
}
    `;
export type CreateTeamMutationFn = Apollo.MutationFunction<CreateTeamMutation, CreateTeamMutationVariables>;

/**
 * __useCreateTeamMutation__
 *
 * To run a mutation, you first call `useCreateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeamMutation, { data, loading, error }] = useCreateTeamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTeamMutation(baseOptions?: Apollo.MutationHookOptions<CreateTeamMutation, CreateTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTeamMutation, CreateTeamMutationVariables>(CreateTeamDocument, options);
      }
export type CreateTeamMutationHookResult = ReturnType<typeof useCreateTeamMutation>;
export type CreateTeamMutationResult = Apollo.MutationResult<CreateTeamMutation>;
export type CreateTeamMutationOptions = Apollo.BaseMutationOptions<CreateTeamMutation, CreateTeamMutationVariables>;
export const UpdateTeamDocument = gql`
    mutation UpdateTeam($input: UpdateTeamInput!) {
  updateTeam(input: $input) {
    id
  }
}
    `;
export type UpdateTeamMutationFn = Apollo.MutationFunction<UpdateTeamMutation, UpdateTeamMutationVariables>;

/**
 * __useUpdateTeamMutation__
 *
 * To run a mutation, you first call `useUpdateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamMutation, { data, loading, error }] = useUpdateTeamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTeamMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTeamMutation, UpdateTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTeamMutation, UpdateTeamMutationVariables>(UpdateTeamDocument, options);
      }
export type UpdateTeamMutationHookResult = ReturnType<typeof useUpdateTeamMutation>;
export type UpdateTeamMutationResult = Apollo.MutationResult<UpdateTeamMutation>;
export type UpdateTeamMutationOptions = Apollo.BaseMutationOptions<UpdateTeamMutation, UpdateTeamMutationVariables>;
export const GetTeamDocument = gql`
    query GetTeam($teamId: String!) {
  getTeam(teamID: $teamId) {
    id
    active
    name
    description
    whatsappJoinLink
    joinCode
    venues {
      ...VenueFields
    }
    image
  }
}
    ${VenueFieldsFragmentDoc}`;

/**
 * __useGetTeamQuery__
 *
 * To run a query within a React component, call `useGetTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useGetTeamQuery(baseOptions: Apollo.QueryHookOptions<GetTeamQuery, GetTeamQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeamQuery, GetTeamQueryVariables>(GetTeamDocument, options);
      }
export function useGetTeamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeamQuery, GetTeamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeamQuery, GetTeamQueryVariables>(GetTeamDocument, options);
        }
export type GetTeamQueryHookResult = ReturnType<typeof useGetTeamQuery>;
export type GetTeamLazyQueryHookResult = ReturnType<typeof useGetTeamLazyQuery>;
export type GetTeamQueryResult = Apollo.QueryResult<GetTeamQuery, GetTeamQueryVariables>;
export const GetTeamRulesDocument = gql`
    query GetTeamRules {
  getTeamRules {
    id
    ruleTitle
    ruleDetails
    createdAt
    createdByPlayer {
      id
      name
    }
    updatedAt
    updatedByPlayer {
      id
      name
    }
  }
}
    `;

/**
 * __useGetTeamRulesQuery__
 *
 * To run a query within a React component, call `useGetTeamRulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamRulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamRulesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTeamRulesQuery(baseOptions?: Apollo.QueryHookOptions<GetTeamRulesQuery, GetTeamRulesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeamRulesQuery, GetTeamRulesQueryVariables>(GetTeamRulesDocument, options);
      }
export function useGetTeamRulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeamRulesQuery, GetTeamRulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeamRulesQuery, GetTeamRulesQueryVariables>(GetTeamRulesDocument, options);
        }
export type GetTeamRulesQueryHookResult = ReturnType<typeof useGetTeamRulesQuery>;
export type GetTeamRulesLazyQueryHookResult = ReturnType<typeof useGetTeamRulesLazyQuery>;
export type GetTeamRulesQueryResult = Apollo.QueryResult<GetTeamRulesQuery, GetTeamRulesQueryVariables>;
export const CreateTeamRuleDocument = gql`
    mutation CreateTeamRule($inputs: CreateTeamRuleInputs!) {
  createTeamRule(inputs: $inputs) {
    id
  }
}
    `;
export type CreateTeamRuleMutationFn = Apollo.MutationFunction<CreateTeamRuleMutation, CreateTeamRuleMutationVariables>;

/**
 * __useCreateTeamRuleMutation__
 *
 * To run a mutation, you first call `useCreateTeamRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeamRuleMutation, { data, loading, error }] = useCreateTeamRuleMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useCreateTeamRuleMutation(baseOptions?: Apollo.MutationHookOptions<CreateTeamRuleMutation, CreateTeamRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTeamRuleMutation, CreateTeamRuleMutationVariables>(CreateTeamRuleDocument, options);
      }
export type CreateTeamRuleMutationHookResult = ReturnType<typeof useCreateTeamRuleMutation>;
export type CreateTeamRuleMutationResult = Apollo.MutationResult<CreateTeamRuleMutation>;
export type CreateTeamRuleMutationOptions = Apollo.BaseMutationOptions<CreateTeamRuleMutation, CreateTeamRuleMutationVariables>;
export const UpdateTeamRuleDocument = gql`
    mutation UpdateTeamRule($inputs: UpdateTeamRuleInputs!) {
  updateTeamRule(inputs: $inputs) {
    id
  }
}
    `;
export type UpdateTeamRuleMutationFn = Apollo.MutationFunction<UpdateTeamRuleMutation, UpdateTeamRuleMutationVariables>;

/**
 * __useUpdateTeamRuleMutation__
 *
 * To run a mutation, you first call `useUpdateTeamRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamRuleMutation, { data, loading, error }] = useUpdateTeamRuleMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useUpdateTeamRuleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTeamRuleMutation, UpdateTeamRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTeamRuleMutation, UpdateTeamRuleMutationVariables>(UpdateTeamRuleDocument, options);
      }
export type UpdateTeamRuleMutationHookResult = ReturnType<typeof useUpdateTeamRuleMutation>;
export type UpdateTeamRuleMutationResult = Apollo.MutationResult<UpdateTeamRuleMutation>;
export type UpdateTeamRuleMutationOptions = Apollo.BaseMutationOptions<UpdateTeamRuleMutation, UpdateTeamRuleMutationVariables>;
export const DeleteTeamRuleDocument = gql`
    mutation DeleteTeamRule($ruleId: String!) {
  deleteTeamRule(ruleID: $ruleId) {
    id
  }
}
    `;
export type DeleteTeamRuleMutationFn = Apollo.MutationFunction<DeleteTeamRuleMutation, DeleteTeamRuleMutationVariables>;

/**
 * __useDeleteTeamRuleMutation__
 *
 * To run a mutation, you first call `useDeleteTeamRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTeamRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTeamRuleMutation, { data, loading, error }] = useDeleteTeamRuleMutation({
 *   variables: {
 *      ruleId: // value for 'ruleId'
 *   },
 * });
 */
export function useDeleteTeamRuleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTeamRuleMutation, DeleteTeamRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTeamRuleMutation, DeleteTeamRuleMutationVariables>(DeleteTeamRuleDocument, options);
      }
export type DeleteTeamRuleMutationHookResult = ReturnType<typeof useDeleteTeamRuleMutation>;
export type DeleteTeamRuleMutationResult = Apollo.MutationResult<DeleteTeamRuleMutation>;
export type DeleteTeamRuleMutationOptions = Apollo.BaseMutationOptions<DeleteTeamRuleMutation, DeleteTeamRuleMutationVariables>;
export const InvitePlayerToJoinTeamDocument = gql`
    mutation InvitePlayerToJoinTeam($input: InvitePlayerInputs!) {
  invitePlayerToJoinTeam(input: $input)
}
    `;
export type InvitePlayerToJoinTeamMutationFn = Apollo.MutationFunction<InvitePlayerToJoinTeamMutation, InvitePlayerToJoinTeamMutationVariables>;

/**
 * __useInvitePlayerToJoinTeamMutation__
 *
 * To run a mutation, you first call `useInvitePlayerToJoinTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvitePlayerToJoinTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invitePlayerToJoinTeamMutation, { data, loading, error }] = useInvitePlayerToJoinTeamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInvitePlayerToJoinTeamMutation(baseOptions?: Apollo.MutationHookOptions<InvitePlayerToJoinTeamMutation, InvitePlayerToJoinTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InvitePlayerToJoinTeamMutation, InvitePlayerToJoinTeamMutationVariables>(InvitePlayerToJoinTeamDocument, options);
      }
export type InvitePlayerToJoinTeamMutationHookResult = ReturnType<typeof useInvitePlayerToJoinTeamMutation>;
export type InvitePlayerToJoinTeamMutationResult = Apollo.MutationResult<InvitePlayerToJoinTeamMutation>;
export type InvitePlayerToJoinTeamMutationOptions = Apollo.BaseMutationOptions<InvitePlayerToJoinTeamMutation, InvitePlayerToJoinTeamMutationVariables>;
export const GetMyBalanceDocument = gql`
    query GetMyBalance {
  getPlayerBalance
}
    `;

/**
 * __useGetMyBalanceQuery__
 *
 * To run a query within a React component, call `useGetMyBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyBalanceQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyBalanceQuery(baseOptions?: Apollo.QueryHookOptions<GetMyBalanceQuery, GetMyBalanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyBalanceQuery, GetMyBalanceQueryVariables>(GetMyBalanceDocument, options);
      }
export function useGetMyBalanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyBalanceQuery, GetMyBalanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyBalanceQuery, GetMyBalanceQueryVariables>(GetMyBalanceDocument, options);
        }
export type GetMyBalanceQueryHookResult = ReturnType<typeof useGetMyBalanceQuery>;
export type GetMyBalanceLazyQueryHookResult = ReturnType<typeof useGetMyBalanceLazyQuery>;
export type GetMyBalanceQueryResult = Apollo.QueryResult<GetMyBalanceQuery, GetMyBalanceQueryVariables>;
export const LookupPlacePredictionsDocument = gql`
    query LookupPlacePredictions($address: String!) {
  lookupPlacePredictions(address: $address) {
    description
    distance_meters
    place_id
    terms {
      offset
      value
    }
    types
    matched_substrings {
      length
      offset
    }
    structured_formatting {
      main_text
      main_text_matched_substrings {
        offset
        length
      }
      secondary_text
      secondary_text_matched_substrings {
        length
        offset
      }
    }
  }
}
    `;

/**
 * __useLookupPlacePredictionsQuery__
 *
 * To run a query within a React component, call `useLookupPlacePredictionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLookupPlacePredictionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLookupPlacePredictionsQuery({
 *   variables: {
 *      address: // value for 'address'
 *   },
 * });
 */
export function useLookupPlacePredictionsQuery(baseOptions: Apollo.QueryHookOptions<LookupPlacePredictionsQuery, LookupPlacePredictionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LookupPlacePredictionsQuery, LookupPlacePredictionsQueryVariables>(LookupPlacePredictionsDocument, options);
      }
export function useLookupPlacePredictionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LookupPlacePredictionsQuery, LookupPlacePredictionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LookupPlacePredictionsQuery, LookupPlacePredictionsQueryVariables>(LookupPlacePredictionsDocument, options);
        }
export type LookupPlacePredictionsQueryHookResult = ReturnType<typeof useLookupPlacePredictionsQuery>;
export type LookupPlacePredictionsLazyQueryHookResult = ReturnType<typeof useLookupPlacePredictionsLazyQuery>;
export type LookupPlacePredictionsQueryResult = Apollo.QueryResult<LookupPlacePredictionsQuery, LookupPlacePredictionsQueryVariables>;
export const LookupPlaceDetailsDocument = gql`
    query LookupPlaceDetails($placeid: String!) {
  lookupPlace(placeid: $placeid) {
    name
    formatted_address
    formatted_phone_number
    website
    rating
    types
    postcode
    lat
    lng
  }
}
    `;

/**
 * __useLookupPlaceDetailsQuery__
 *
 * To run a query within a React component, call `useLookupPlaceDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLookupPlaceDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLookupPlaceDetailsQuery({
 *   variables: {
 *      placeid: // value for 'placeid'
 *   },
 * });
 */
export function useLookupPlaceDetailsQuery(baseOptions: Apollo.QueryHookOptions<LookupPlaceDetailsQuery, LookupPlaceDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LookupPlaceDetailsQuery, LookupPlaceDetailsQueryVariables>(LookupPlaceDetailsDocument, options);
      }
export function useLookupPlaceDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LookupPlaceDetailsQuery, LookupPlaceDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LookupPlaceDetailsQuery, LookupPlaceDetailsQueryVariables>(LookupPlaceDetailsDocument, options);
        }
export type LookupPlaceDetailsQueryHookResult = ReturnType<typeof useLookupPlaceDetailsQuery>;
export type LookupPlaceDetailsLazyQueryHookResult = ReturnType<typeof useLookupPlaceDetailsLazyQuery>;
export type LookupPlaceDetailsQueryResult = Apollo.QueryResult<LookupPlaceDetailsQuery, LookupPlaceDetailsQueryVariables>;
export const FileUploadURLDocument = gql`
    query FileUploadURL($inputs: FileUploadInputs!) {
  createFileUploadUrl(inputs: $inputs)
}
    `;

/**
 * __useFileUploadURLQuery__
 *
 * To run a query within a React component, call `useFileUploadURLQuery` and pass it any options that fit your needs.
 * When your component renders, `useFileUploadURLQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFileUploadURLQuery({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useFileUploadURLQuery(baseOptions: Apollo.QueryHookOptions<FileUploadURLQuery, FileUploadURLQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FileUploadURLQuery, FileUploadURLQueryVariables>(FileUploadURLDocument, options);
      }
export function useFileUploadURLLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FileUploadURLQuery, FileUploadURLQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FileUploadURLQuery, FileUploadURLQueryVariables>(FileUploadURLDocument, options);
        }
export type FileUploadURLQueryHookResult = ReturnType<typeof useFileUploadURLQuery>;
export type FileUploadURLLazyQueryHookResult = ReturnType<typeof useFileUploadURLLazyQuery>;
export type FileUploadURLQueryResult = Apollo.QueryResult<FileUploadURLQuery, FileUploadURLQueryVariables>;
export const GetMyProfileDocument = gql`
    query GetMyProfile {
  getMyProfile {
    ...MyProfileFields
  }
}
    ${MyProfileFieldsFragmentDoc}`;

/**
 * __useGetMyProfileQuery__
 *
 * To run a query within a React component, call `useGetMyProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyProfileQuery(baseOptions?: Apollo.QueryHookOptions<GetMyProfileQuery, GetMyProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyProfileQuery, GetMyProfileQueryVariables>(GetMyProfileDocument, options);
      }
export function useGetMyProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyProfileQuery, GetMyProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyProfileQuery, GetMyProfileQueryVariables>(GetMyProfileDocument, options);
        }
export type GetMyProfileQueryHookResult = ReturnType<typeof useGetMyProfileQuery>;
export type GetMyProfileLazyQueryHookResult = ReturnType<typeof useGetMyProfileLazyQuery>;
export type GetMyProfileQueryResult = Apollo.QueryResult<GetMyProfileQuery, GetMyProfileQueryVariables>;
export const GetProfileDocument = gql`
    query GetProfile($playerID: String!) {
  getPlayersProfile(playerID: $playerID) {
    player {
      id
      name
    }
    teamProfile {
      position
      imageURL
      isTeamAdmin
      hasJoinedTeamChat
      team {
        id
        name
        image
        rulesEditableByPlayers
        owner {
          id
          name
        }
      }
    }
    teams {
      id
      name
      whatsappJoinLink
    }
    totalGames
    totalGamesWon
    totalMvpVotes
    totalGoals
    averageGoalsPerMatch
    recentReviews {
      id
      highlight
      win
      goals
      createdAt
    }
    transactions {
      id
      amount
      name
      type
      createdAt
    }
    totalMvpWins
  }
}
    `;

/**
 * __useGetProfileQuery__
 *
 * To run a query within a React component, call `useGetProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileQuery({
 *   variables: {
 *      playerID: // value for 'playerID'
 *   },
 * });
 */
export function useGetProfileQuery(baseOptions: Apollo.QueryHookOptions<GetProfileQuery, GetProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProfileQuery, GetProfileQueryVariables>(GetProfileDocument, options);
      }
export function useGetProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProfileQuery, GetProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProfileQuery, GetProfileQueryVariables>(GetProfileDocument, options);
        }
export type GetProfileQueryHookResult = ReturnType<typeof useGetProfileQuery>;
export type GetProfileLazyQueryHookResult = ReturnType<typeof useGetProfileLazyQuery>;
export type GetProfileQueryResult = Apollo.QueryResult<GetProfileQuery, GetProfileQueryVariables>;
export const GetNotificationsDocument = gql`
    query GetNotifications($inputs: GetNotificationInputs!) {
  getNotifications(inputs: $inputs) {
    id
    priority
    isRead
    message
    redirect
    createdAt
  }
}
    `;

/**
 * __useGetNotificationsQuery__
 *
 * To run a query within a React component, call `useGetNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsQuery({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useGetNotificationsQuery(baseOptions: Apollo.QueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, options);
      }
export function useGetNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, options);
        }
export type GetNotificationsQueryHookResult = ReturnType<typeof useGetNotificationsQuery>;
export type GetNotificationsLazyQueryHookResult = ReturnType<typeof useGetNotificationsLazyQuery>;
export type GetNotificationsQueryResult = Apollo.QueryResult<GetNotificationsQuery, GetNotificationsQueryVariables>;
export const MarkNotificationsReadDocument = gql`
    mutation MarkNotificationsRead {
  markAllRead {
    id
  }
}
    `;
export type MarkNotificationsReadMutationFn = Apollo.MutationFunction<MarkNotificationsReadMutation, MarkNotificationsReadMutationVariables>;

/**
 * __useMarkNotificationsReadMutation__
 *
 * To run a mutation, you first call `useMarkNotificationsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkNotificationsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markNotificationsReadMutation, { data, loading, error }] = useMarkNotificationsReadMutation({
 *   variables: {
 *   },
 * });
 */
export function useMarkNotificationsReadMutation(baseOptions?: Apollo.MutationHookOptions<MarkNotificationsReadMutation, MarkNotificationsReadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkNotificationsReadMutation, MarkNotificationsReadMutationVariables>(MarkNotificationsReadDocument, options);
      }
export type MarkNotificationsReadMutationHookResult = ReturnType<typeof useMarkNotificationsReadMutation>;
export type MarkNotificationsReadMutationResult = Apollo.MutationResult<MarkNotificationsReadMutation>;
export type MarkNotificationsReadMutationOptions = Apollo.BaseMutationOptions<MarkNotificationsReadMutation, MarkNotificationsReadMutationVariables>;
export const GlobalNotificationDocument = gql`
    subscription GlobalNotification($teamID: ID!) {
  globalNotification(teamID: $teamID) {
    ... on VenueImageChangeEvent {
      teamID
      eventType
      venueID
      imageURL
    }
    ... on PlayerProfileImageChangeEvent {
      teamID
      eventType
      teamProfileID
      imageURL
    }
  }
}
    `;

/**
 * __useGlobalNotificationSubscription__
 *
 * To run a query within a React component, call `useGlobalNotificationSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGlobalNotificationSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlobalNotificationSubscription({
 *   variables: {
 *      teamID: // value for 'teamID'
 *   },
 * });
 */
export function useGlobalNotificationSubscription(baseOptions: Apollo.SubscriptionHookOptions<GlobalNotificationSubscription, GlobalNotificationSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GlobalNotificationSubscription, GlobalNotificationSubscriptionVariables>(GlobalNotificationDocument, options);
      }
export type GlobalNotificationSubscriptionHookResult = ReturnType<typeof useGlobalNotificationSubscription>;
export type GlobalNotificationSubscriptionResult = Apollo.SubscriptionResult<GlobalNotificationSubscription>;
export const AddPlayerToGameDocument = gql`
    mutation AddPlayerToGame($gameId: String!, $playerId: String!) {
  addPlayerToGame(gameID: $gameId, playerID: $playerId) {
    ...UpcomingGameFields
  }
}
    ${UpcomingGameFieldsFragmentDoc}`;
export type AddPlayerToGameMutationFn = Apollo.MutationFunction<AddPlayerToGameMutation, AddPlayerToGameMutationVariables>;

/**
 * __useAddPlayerToGameMutation__
 *
 * To run a mutation, you first call `useAddPlayerToGameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPlayerToGameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPlayerToGameMutation, { data, loading, error }] = useAddPlayerToGameMutation({
 *   variables: {
 *      gameId: // value for 'gameId'
 *      playerId: // value for 'playerId'
 *   },
 * });
 */
export function useAddPlayerToGameMutation(baseOptions?: Apollo.MutationHookOptions<AddPlayerToGameMutation, AddPlayerToGameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPlayerToGameMutation, AddPlayerToGameMutationVariables>(AddPlayerToGameDocument, options);
      }
export type AddPlayerToGameMutationHookResult = ReturnType<typeof useAddPlayerToGameMutation>;
export type AddPlayerToGameMutationResult = Apollo.MutationResult<AddPlayerToGameMutation>;
export type AddPlayerToGameMutationOptions = Apollo.BaseMutationOptions<AddPlayerToGameMutation, AddPlayerToGameMutationVariables>;
export const RemovePlayerFromGameDocument = gql`
    mutation RemovePlayerFromGame($playerId: String!, $gameId: String!) {
  removePlayerFromGame(playerID: $playerId, gameID: $gameId) {
    ...UpcomingGameFields
  }
}
    ${UpcomingGameFieldsFragmentDoc}`;
export type RemovePlayerFromGameMutationFn = Apollo.MutationFunction<RemovePlayerFromGameMutation, RemovePlayerFromGameMutationVariables>;

/**
 * __useRemovePlayerFromGameMutation__
 *
 * To run a mutation, you first call `useRemovePlayerFromGameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePlayerFromGameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePlayerFromGameMutation, { data, loading, error }] = useRemovePlayerFromGameMutation({
 *   variables: {
 *      playerId: // value for 'playerId'
 *      gameId: // value for 'gameId'
 *   },
 * });
 */
export function useRemovePlayerFromGameMutation(baseOptions?: Apollo.MutationHookOptions<RemovePlayerFromGameMutation, RemovePlayerFromGameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemovePlayerFromGameMutation, RemovePlayerFromGameMutationVariables>(RemovePlayerFromGameDocument, options);
      }
export type RemovePlayerFromGameMutationHookResult = ReturnType<typeof useRemovePlayerFromGameMutation>;
export type RemovePlayerFromGameMutationResult = Apollo.MutationResult<RemovePlayerFromGameMutation>;
export type RemovePlayerFromGameMutationOptions = Apollo.BaseMutationOptions<RemovePlayerFromGameMutation, RemovePlayerFromGameMutationVariables>;
export const JoinWaitingListDocument = gql`
    mutation JoinWaitingList($gameID: String!) {
  joinWaitingList(GameID: $gameID) {
    id
    waitingList {
      ...WaitingListFields
    }
  }
}
    ${WaitingListFieldsFragmentDoc}`;
export type JoinWaitingListMutationFn = Apollo.MutationFunction<JoinWaitingListMutation, JoinWaitingListMutationVariables>;

/**
 * __useJoinWaitingListMutation__
 *
 * To run a mutation, you first call `useJoinWaitingListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinWaitingListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinWaitingListMutation, { data, loading, error }] = useJoinWaitingListMutation({
 *   variables: {
 *      gameID: // value for 'gameID'
 *   },
 * });
 */
export function useJoinWaitingListMutation(baseOptions?: Apollo.MutationHookOptions<JoinWaitingListMutation, JoinWaitingListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<JoinWaitingListMutation, JoinWaitingListMutationVariables>(JoinWaitingListDocument, options);
      }
export type JoinWaitingListMutationHookResult = ReturnType<typeof useJoinWaitingListMutation>;
export type JoinWaitingListMutationResult = Apollo.MutationResult<JoinWaitingListMutation>;
export type JoinWaitingListMutationOptions = Apollo.BaseMutationOptions<JoinWaitingListMutation, JoinWaitingListMutationVariables>;
export const LeaveWaitingListDocument = gql`
    mutation LeaveWaitingList($gameID: String!) {
  leaveWaitingList(GameID: $gameID) {
    id
    waitingList {
      ...WaitingListFields
    }
  }
}
    ${WaitingListFieldsFragmentDoc}`;
export type LeaveWaitingListMutationFn = Apollo.MutationFunction<LeaveWaitingListMutation, LeaveWaitingListMutationVariables>;

/**
 * __useLeaveWaitingListMutation__
 *
 * To run a mutation, you first call `useLeaveWaitingListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveWaitingListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveWaitingListMutation, { data, loading, error }] = useLeaveWaitingListMutation({
 *   variables: {
 *      gameID: // value for 'gameID'
 *   },
 * });
 */
export function useLeaveWaitingListMutation(baseOptions?: Apollo.MutationHookOptions<LeaveWaitingListMutation, LeaveWaitingListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LeaveWaitingListMutation, LeaveWaitingListMutationVariables>(LeaveWaitingListDocument, options);
      }
export type LeaveWaitingListMutationHookResult = ReturnType<typeof useLeaveWaitingListMutation>;
export type LeaveWaitingListMutationResult = Apollo.MutationResult<LeaveWaitingListMutation>;
export type LeaveWaitingListMutationOptions = Apollo.BaseMutationOptions<LeaveWaitingListMutation, LeaveWaitingListMutationVariables>;
export const GetHistoricalGamesDocument = gql`
    query GetHistoricalGames($rowsPerPage: Int!, $orderBy: String!, $orderDirection: SortDirection!, $page: Int!, $date: Date, $status: GameStatus, $searchText: String) {
  getHistoricalGames(
    rowsPerPage: $rowsPerPage
    orderBy: $orderBy
    orderDirection: $orderDirection
    page: $page
    date: $date
    status: $status
    searchText: $searchText
  ) {
    games {
      id
      date
      name
    }
    totalGames
  }
}
    `;

/**
 * __useGetHistoricalGamesQuery__
 *
 * To run a query within a React component, call `useGetHistoricalGamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHistoricalGamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHistoricalGamesQuery({
 *   variables: {
 *      rowsPerPage: // value for 'rowsPerPage'
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *      page: // value for 'page'
 *      date: // value for 'date'
 *      status: // value for 'status'
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useGetHistoricalGamesQuery(baseOptions: Apollo.QueryHookOptions<GetHistoricalGamesQuery, GetHistoricalGamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHistoricalGamesQuery, GetHistoricalGamesQueryVariables>(GetHistoricalGamesDocument, options);
      }
export function useGetHistoricalGamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHistoricalGamesQuery, GetHistoricalGamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHistoricalGamesQuery, GetHistoricalGamesQueryVariables>(GetHistoricalGamesDocument, options);
        }
export type GetHistoricalGamesQueryHookResult = ReturnType<typeof useGetHistoricalGamesQuery>;
export type GetHistoricalGamesLazyQueryHookResult = ReturnType<typeof useGetHistoricalGamesLazyQuery>;
export type GetHistoricalGamesQueryResult = Apollo.QueryResult<GetHistoricalGamesQuery, GetHistoricalGamesQueryVariables>;
export const SearchGamesDocument = gql`
    query SearchGames($rowsPerPage: Int!, $orderBy: String!, $orderDirection: SortDirection!, $page: Int!, $date: Date, $status: GameStatus, $searchText: String) @connection(key: "searchGames") {
  searchGames(
    rowsPerPage: $rowsPerPage
    orderBy: $orderBy
    orderDirection: $orderDirection
    page: $page
    date: $date
    status: $status
    searchText: $searchText
  ) {
    id
    date
    name
  }
  searchGamesCount(
    rowsPerPage: $rowsPerPage
    orderBy: $orderBy
    orderDirection: $orderDirection
    page: $page
    date: $date
    status: $status
    searchText: $searchText
  )
}
    `;

/**
 * __useSearchGamesQuery__
 *
 * To run a query within a React component, call `useSearchGamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchGamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchGamesQuery({
 *   variables: {
 *      rowsPerPage: // value for 'rowsPerPage'
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *      page: // value for 'page'
 *      date: // value for 'date'
 *      status: // value for 'status'
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useSearchGamesQuery(baseOptions: Apollo.QueryHookOptions<SearchGamesQuery, SearchGamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchGamesQuery, SearchGamesQueryVariables>(SearchGamesDocument, options);
      }
export function useSearchGamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchGamesQuery, SearchGamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchGamesQuery, SearchGamesQueryVariables>(SearchGamesDocument, options);
        }
export type SearchGamesQueryHookResult = ReturnType<typeof useSearchGamesQuery>;
export type SearchGamesLazyQueryHookResult = ReturnType<typeof useSearchGamesLazyQuery>;
export type SearchGamesQueryResult = Apollo.QueryResult<SearchGamesQuery, SearchGamesQueryVariables>;
export const ViewGameDocument = gql`
    query ViewGame($gameId: String!) {
  getGame(gameID: $gameId) {
    ...ViewGameFields
  }
}
    ${ViewGameFieldsFragmentDoc}`;

/**
 * __useViewGameQuery__
 *
 * To run a query within a React component, call `useViewGameQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewGameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewGameQuery({
 *   variables: {
 *      gameId: // value for 'gameId'
 *   },
 * });
 */
export function useViewGameQuery(baseOptions: Apollo.QueryHookOptions<ViewGameQuery, ViewGameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ViewGameQuery, ViewGameQueryVariables>(ViewGameDocument, options);
      }
export function useViewGameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ViewGameQuery, ViewGameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ViewGameQuery, ViewGameQueryVariables>(ViewGameDocument, options);
        }
export type ViewGameQueryHookResult = ReturnType<typeof useViewGameQuery>;
export type ViewGameLazyQueryHookResult = ReturnType<typeof useViewGameLazyQuery>;
export type ViewGameQueryResult = Apollo.QueryResult<ViewGameQuery, ViewGameQueryVariables>;
export const AddGuestToGameDocument = gql`
    mutation AddGuestToGame($gameId: String!, $name: String!) {
  addGuestToGame(gameID: $gameId, name: $name) {
    id
    guests {
      name
      invitedByPlayerID
    }
  }
}
    `;
export type AddGuestToGameMutationFn = Apollo.MutationFunction<AddGuestToGameMutation, AddGuestToGameMutationVariables>;

/**
 * __useAddGuestToGameMutation__
 *
 * To run a mutation, you first call `useAddGuestToGameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGuestToGameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGuestToGameMutation, { data, loading, error }] = useAddGuestToGameMutation({
 *   variables: {
 *      gameId: // value for 'gameId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAddGuestToGameMutation(baseOptions?: Apollo.MutationHookOptions<AddGuestToGameMutation, AddGuestToGameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddGuestToGameMutation, AddGuestToGameMutationVariables>(AddGuestToGameDocument, options);
      }
export type AddGuestToGameMutationHookResult = ReturnType<typeof useAddGuestToGameMutation>;
export type AddGuestToGameMutationResult = Apollo.MutationResult<AddGuestToGameMutation>;
export type AddGuestToGameMutationOptions = Apollo.BaseMutationOptions<AddGuestToGameMutation, AddGuestToGameMutationVariables>;
export const RemoveGuestFromGameDocument = gql`
    mutation RemoveGuestFromGame($gameId: String!, $name: String!) {
  removeGuestFromGame(gameID: $gameId, name: $name) {
    id
    guests {
      name
      invitedByPlayerID
    }
  }
}
    `;
export type RemoveGuestFromGameMutationFn = Apollo.MutationFunction<RemoveGuestFromGameMutation, RemoveGuestFromGameMutationVariables>;

/**
 * __useRemoveGuestFromGameMutation__
 *
 * To run a mutation, you first call `useRemoveGuestFromGameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveGuestFromGameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeGuestFromGameMutation, { data, loading, error }] = useRemoveGuestFromGameMutation({
 *   variables: {
 *      gameId: // value for 'gameId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useRemoveGuestFromGameMutation(baseOptions?: Apollo.MutationHookOptions<RemoveGuestFromGameMutation, RemoveGuestFromGameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveGuestFromGameMutation, RemoveGuestFromGameMutationVariables>(RemoveGuestFromGameDocument, options);
      }
export type RemoveGuestFromGameMutationHookResult = ReturnType<typeof useRemoveGuestFromGameMutation>;
export type RemoveGuestFromGameMutationResult = Apollo.MutationResult<RemoveGuestFromGameMutation>;
export type RemoveGuestFromGameMutationOptions = Apollo.BaseMutationOptions<RemoveGuestFromGameMutation, RemoveGuestFromGameMutationVariables>;
export const GetUpcomingGamesDocument = gql`
    query GetUpcomingGames {
  getUpcomingGames {
    ...UpcomingGameFields
  }
}
    ${UpcomingGameFieldsFragmentDoc}`;

/**
 * __useGetUpcomingGamesQuery__
 *
 * To run a query within a React component, call `useGetUpcomingGamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUpcomingGamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUpcomingGamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUpcomingGamesQuery(baseOptions?: Apollo.QueryHookOptions<GetUpcomingGamesQuery, GetUpcomingGamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUpcomingGamesQuery, GetUpcomingGamesQueryVariables>(GetUpcomingGamesDocument, options);
      }
export function useGetUpcomingGamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUpcomingGamesQuery, GetUpcomingGamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUpcomingGamesQuery, GetUpcomingGamesQueryVariables>(GetUpcomingGamesDocument, options);
        }
export type GetUpcomingGamesQueryHookResult = ReturnType<typeof useGetUpcomingGamesQuery>;
export type GetUpcomingGamesLazyQueryHookResult = ReturnType<typeof useGetUpcomingGamesLazyQuery>;
export type GetUpcomingGamesQueryResult = Apollo.QueryResult<GetUpcomingGamesQuery, GetUpcomingGamesQueryVariables>;
export const UpcomingGamesNotificationsDocument = gql`
    subscription UpcomingGamesNotifications($teamId: ID!) {
  upcomingGamesNotification(teamID: $teamId) {
    ... on AttendanceChangeEvent {
      teamID
      attendanceID
      gameID
      playerID
      isSignup
    }
    ... on GameChangeEvent {
      teamID
      type
    }
  }
}
    `;

/**
 * __useUpcomingGamesNotificationsSubscription__
 *
 * To run a query within a React component, call `useUpcomingGamesNotificationsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUpcomingGamesNotificationsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpcomingGamesNotificationsSubscription({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useUpcomingGamesNotificationsSubscription(baseOptions: Apollo.SubscriptionHookOptions<UpcomingGamesNotificationsSubscription, UpcomingGamesNotificationsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<UpcomingGamesNotificationsSubscription, UpcomingGamesNotificationsSubscriptionVariables>(UpcomingGamesNotificationsDocument, options);
      }
export type UpcomingGamesNotificationsSubscriptionHookResult = ReturnType<typeof useUpcomingGamesNotificationsSubscription>;
export type UpcomingGamesNotificationsSubscriptionResult = Apollo.SubscriptionResult<UpcomingGamesNotificationsSubscription>;
export const GetTeamJoinRequestsDocument = gql`
    query GetTeamJoinRequests {
  getActiveTeamJoinRequests {
    id
    status
    player {
      id
      name
      email
    }
    team {
      id
      name
    }
    createdAt
  }
}
    `;

/**
 * __useGetTeamJoinRequestsQuery__
 *
 * To run a query within a React component, call `useGetTeamJoinRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamJoinRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamJoinRequestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTeamJoinRequestsQuery(baseOptions?: Apollo.QueryHookOptions<GetTeamJoinRequestsQuery, GetTeamJoinRequestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeamJoinRequestsQuery, GetTeamJoinRequestsQueryVariables>(GetTeamJoinRequestsDocument, options);
      }
export function useGetTeamJoinRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeamJoinRequestsQuery, GetTeamJoinRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeamJoinRequestsQuery, GetTeamJoinRequestsQueryVariables>(GetTeamJoinRequestsDocument, options);
        }
export type GetTeamJoinRequestsQueryHookResult = ReturnType<typeof useGetTeamJoinRequestsQuery>;
export type GetTeamJoinRequestsLazyQueryHookResult = ReturnType<typeof useGetTeamJoinRequestsLazyQuery>;
export type GetTeamJoinRequestsQueryResult = Apollo.QueryResult<GetTeamJoinRequestsQuery, GetTeamJoinRequestsQueryVariables>;
export const AcceptJoinRequestDocument = gql`
    mutation AcceptJoinRequest($joinRequestId: String!) {
  acceptJoinRequest(joinRequestID: $joinRequestId) {
    id
  }
}
    `;
export type AcceptJoinRequestMutationFn = Apollo.MutationFunction<AcceptJoinRequestMutation, AcceptJoinRequestMutationVariables>;

/**
 * __useAcceptJoinRequestMutation__
 *
 * To run a mutation, you first call `useAcceptJoinRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptJoinRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptJoinRequestMutation, { data, loading, error }] = useAcceptJoinRequestMutation({
 *   variables: {
 *      joinRequestId: // value for 'joinRequestId'
 *   },
 * });
 */
export function useAcceptJoinRequestMutation(baseOptions?: Apollo.MutationHookOptions<AcceptJoinRequestMutation, AcceptJoinRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptJoinRequestMutation, AcceptJoinRequestMutationVariables>(AcceptJoinRequestDocument, options);
      }
export type AcceptJoinRequestMutationHookResult = ReturnType<typeof useAcceptJoinRequestMutation>;
export type AcceptJoinRequestMutationResult = Apollo.MutationResult<AcceptJoinRequestMutation>;
export type AcceptJoinRequestMutationOptions = Apollo.BaseMutationOptions<AcceptJoinRequestMutation, AcceptJoinRequestMutationVariables>;
export const RejectJoinRequestDocument = gql`
    mutation RejectJoinRequest($joinRequestId: String!) {
  rejectJoinRequest(joinRequestID: $joinRequestId) {
    id
  }
}
    `;
export type RejectJoinRequestMutationFn = Apollo.MutationFunction<RejectJoinRequestMutation, RejectJoinRequestMutationVariables>;

/**
 * __useRejectJoinRequestMutation__
 *
 * To run a mutation, you first call `useRejectJoinRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectJoinRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectJoinRequestMutation, { data, loading, error }] = useRejectJoinRequestMutation({
 *   variables: {
 *      joinRequestId: // value for 'joinRequestId'
 *   },
 * });
 */
export function useRejectJoinRequestMutation(baseOptions?: Apollo.MutationHookOptions<RejectJoinRequestMutation, RejectJoinRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RejectJoinRequestMutation, RejectJoinRequestMutationVariables>(RejectJoinRequestDocument, options);
      }
export type RejectJoinRequestMutationHookResult = ReturnType<typeof useRejectJoinRequestMutation>;
export type RejectJoinRequestMutationResult = Apollo.MutationResult<RejectJoinRequestMutation>;
export type RejectJoinRequestMutationOptions = Apollo.BaseMutationOptions<RejectJoinRequestMutation, RejectJoinRequestMutationVariables>;
export const SearchProfileDocument = gql`
    query SearchProfile($playerId: String!) {
  getPlayersProfile(playerID: $playerId) {
    ...PlayerProfileFields
  }
}
    ${PlayerProfileFieldsFragmentDoc}`;

/**
 * __useSearchProfileQuery__
 *
 * To run a query within a React component, call `useSearchProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchProfileQuery({
 *   variables: {
 *      playerId: // value for 'playerId'
 *   },
 * });
 */
export function useSearchProfileQuery(baseOptions: Apollo.QueryHookOptions<SearchProfileQuery, SearchProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchProfileQuery, SearchProfileQueryVariables>(SearchProfileDocument, options);
      }
export function useSearchProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchProfileQuery, SearchProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchProfileQuery, SearchProfileQueryVariables>(SearchProfileDocument, options);
        }
export type SearchProfileQueryHookResult = ReturnType<typeof useSearchProfileQuery>;
export type SearchProfileLazyQueryHookResult = ReturnType<typeof useSearchProfileLazyQuery>;
export type SearchProfileQueryResult = Apollo.QueryResult<SearchProfileQuery, SearchProfileQueryVariables>;
export const ProfileTransactionsDocument = gql`
    query ProfileTransactions($playerId: String!, $sortBy: String, $sortDirection: SortDirection, $offset: Int, $limit: Int) {
  getPlayersProfile(playerID: $playerId) {
    player {
      id
    }
    transactions(
      sortBy: $sortBy
      sortDirection: $sortDirection
      offset: $offset
      limit: $limit
    ) {
      id
      amount
      name
      type
      createdAt
    }
    transactionsCount
  }
}
    `;

/**
 * __useProfileTransactionsQuery__
 *
 * To run a query within a React component, call `useProfileTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileTransactionsQuery({
 *   variables: {
 *      playerId: // value for 'playerId'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useProfileTransactionsQuery(baseOptions: Apollo.QueryHookOptions<ProfileTransactionsQuery, ProfileTransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProfileTransactionsQuery, ProfileTransactionsQueryVariables>(ProfileTransactionsDocument, options);
      }
export function useProfileTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfileTransactionsQuery, ProfileTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProfileTransactionsQuery, ProfileTransactionsQueryVariables>(ProfileTransactionsDocument, options);
        }
export type ProfileTransactionsQueryHookResult = ReturnType<typeof useProfileTransactionsQuery>;
export type ProfileTransactionsLazyQueryHookResult = ReturnType<typeof useProfileTransactionsLazyQuery>;
export type ProfileTransactionsQueryResult = Apollo.QueryResult<ProfileTransactionsQuery, ProfileTransactionsQueryVariables>;
export const UpdatePlayersTeamProfileDocument = gql`
    mutation UpdatePlayersTeamProfile($position: String) {
  updatePlayersTeamProfile(position: $position) {
    id
    position
  }
}
    `;
export type UpdatePlayersTeamProfileMutationFn = Apollo.MutationFunction<UpdatePlayersTeamProfileMutation, UpdatePlayersTeamProfileMutationVariables>;

/**
 * __useUpdatePlayersTeamProfileMutation__
 *
 * To run a mutation, you first call `useUpdatePlayersTeamProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlayersTeamProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlayersTeamProfileMutation, { data, loading, error }] = useUpdatePlayersTeamProfileMutation({
 *   variables: {
 *      position: // value for 'position'
 *   },
 * });
 */
export function useUpdatePlayersTeamProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePlayersTeamProfileMutation, UpdatePlayersTeamProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePlayersTeamProfileMutation, UpdatePlayersTeamProfileMutationVariables>(UpdatePlayersTeamProfileDocument, options);
      }
export type UpdatePlayersTeamProfileMutationHookResult = ReturnType<typeof useUpdatePlayersTeamProfileMutation>;
export type UpdatePlayersTeamProfileMutationResult = Apollo.MutationResult<UpdatePlayersTeamProfileMutation>;
export type UpdatePlayersTeamProfileMutationOptions = Apollo.BaseMutationOptions<UpdatePlayersTeamProfileMutation, UpdatePlayersTeamProfileMutationVariables>;
export const TeamReoccuringGamesDocument = gql`
    query TeamReoccuringGames($teamId: String!) {
  getTeam(teamID: $teamId) {
    id
    reoccuringGames {
      ...ReoccuringGameFields
    }
  }
}
    ${ReoccuringGameFieldsFragmentDoc}`;

/**
 * __useTeamReoccuringGamesQuery__
 *
 * To run a query within a React component, call `useTeamReoccuringGamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamReoccuringGamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamReoccuringGamesQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useTeamReoccuringGamesQuery(baseOptions: Apollo.QueryHookOptions<TeamReoccuringGamesQuery, TeamReoccuringGamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamReoccuringGamesQuery, TeamReoccuringGamesQueryVariables>(TeamReoccuringGamesDocument, options);
      }
export function useTeamReoccuringGamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamReoccuringGamesQuery, TeamReoccuringGamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamReoccuringGamesQuery, TeamReoccuringGamesQueryVariables>(TeamReoccuringGamesDocument, options);
        }
export type TeamReoccuringGamesQueryHookResult = ReturnType<typeof useTeamReoccuringGamesQuery>;
export type TeamReoccuringGamesLazyQueryHookResult = ReturnType<typeof useTeamReoccuringGamesLazyQuery>;
export type TeamReoccuringGamesQueryResult = Apollo.QueryResult<TeamReoccuringGamesQuery, TeamReoccuringGamesQueryVariables>;
export const CreateReoccuringGameDocument = gql`
    mutation CreateReoccuringGame($inputs: CreateReoccuringGameInputs!) {
  createReoccuringGame(inputs: $inputs) {
    ...ReoccuringGameFields
  }
}
    ${ReoccuringGameFieldsFragmentDoc}`;
export type CreateReoccuringGameMutationFn = Apollo.MutationFunction<CreateReoccuringGameMutation, CreateReoccuringGameMutationVariables>;

/**
 * __useCreateReoccuringGameMutation__
 *
 * To run a mutation, you first call `useCreateReoccuringGameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReoccuringGameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReoccuringGameMutation, { data, loading, error }] = useCreateReoccuringGameMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useCreateReoccuringGameMutation(baseOptions?: Apollo.MutationHookOptions<CreateReoccuringGameMutation, CreateReoccuringGameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReoccuringGameMutation, CreateReoccuringGameMutationVariables>(CreateReoccuringGameDocument, options);
      }
export type CreateReoccuringGameMutationHookResult = ReturnType<typeof useCreateReoccuringGameMutation>;
export type CreateReoccuringGameMutationResult = Apollo.MutationResult<CreateReoccuringGameMutation>;
export type CreateReoccuringGameMutationOptions = Apollo.BaseMutationOptions<CreateReoccuringGameMutation, CreateReoccuringGameMutationVariables>;
export const UpdateReoccuringGameDocument = gql`
    mutation UpdateReoccuringGame($inputs: UpdateReoccuringGameInputs!) {
  updateReoccuringGame(inputs: $inputs) {
    ...ReoccuringGameFields
  }
}
    ${ReoccuringGameFieldsFragmentDoc}`;
export type UpdateReoccuringGameMutationFn = Apollo.MutationFunction<UpdateReoccuringGameMutation, UpdateReoccuringGameMutationVariables>;

/**
 * __useUpdateReoccuringGameMutation__
 *
 * To run a mutation, you first call `useUpdateReoccuringGameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReoccuringGameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReoccuringGameMutation, { data, loading, error }] = useUpdateReoccuringGameMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useUpdateReoccuringGameMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReoccuringGameMutation, UpdateReoccuringGameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReoccuringGameMutation, UpdateReoccuringGameMutationVariables>(UpdateReoccuringGameDocument, options);
      }
export type UpdateReoccuringGameMutationHookResult = ReturnType<typeof useUpdateReoccuringGameMutation>;
export type UpdateReoccuringGameMutationResult = Apollo.MutationResult<UpdateReoccuringGameMutation>;
export type UpdateReoccuringGameMutationOptions = Apollo.BaseMutationOptions<UpdateReoccuringGameMutation, UpdateReoccuringGameMutationVariables>;
export const DeleteReoccuringGameDocument = gql`
    mutation DeleteReoccuringGame($deleteReoccuringGameId: String!) {
  deleteReoccuringGame(id: $deleteReoccuringGameId) {
    id
  }
}
    `;
export type DeleteReoccuringGameMutationFn = Apollo.MutationFunction<DeleteReoccuringGameMutation, DeleteReoccuringGameMutationVariables>;

/**
 * __useDeleteReoccuringGameMutation__
 *
 * To run a mutation, you first call `useDeleteReoccuringGameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReoccuringGameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReoccuringGameMutation, { data, loading, error }] = useDeleteReoccuringGameMutation({
 *   variables: {
 *      deleteReoccuringGameId: // value for 'deleteReoccuringGameId'
 *   },
 * });
 */
export function useDeleteReoccuringGameMutation(baseOptions?: Apollo.MutationHookOptions<DeleteReoccuringGameMutation, DeleteReoccuringGameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteReoccuringGameMutation, DeleteReoccuringGameMutationVariables>(DeleteReoccuringGameDocument, options);
      }
export type DeleteReoccuringGameMutationHookResult = ReturnType<typeof useDeleteReoccuringGameMutation>;
export type DeleteReoccuringGameMutationResult = Apollo.MutationResult<DeleteReoccuringGameMutation>;
export type DeleteReoccuringGameMutationOptions = Apollo.BaseMutationOptions<DeleteReoccuringGameMutation, DeleteReoccuringGameMutationVariables>;
export const GenerateGamesDocument = gql`
    mutation GenerateGames {
  generateGamesFromReoccuringGames
}
    `;
export type GenerateGamesMutationFn = Apollo.MutationFunction<GenerateGamesMutation, GenerateGamesMutationVariables>;

/**
 * __useGenerateGamesMutation__
 *
 * To run a mutation, you first call `useGenerateGamesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateGamesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateGamesMutation, { data, loading, error }] = useGenerateGamesMutation({
 *   variables: {
 *   },
 * });
 */
export function useGenerateGamesMutation(baseOptions?: Apollo.MutationHookOptions<GenerateGamesMutation, GenerateGamesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateGamesMutation, GenerateGamesMutationVariables>(GenerateGamesDocument, options);
      }
export type GenerateGamesMutationHookResult = ReturnType<typeof useGenerateGamesMutation>;
export type GenerateGamesMutationResult = Apollo.MutationResult<GenerateGamesMutation>;
export type GenerateGamesMutationOptions = Apollo.BaseMutationOptions<GenerateGamesMutation, GenerateGamesMutationVariables>;
export const TeamVenuesDocument = gql`
    query TeamVenues {
  getVenues {
    ...VenueFields
  }
}
    ${VenueFieldsFragmentDoc}`;

/**
 * __useTeamVenuesQuery__
 *
 * To run a query within a React component, call `useTeamVenuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamVenuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamVenuesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeamVenuesQuery(baseOptions?: Apollo.QueryHookOptions<TeamVenuesQuery, TeamVenuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamVenuesQuery, TeamVenuesQueryVariables>(TeamVenuesDocument, options);
      }
export function useTeamVenuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamVenuesQuery, TeamVenuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamVenuesQuery, TeamVenuesQueryVariables>(TeamVenuesDocument, options);
        }
export type TeamVenuesQueryHookResult = ReturnType<typeof useTeamVenuesQuery>;
export type TeamVenuesLazyQueryHookResult = ReturnType<typeof useTeamVenuesLazyQuery>;
export type TeamVenuesQueryResult = Apollo.QueryResult<TeamVenuesQuery, TeamVenuesQueryVariables>;
export const SaveVenueDocument = gql`
    mutation SaveVenue($inputs: SaveVenueInputs!) {
  saveVenue(inputs: $inputs) {
    ...VenueFields
  }
}
    ${VenueFieldsFragmentDoc}`;
export type SaveVenueMutationFn = Apollo.MutationFunction<SaveVenueMutation, SaveVenueMutationVariables>;

/**
 * __useSaveVenueMutation__
 *
 * To run a mutation, you first call `useSaveVenueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveVenueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveVenueMutation, { data, loading, error }] = useSaveVenueMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useSaveVenueMutation(baseOptions?: Apollo.MutationHookOptions<SaveVenueMutation, SaveVenueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveVenueMutation, SaveVenueMutationVariables>(SaveVenueDocument, options);
      }
export type SaveVenueMutationHookResult = ReturnType<typeof useSaveVenueMutation>;
export type SaveVenueMutationResult = Apollo.MutationResult<SaveVenueMutation>;
export type SaveVenueMutationOptions = Apollo.BaseMutationOptions<SaveVenueMutation, SaveVenueMutationVariables>;
export const DeleteVenueDocument = gql`
    mutation DeleteVenue($deleteVenueId: String!) {
  deleteVenue(venueid: $deleteVenueId) {
    id
  }
}
    `;
export type DeleteVenueMutationFn = Apollo.MutationFunction<DeleteVenueMutation, DeleteVenueMutationVariables>;

/**
 * __useDeleteVenueMutation__
 *
 * To run a mutation, you first call `useDeleteVenueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVenueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVenueMutation, { data, loading, error }] = useDeleteVenueMutation({
 *   variables: {
 *      deleteVenueId: // value for 'deleteVenueId'
 *   },
 * });
 */
export function useDeleteVenueMutation(baseOptions?: Apollo.MutationHookOptions<DeleteVenueMutation, DeleteVenueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteVenueMutation, DeleteVenueMutationVariables>(DeleteVenueDocument, options);
      }
export type DeleteVenueMutationHookResult = ReturnType<typeof useDeleteVenueMutation>;
export type DeleteVenueMutationResult = Apollo.MutationResult<DeleteVenueMutation>;
export type DeleteVenueMutationOptions = Apollo.BaseMutationOptions<DeleteVenueMutation, DeleteVenueMutationVariables>;
export const EmailLoginDocument = gql`
    mutation EmailLogin($password: String!, $email: String!) {
  emailLogin(password: $password, email: $email) {
    accessToken
  }
}
    `;
export type EmailLoginMutationFn = Apollo.MutationFunction<EmailLoginMutation, EmailLoginMutationVariables>;

/**
 * __useEmailLoginMutation__
 *
 * To run a mutation, you first call `useEmailLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmailLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emailLoginMutation, { data, loading, error }] = useEmailLoginMutation({
 *   variables: {
 *      password: // value for 'password'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useEmailLoginMutation(baseOptions?: Apollo.MutationHookOptions<EmailLoginMutation, EmailLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EmailLoginMutation, EmailLoginMutationVariables>(EmailLoginDocument, options);
      }
export type EmailLoginMutationHookResult = ReturnType<typeof useEmailLoginMutation>;
export type EmailLoginMutationResult = Apollo.MutationResult<EmailLoginMutation>;
export type EmailLoginMutationOptions = Apollo.BaseMutationOptions<EmailLoginMutation, EmailLoginMutationVariables>;
export const GoogleLoginDocument = gql`
    mutation GoogleLogin($code: String!) {
  googleLogin(code: $code) {
    accessToken
  }
}
    `;
export type GoogleLoginMutationFn = Apollo.MutationFunction<GoogleLoginMutation, GoogleLoginMutationVariables>;

/**
 * __useGoogleLoginMutation__
 *
 * To run a mutation, you first call `useGoogleLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGoogleLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [googleLoginMutation, { data, loading, error }] = useGoogleLoginMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useGoogleLoginMutation(baseOptions?: Apollo.MutationHookOptions<GoogleLoginMutation, GoogleLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GoogleLoginMutation, GoogleLoginMutationVariables>(GoogleLoginDocument, options);
      }
export type GoogleLoginMutationHookResult = ReturnType<typeof useGoogleLoginMutation>;
export type GoogleLoginMutationResult = Apollo.MutationResult<GoogleLoginMutation>;
export type GoogleLoginMutationOptions = Apollo.BaseMutationOptions<GoogleLoginMutation, GoogleLoginMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const ConfirmEmailDocument = gql`
    mutation ConfirmEmail($token: String!) {
  confirmEmail(token: $token)
}
    `;
export type ConfirmEmailMutationFn = Apollo.MutationFunction<ConfirmEmailMutation, ConfirmEmailMutationVariables>;

/**
 * __useConfirmEmailMutation__
 *
 * To run a mutation, you first call `useConfirmEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmEmailMutation, { data, loading, error }] = useConfirmEmailMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useConfirmEmailMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmEmailMutation, ConfirmEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmEmailMutation, ConfirmEmailMutationVariables>(ConfirmEmailDocument, options);
      }
export type ConfirmEmailMutationHookResult = ReturnType<typeof useConfirmEmailMutation>;
export type ConfirmEmailMutationResult = Apollo.MutationResult<ConfirmEmailMutation>;
export type ConfirmEmailMutationOptions = Apollo.BaseMutationOptions<ConfirmEmailMutation, ConfirmEmailMutationVariables>;
export const ResendConfirmationEmailDocument = gql`
    mutation ResendConfirmationEmail {
  resendConfirmationEmail {
    accessToken
  }
}
    `;
export type ResendConfirmationEmailMutationFn = Apollo.MutationFunction<ResendConfirmationEmailMutation, ResendConfirmationEmailMutationVariables>;

/**
 * __useResendConfirmationEmailMutation__
 *
 * To run a mutation, you first call `useResendConfirmationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendConfirmationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendConfirmationEmailMutation, { data, loading, error }] = useResendConfirmationEmailMutation({
 *   variables: {
 *   },
 * });
 */
export function useResendConfirmationEmailMutation(baseOptions?: Apollo.MutationHookOptions<ResendConfirmationEmailMutation, ResendConfirmationEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendConfirmationEmailMutation, ResendConfirmationEmailMutationVariables>(ResendConfirmationEmailDocument, options);
      }
export type ResendConfirmationEmailMutationHookResult = ReturnType<typeof useResendConfirmationEmailMutation>;
export type ResendConfirmationEmailMutationResult = Apollo.MutationResult<ResendConfirmationEmailMutation>;
export type ResendConfirmationEmailMutationOptions = Apollo.BaseMutationOptions<ResendConfirmationEmailMutation, ResendConfirmationEmailMutationVariables>;
export const RequestToJoinTeamDocument = gql`
    mutation RequestToJoinTeam($joinCode: String!) {
  requestToJoinTeam(joinCode: $joinCode) {
    id
    status
  }
}
    `;
export type RequestToJoinTeamMutationFn = Apollo.MutationFunction<RequestToJoinTeamMutation, RequestToJoinTeamMutationVariables>;

/**
 * __useRequestToJoinTeamMutation__
 *
 * To run a mutation, you first call `useRequestToJoinTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestToJoinTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestToJoinTeamMutation, { data, loading, error }] = useRequestToJoinTeamMutation({
 *   variables: {
 *      joinCode: // value for 'joinCode'
 *   },
 * });
 */
export function useRequestToJoinTeamMutation(baseOptions?: Apollo.MutationHookOptions<RequestToJoinTeamMutation, RequestToJoinTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestToJoinTeamMutation, RequestToJoinTeamMutationVariables>(RequestToJoinTeamDocument, options);
      }
export type RequestToJoinTeamMutationHookResult = ReturnType<typeof useRequestToJoinTeamMutation>;
export type RequestToJoinTeamMutationResult = Apollo.MutationResult<RequestToJoinTeamMutation>;
export type RequestToJoinTeamMutationOptions = Apollo.BaseMutationOptions<RequestToJoinTeamMutation, RequestToJoinTeamMutationVariables>;
export const GoogleRegisterDocument = gql`
    mutation GoogleRegister($code: String!) {
  googleRegister(code: $code) {
    accessToken
  }
}
    `;
export type GoogleRegisterMutationFn = Apollo.MutationFunction<GoogleRegisterMutation, GoogleRegisterMutationVariables>;

/**
 * __useGoogleRegisterMutation__
 *
 * To run a mutation, you first call `useGoogleRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGoogleRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [googleRegisterMutation, { data, loading, error }] = useGoogleRegisterMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useGoogleRegisterMutation(baseOptions?: Apollo.MutationHookOptions<GoogleRegisterMutation, GoogleRegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GoogleRegisterMutation, GoogleRegisterMutationVariables>(GoogleRegisterDocument, options);
      }
export type GoogleRegisterMutationHookResult = ReturnType<typeof useGoogleRegisterMutation>;
export type GoogleRegisterMutationResult = Apollo.MutationResult<GoogleRegisterMutation>;
export type GoogleRegisterMutationOptions = Apollo.BaseMutationOptions<GoogleRegisterMutation, GoogleRegisterMutationVariables>;
export const EmailRegisterDocument = gql`
    mutation EmailRegister($input: EmailRegisterInput!) {
  emailRegister(input: $input) {
    accessToken
  }
}
    `;
export type EmailRegisterMutationFn = Apollo.MutationFunction<EmailRegisterMutation, EmailRegisterMutationVariables>;

/**
 * __useEmailRegisterMutation__
 *
 * To run a mutation, you first call `useEmailRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmailRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emailRegisterMutation, { data, loading, error }] = useEmailRegisterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEmailRegisterMutation(baseOptions?: Apollo.MutationHookOptions<EmailRegisterMutation, EmailRegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EmailRegisterMutation, EmailRegisterMutationVariables>(EmailRegisterDocument, options);
      }
export type EmailRegisterMutationHookResult = ReturnType<typeof useEmailRegisterMutation>;
export type EmailRegisterMutationResult = Apollo.MutationResult<EmailRegisterMutation>;
export type EmailRegisterMutationOptions = Apollo.BaseMutationOptions<EmailRegisterMutation, EmailRegisterMutationVariables>;