import "@fontsource/inter/300.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/700.css";

import { CssBaseline, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { env } from "config/env";
import ReloadPrompt from "config/ReloadPrompt";
import AuthorizedApolloProvider from "providers/AuthorizedApolloProvider";
import { GlobalStoreProvider } from "providers/GlobalStoreProvider";
import PublicRoutes from "PublicRoutes";
import React from "react";
import { createRoot } from "react-dom/client";
import { Toaster, ToastOptions, ToastType } from "react-hot-toast";
import theme from "theme";
import { version } from "../package.json";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

Sentry.init({
  dsn: env.VITE_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  enabled: env.isProd,
  release: version,
  tracesSampleRate: 0.5,
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <GlobalStoreProvider>
        <AuthorizedApolloProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <PublicRoutes />
            </LocalizationProvider>
            <ReloadPrompt />
            {createToaster()}
          </ThemeProvider>
        </AuthorizedApolloProvider>
      </GlobalStoreProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

function createToaster() {
  const config: ToastOptions & {
    [key in ToastType]?: ToastOptions;
  } = {
    style: {
      background: theme.palette.background.default,
      color: theme.palette.text.primary,
    },
    success: {
      duration: 1500,
      icon: "🎉",
    },
    error: {
      duration: 3000,
      icon: "🚨",
    },
  };
  return <Toaster toastOptions={config} />;
}
