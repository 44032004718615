import { ListItemIcon, MenuItem as MuiMenuItem } from "@mui/material";
import { Link } from "react-router-dom";
import { PTBMenuItem } from "./Menu";

interface MenuItemProps extends PTBMenuItem {
  onClick: (path: string) => () => Promise<void>;
  dense?: boolean;
}

/** A MenuItem that may be control + clickable for opening in a new tab if a path is provided */
function MenuItem({ label, path, icon, dense = false, onClick }: MenuItemProps) {
  // Render a react-router-dom Link component if a path is provided, otherwise just render a MenuItem
  if (path) {
    return (
      <MuiMenuItem component={Link} to={path} dense={dense} onClick={onClick(label)}>
        <ListItemIcon>{icon}</ListItemIcon>
        {label}
      </MuiMenuItem>
    );
  } else {
    return (
      <MuiMenuItem dense={dense} onClick={onClick(label)}>
        <ListItemIcon>{icon}</ListItemIcon>
        {label}
      </MuiMenuItem>
    );
  }
}

export default MenuItem;
