import { env } from "config/env";

interface RefreshTokenSuccessResponse {
  success: true;
  accessToken: string;
}

interface RefreshTokenErrorResponse {
  success: false;
  errorMessage: string;
}

/** Attempt to generate a new access token with an existing refresh token */
export const refreshAccessToken = async () => {
  const refreshEndpoint = `${env.VITE_BACKEND_ENDPOINT}/api/refresh_token`;
  const refreshResponse = await fetch(refreshEndpoint, {
    method: "POST",
    credentials: "include",
  });

  const refreshData = (await refreshResponse.json()) as
    | RefreshTokenSuccessResponse
    | RefreshTokenErrorResponse;

  if (!refreshData.success) {
    throw new Error(refreshData.errorMessage);
  }

  return refreshData.accessToken;
};
