import { Theme, useMediaQuery } from "@mui/material";

/**
 * Determine if the current screen is a mobile device
 * @returns Boolean indicating if the current screen is a mobile device
 */

export function useIsMobile(): boolean {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  return isMobile;
}
