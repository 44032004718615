import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Container,
  Divider,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import PublicScreen from "components/layouts/PublicScreen";
import { useRequestToJoinTeamMutation } from "graphql/generated";
import { usePlayerJoinRequests } from "hooks";
import { useAuthStore } from "providers/AuthStoreProvider";
import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Status } from "types";
import { formatDateTimeDistance } from "utils";
import SelectTeamFromList from "./TeamListSelect";

function NoActiveTeam() {
  const [status, setStatus] = useState<Status>(Status.Ready);
  const [teamCode, setTeamCode] = useState("");
  const profile = useAuthStore((store) => store.profile);
  const navigate = useNavigate();
  const [requestToJoinTeamMutation] = useRequestToJoinTeamMutation();
  const { joinRequests } = usePlayerJoinRequests();

  function handleTeamCodeChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    if (value.length > 6) return;
    const newValue = value.toUpperCase();
    setTeamCode(newValue);
  }

  async function handleJoinPressed() {
    setStatus(Status.Loading);
    try {
      await toast.promise(
        requestToJoinTeamMutation({
          variables: {
            joinCode: teamCode,
          },
          refetchQueries: ["GetMyPendingJoinRequests"],
          awaitRefetchQueries: true,
        }),
        {
          loading: "Requesting to join team...",
          error: (error) => error?.message ?? "Error making request",
          success: "Request sent!",
        }
      );
    } catch (error) {
      //
    } finally {
      setTeamCode("");
      setStatus(Status.Ready);
    }
  }

  return (
    <PublicScreen>
      <Container maxWidth="xs">
        <Stack spacing={2}>
          <Typography variant="h6" align="center">
            It looks like you&apos;ve got no active team
            <br />
            😥
          </Typography>

          {profile.teams.length > 0 ? (
            <>
              <Divider>Select a team</Divider>
              <SelectTeamFromList teams={profile.teams} />
            </>
          ) : null}

          <Divider>Create a team</Divider>

          <Button
            variant="contained"
            color="secondary"
            onClick={() => navigate("/teams/create")}
          >
            Create a team
          </Button>
          <Divider>Or join an existing team</Divider>

          <TextField
            label="Team Code"
            value={teamCode}
            onChange={handleTeamCodeChange}
            fullWidth
            size="small"
            helperText="Enter your 6 digit team code"
          />
          <LoadingButton
            onClick={handleJoinPressed}
            variant="contained"
            color="secondary"
            loading={status !== Status.Ready}
          >
            Request to Join
          </LoadingButton>

          {joinRequests.length > 0 ? (
            <>
              <Typography variant="h6">Pending Requests</Typography>
              <Stack spacing={2}>
                {joinRequests.map((request) => {
                  const [date] = formatDateTimeDistance(
                    request.createdAt,
                    "dd/MM/yyyy p"
                  );
                  return (
                    <Box display="flex" justifyContent="space-between">
                      <Typography variant="body2">{request.team?.name ?? ""}</Typography>
                      <Typography variant="body2">{date}</Typography>
                    </Box>
                  );
                })}
              </Stack>
            </>
          ) : null}
        </Stack>
      </Container>
    </PublicScreen>
  );
}

export default NoActiveTeam;
