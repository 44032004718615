import { useQuery } from "@tanstack/react-query";
import { useGlobalStore } from "providers/GlobalStoreProvider";
import { useNavigate } from "react-router-dom";
import { refreshAccessToken } from "utils";

export function useRefreshToken() {
  // const clearGlobalStore = useGlobalStore((store) => store.clearStore);
  const setAccessToken = useGlobalStore((store) => store.setAccessToken);
  const navigate = useNavigate();
  // const apolloClient = useApolloClient();

  const refreshTokenQuery = useQuery({
    queryKey: ["refreshToken"],
    queryFn: refreshAccessToken,
    onSuccess: (accessToken) => {
      setAccessToken(accessToken);
      console.log("Got new access token and state of cookies are %o", document.cookie);
    },
    retry: false,
    onError: async (error) => {
      // Clear all local data stores
      // await apolloClient.clearStore();
      // clearAllCookies();
      // localStorage.clear();
      // sessionStorage.clear();
      // clearGlobalStore();

      const errorMessage =
        error instanceof Error ? error.message : "Unknown error refreshing access token";
      console.error(`Error refreshing access token: ${errorMessage}`);
      navigate("/login");
    },
  });

  return refreshTokenQuery;

  //   useEffect(() => {
  //     fetch(refreshEndpoint, {
  //       method: "POST",
  //       credentials: "include",
  //     })
  //       .then((response) => {
  //         if (response.status === 200) {
  //           return response.json();
  //         }
  //       })
  //       .then((data) => {
  //         if (!data.ok) {
  //           navigate("/login");
  //         } else {
  //           setGlobalState({
  //             accessToken: data.accessToken,
  //           });
  //         }
  //         setAuthLoading(false);
  //       })
  //       .catch((error) => {
  //         console.error("Error refreshing token:" + error);
  //         setAuthLoading(false);
  //       });
  //   }, [navigate, pathname, publicRoutes, refreshEndpoint, setGlobalState]);
}
