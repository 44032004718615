import { Box } from "@mui/material";

/** Apply a footer large enough so the FAB button won't sit on top of content */
function Footer() {
  return (
    <Box bgcolor="background.default" component="footer" borderRadius="0" height="60px" />
  );
}

export default Footer;
