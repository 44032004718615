import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

interface ConfirmDialogProps {
  title: string;
  message?: string;
  handleClose: (confirmation: boolean) => void;
  isOpen: boolean;
}

export function ConfirmDialog(props: ConfirmDialogProps) {
  const { title, message = "", handleClose, isOpen } = props;

  return (
    <Dialog open={isOpen} onClose={() => handleClose(false)}>
      <DialogTitle>{title}</DialogTitle>
      {message ? (
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
      ) : null}
      <DialogActions>
        <Button onClick={() => handleClose(false)} color="inherit" variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={() => handleClose(true)}
          color="primary"
          variant="contained"
          autoFocus
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
