import { ApolloCache } from "@apollo/client";

type gqlResponseObject =
  | {
      id?: number;
      __typename?: string;
    }
  | null
  | undefined;

type CacheUpdateAction =
  | { type: "ADD"; rootListQuery: string; data: gqlResponseObject }
  | { type: "DELETE"; data: gqlResponseObject };

/**
 * Update the apollo cache by creating a new reference to
 * the cached object and updating the base query
 * @param cache An apollo cache
 * @param action Required data for cache update
 */
export function updateCache(cache: ApolloCache<any>, action: CacheUpdateAction) {
  try {
    if (action.type === "ADD") {
      const newID = action.data?.id;
      const typename = action.data?.__typename;
      if (!newID || !typename) {
        throw new Error(`Unable to update cache for ${action.rootListQuery}`);
      }
      const newCacheItem = {
        __ref: `${typename}:${newID}`,
      };

      cache.modify({
        fields(fieldValue, details) {
          // If there are multiple queries with the same name
          // check the variables passed to the query
          if (details.storeFieldName === action.rootListQuery) {
            return [...fieldValue, newCacheItem];
          } else {
            return fieldValue;
          }
        },
      });
    } else if (action.type === "DELETE") {
      const type = action.data?.__typename;
      const id = action.data?.id;
      if (!type || !id) {
        throw new Error(`Unable to remove an item from cache`);
      }
      const normalizedId = cache.identify({
        id,
        __typename: type,
      });
      cache.evict({ id: normalizedId });
      cache.gc();
    }
  } catch (error) {
    console.error(error);
  }
}

type BaseResult = { __typename?: string; [key: string]: any };

/** Evict an item from the Apollo cache, updating all subscribed queries */
export const deleteCacheItem = <TResult extends BaseResult>({
  cache,
  result,
  keyField = "id",
}: {
  /** The Apollo in memory cache */
  cache: ApolloCache<any>;
  /** The query/fragment result to delete */
  result: TResult;
  /** If you have changed the keyField for the object from "id", set it with keyField */
  keyField?: keyof TResult;
}): void => {
  // Lookup the object in the cache by its typename and keyField
  const normalizedId = cache.identify({
    __typename: result.__typename,
    [keyField]: result[keyField],
  });

  if (!normalizedId) {
    console.warn(
      `Unable to find ${result.__typename}:${result[keyField]} when attempting to clear Apollo cache`
    );
  }

  cache.evict({ id: normalizedId });
  cache.gc();
};

/**
 * Add a new cache ref to an existing query
 * Mutation response MUST have all the variables used for the query otherwise you need to use refetch queries
 * @param inputs.cache An apollo cache
 * @param inputs.fieldName The existing root query name OR the field on the cached object.
 * @param inputs.newRef The new cache ref to add to the query
 * @param inputs.existingObjectRef (Optional) An existing cache ref object to update otherwise defaults to root level query (ROOT_QUERY)
 * @example To update an existing cached object e.g. Adding a contact on an account to the contacts field: addCacheItemToQuery(cache, "contacts", "Contact:258", "Account:123")
 * @example To update a root level query e.g. Adding an account to the global accounts query: addCacheItemToQuery(cache, "accounts", "Account:258")
 */
export const addCacheItemToQuery = ({
  cache,
  fieldName,
  newRef,
  existingObjectRef = "ROOT_QUERY",
}: {
  cache: ApolloCache<any>;
  fieldName: string;
  newRef: string;
  existingObjectRef?: string;
}): void => {
  cache.modify({
    id: existingObjectRef,
    fields: (fieldValue, details) => {
      if (details.storeFieldName === fieldName) {
        return [...fieldValue, { __ref: newRef }];
      } else {
        return fieldValue;
      }
    },
  });
};
