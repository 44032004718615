import * as Sentry from "@sentry/react";
import { LoadingScreen, ScrollTop } from "components/common";
import Footer from "components/Footer/Footer";
import AppShell from "components/layouts/AppShell";
import Menu from "components/TopNavigation/Menu";
import TopNavigation from "components/TopNavigation/TopNavigation";
import { useGetMyProfileQuery } from "graphql/generated";
import { useRefreshToken } from "hooks";
import PrivateRoutes from "PrivateRoutes";
import { AuthGlobalStore, AuthStoreCacheKey, AuthStoreProvider } from "providers/AuthStoreProvider";
import { useGlobalStore } from "providers/GlobalStoreProvider";
import { Navigate } from "react-router-dom";

function App() {
  const accessToken = useGlobalStore((store) => store.accessToken);

  // Load profile from cache if exists, otherwise load from server
  // Helps get to the home screen faster
  const cachedStoreJson = window.localStorage.getItem(AuthStoreCacheKey);
  const cachedStore = cachedStoreJson
    ? (JSON.parse(cachedStoreJson) as {
        state?: AuthGlobalStore;
        version?: number;
      })
    : undefined;
  const cachedProfile = cachedStore?.state?.profile;

  // Don't refresh profile if we have a cached profile, the UpdateProvider will take care of it
  const profileQuery = useGetMyProfileQuery({
    skip: !!cachedProfile || !accessToken,
  });

  // Refresh access token in case it's expired
  // Sleep for 1 second to avoid flashing the loading screen
  // with setTimeout and a promose

  useRefreshToken();

  const profile = profileQuery.data?.getMyProfile ?? cachedProfile;
  const appLoading = !profile && profileQuery.loading;

  if (profileQuery.error) {
    if (profileQuery.error?.message === "Login required") {
      return <Navigate to="/login" />;
    } else {
      // Error loading profile
      Sentry.captureException(profileQuery.error);
      console.error(profileQuery.error);
      return <div>{profileQuery.error.message}</div>;
    }
  }

  if (!accessToken) {
    return <Navigate to="/login" />;
  }

  if (appLoading) {
    return <LoadingScreen message="Loading Profile..." />;
  }

  if (!profile) {
    throw new Error("Error loading profile");
  }

  console.log("%cAPP SHELL RENDERING", "color:red");

  return (
    <AuthStoreProvider profile={profile}>
      <AppShell
        header={<TopNavigation />}
        footer={<Footer />}
        menuPosition="right"
        renderMenu={(rootElement: HTMLElement | null, onClose: () => void) => (
          <Menu anchorEl={rootElement} onClose={onClose} />
        )}
      >
        <PrivateRoutes />
        <ScrollTop />
      </AppShell>
    </AuthStoreProvider>
  );
}

export default App;
