import { ArrowBack, Home } from "@mui/icons-material";
import { Alert, Container, Stack, Typography } from "@mui/material";
import ErrorAnimation from "assets/animations/PTB_500.json";
import PublicScreen from "components/layouts/PublicScreen";
import { env } from "config/env";
import Lottie from "lottie-react";
import { useNavigate } from "react-router-dom";
import SecondaryButton from "./SecondaryButton";

interface ErrorScreenProps {
  message: string;
  title?: string;
  error?: Error;
}

function ErrorScreen(props: ErrorScreenProps) {
  const { message, title = "Critical Error", error } = props;
  const navigate = useNavigate();

  return (
    <PublicScreen>
      <Container maxWidth="sm" disableGutters sx={{ pb: 12 }}>
        <Alert severity="error">{title}</Alert>
        <Typography variant="h6" align="center" sx={{ mt: 2 }}>
          {message}
        </Typography>
        <Lottie style={{ height: "250px" }} animationData={ErrorAnimation} />
        <Stack direction="row" spacing={2} justifyContent="center">
          <SecondaryButton startIcon={<ArrowBack />} onClick={() => navigate("-1")}>
            Back
          </SecondaryButton>
          <SecondaryButton startIcon={<Home />} onClick={() => navigate("/")}>
            Home
          </SecondaryButton>
        </Stack>
        {env.isDev && error?.stack ? <Typography>{error?.stack}</Typography> : null}
      </Container>
    </PublicScreen>
  );
}

export default ErrorScreen;
