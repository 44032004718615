import { Button, ButtonProps } from "@mui/material";
import { forwardRef } from "react";

const SecondaryButton = forwardRef<HTMLButtonElement, ButtonProps>(
  function SecondaryButton(props, ref) {
    const {
      children,
      color = "primary",
      variant = "outlined",
      size = "small",
      ...rest
    } = props;
    return (
      <Button
        {...rest}
        ref={ref}
        color={color}
        variant={variant}
        size={size}
        sx={{
          ...rest.sx,
          backgroundColor: "background.default",
        }}
      >
        {children}
      </Button>
    );
  }
);

export default SecondaryButton;
