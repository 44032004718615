import { LoadingScreen } from "components/common";
import { useAuthStore } from "providers/AuthStoreProvider";
import React, { Suspense } from "react";
import { Navigate, RouteObject, useLocation, useRoutes } from "react-router-dom";
import ViewChangelog from "screens/changelog/ViewChangelog";
import Home from "screens/home/Home";
import NoActiveTeam from "screens/noActiveTeam/NoActiveTeam";
import EditScheduledGame from "screens/ScheduledGames/EditScheduledGame";

const UpcomingGamesPage = React.lazy(() => import("screens/Games/UpcomingGamesPage"));
const VenuesListPage = React.lazy(() => import("screens/admin/Venues/VenuesListPage"));
const EditVenuePage = React.lazy(() => import("screens/admin/Venues/EditVenuePage"));
const ViewGamePage = React.lazy(() => import("screens/Games/ViewGame/ViewGamePage"));
const PlayersListPage = React.lazy(() => import("screens/Players/PlayersListPage"));
const TeamProfilePage = React.lazy(() => import("screens/Profile/TeamProfilePage"));
const TeamSettingsPage = React.lazy(() => import("screens/TeamSettings/TeamSettingsPage"));
const GameHistoryPage = React.lazy(() => import("screens/Games/GameHistory/GameHistoryPage"));
const ScheduledGamesList = React.lazy(() => import("screens/ScheduledGames/ScheduledGamesList"));

function PrivateRoutes() {
  const activeTeamProfile = useAuthStore((store) => store.profile.teamProfile);
  const { pathname } = useLocation();

  const routes: RouteObject[] = [
    { path: "/", element: <Home /> },
    { path: "/noteam", element: <NoActiveTeam /> },
    { path: "/changelog", element: <ViewChangelog /> },

    { path: "/games", element: <UpcomingGamesPage /> },
    { path: "/games/:gameid", element: <ViewGamePage /> },
    { path: "/games/history", element: <GameHistoryPage /> },

    { path: "/players", element: <PlayersListPage /> },
    { path: "/profile/:playerID", element: <TeamProfilePage /> },
  ];

  if (activeTeamProfile?.isTeamAdmin) {
    routes.push(
      { path: "/admin", element: <TeamSettingsPage /> },
      { path: "/admin/venues", element: <VenuesListPage /> },
      { path: "/admin/venues/:venueID", element: <EditVenuePage /> },
      { path: "/schedule", element: <ScheduledGamesList />},
      { path: "/schedule/:scheduledGameID", element: <EditScheduledGame />},
    );
  }

  const privateRoutes = useRoutes(routes);

  if (!activeTeamProfile && pathname !== "/noteam") {
    return <Navigate to="noteam" />;
  }

  return <Suspense fallback={<LoadingScreen message="Loading PTB..." />}>{privateRoutes}</Suspense>;
}

export default PrivateRoutes;
