import { useGetMyPendingJoinRequestsQuery } from "graphql/generated";

export function usePlayerJoinRequests() {
  const {
    data,
    loading: joinRequestsLoading,
    error: joinRequestsError,
  } = useGetMyPendingJoinRequestsQuery();

  const joinRequests = data?.getMyPendingJoinRequests ?? [];

  return { joinRequests, joinRequestsLoading, joinRequestsError };
}
