import { KeyboardArrowUp as KeyboardArrowUpIcon } from "@mui/icons-material";
import { Box, Fab, useScrollTrigger, Zoom } from "@mui/material";

interface ScrollTopProps {
  Icon?: JSX.Element;
}

export function ScrollTop(props: ScrollTopProps) {
  const { Icon = <KeyboardArrowUpIcon /> } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = () => {
    // Scroll to top of page
    const firstElement = document.firstElementChild;
    if (firstElement) {
      firstElement.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: "fixed", bottom: 16, right: 16, zIndex: 10 }}
      >
        <Fab color="secondary" size="small" aria-label="Scroll back to top">
          {Icon}
        </Fab>
      </Box>
    </Zoom>
  );
}
