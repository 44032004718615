import { format, formatDistance } from "date-fns";
import {
  DayOfWeek,
  PlayerStatus,
  PlayerTeamsStatus,
  ReoccuringGameFrequency,
} from "graphql/generated";

export * from "./queries";

export function formatDateTimeDistance(
  dateStringISO: string | undefined | null,
  formatString: string
) {
  try {
    if (!dateStringISO) {
      throw new Error("Invalid date");
    }
    const dateObject = new Date(dateStringISO);
    const formattedDate = format(dateObject, formatString);
    const distance = formatDistance(dateObject, new Date(), {
      addSuffix: true,
    });
    return [formattedDate, distance];
  } catch (error) {
    return ["", ""];
  }
}

export function formatGameDay(day: string) {
  switch (day) {
    case DayOfWeek.MONDAY:
      return "Monday";
    case DayOfWeek.TUESDAY:
      return "Tuesday";
    case DayOfWeek.WEDNESDAY:
      return "Wednesday";
    case DayOfWeek.THURSDAY:
      return "Thursday";
    case DayOfWeek.FRIDAY:
      return "Friday";
    case DayOfWeek.SATURDAY:
      return "Saturday";
    case DayOfWeek.SUNDAY:
      return "Sunday";
  }
}

export function formatGameFrequency(frequency: ReoccuringGameFrequency) {
  switch (frequency) {
    case ReoccuringGameFrequency.WEEKLY:
      return "Weekly";
    case ReoccuringGameFrequency.FORTNIGHTLY:
      return "Fortnightly";
    case ReoccuringGameFrequency.MONTHLY:
      return "Monthly";
  }
}

export function formatPlayerStatus(status: PlayerStatus) {
  switch (status) {
    case PlayerStatus.ACTIVE:
      return "Active";
    case PlayerStatus.INACTIVE:
      return "Inactive";
  }
}

export function formatPlayerTeamStatus(status?: PlayerTeamsStatus) {
  switch (status) {
    case PlayerTeamsStatus.ACTIVE:
      return "Active";
    case PlayerTeamsStatus.INACTIVE:
      return "Inactive";
  }
}

/** Clear all cookies */
export const clearAllCookies = () => {
  const cookies = document.cookie.split("; ");
  // for (let c = 0; c < cookies.length; c++) {
  for (const cookie of cookies) {
    const domain = window.location.hostname.split(".");
    while (domain.length > 0) {
      const cookieBase =
        encodeURIComponent(cookie.split(";")[0].split("=")[0]) +
        "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" +
        domain.join(".") +
        " ;path=";
      const path = window.location.pathname.split("/");
      document.cookie = cookieBase + "/";
      while (path.length > 0) {
        document.cookie = cookieBase + path.join("/");
        path.pop();
      }
      domain.shift();
    }
  }
};
